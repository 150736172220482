/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import Icon from 'Component/Icon';

import './PromotionBar.style';

/**
 * PromotionBar
 * @class PromotionBar
 * @namespace Sofacompany/Component/PromotionBar/Component/PromotionBar */
export class PromotionBar extends PureComponent {
    static propTypes = {
        handleClose: PropTypes.func.isRequired,
        promotionbars: PropTypes.arrayOf(PropTypes.shape({
            active: PropTypes.bool,
            id: PropTypes.string,
            text: PropTypes.string,
            type: PropTypes.string,
            color: PropTypes.string
        })).isRequired,
        slideMargin: PropTypes.number.isRequired
    };

    renderStatus(status, index) {
        const {
            text,
            type,
            color
        } = status;

        const style = { __html: `<style>.PromotionBar-Wrapper .svg-color { stroke: ${type} }</style>` };

        return (
            <div
              block="PromotionBar"
              elem="Item"
              mods={ { type } }
              key={ index }
              style={ { backgroundColor: color, color: type } }
            >
                <div dangerouslySetInnerHTML={ style } />
                <Html content={ text } />
            </div>
        );
    }

    render() {
        const {
            promotionbars, slideMargin, handleClose
        } = this.props;

        if (!promotionbars || promotionbars.length < 1) {
            return null;
        }

        const style = {
            // eslint-disable-next-line no-magic-numbers
            width: `${promotionbars.length * 100 }vw`,
            marginLeft: `-${slideMargin}%`
        };

        return (
            <div block="PromotionBar" elem="Wrapper">
                <button block="PromotionBar" elem="Close" onClick={ handleClose } aria-label={ __('button') }>
                    <Icon icon="close" />
                </button>
                <div block="PromotionBar" style={ style }>
                    { promotionbars.map((status, index) => this.renderStatus(status, index)) }
                </div>
            </div>
        );
    }
}

export default PromotionBar;
