export const FETCH_DELIVER_FROM_SHOWROOM = 'FETCH_DELIVER_FROM_SHOWROOM';
export const FETCH_DELIVER_FROM_SHOWROOM_FAILURE = 'FETCH_DELIVER_FROM_SHOWROOM_FAILURE';
export const FETCH_DELIVER_FROM_SHOWROOM_SUCCESS = 'FETCH_DELIVER_FROM_SHOWROOM_SUCCESS';

export const fetchDeliverFromShowroom = () => ({
    type: FETCH_DELIVER_FROM_SHOWROOM,
    loading: true
});

export const fetchDeliverFromShowroomFailure = (error) => ({
    type: FETCH_DELIVER_FROM_SHOWROOM_FAILURE,
    error
});
export const fetchDeliverFromShowroomSuccess = (isDeliverFromShowroomAllowed) => ({
    type: FETCH_DELIVER_FROM_SHOWROOM_SUCCESS,
    isDeliverFromShowroomAllowed
});
