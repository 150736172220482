import { Field } from '@scandipwa/scandipwa/src/util/Query';

import CmsBlockQuery from 'Query/CmsBlock.query';
import {
    CmsBlockContainer as SourceCmsBlockContainer
} from 'SourceComponent/CmsBlock/CmsBlock.container';
import { getGuestQuoteId } from 'Util/Cart/Token';
import { fetchMutation } from 'Util/Request';

/** @namespace Component/CmsBlock/Container */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    _getCmsBlock() {
        const { identifier } = this.props;

        if (identifier !== 'cart_cms') {
            this.fetchData(
                [CmsBlockQuery.getQuery({ identifiers: [identifier] })],
                ({ cmsBlocks: { items } }) => {
                    if (!items.length) {
                        return;
                    }

                    this.setState({ cmsBlock: items[0] });
                }
            );
        } else {
            fetchMutation(
                new Field('cmsBlocksCartCms')
                    .addArgument('identifiers', '[String]', identifier)
                    .addArgument('guest_quote_id', '[String]', getGuestQuoteId())
                    .addField(CmsBlockQuery._getItemsField())
                    .setAlias('cmsBlocksCartCms')
            ).then(
                ({ cmsBlocksCartCms: { items } }) => {
                    if (!items.length) {
                        return;
                    }

                    this.setState({ cmsBlock: items[0] });
                },
            );
        }
    }
}

export default CmsBlockContainer;
