/** @namespace Sofacompany/Component/GoogleTagManager/Utils/Event/convertValuesToLowerCase */
export const convertValuesToLowerCase = (data) => {
    if (data) {
        Object.entries(data).forEach(([key, value]) => {
            if (typeof value === 'string') {
                data[key] = value.toLowerCase();
            } else if (typeof value === 'object') {
                convertValuesToLowerCase(value);
            }
        });

        return data;
    }

    return data;
};
