import Draggable from 'Component/Draggable';
import {
    Slider as SourceSlider
} from 'SourceComponent/Slider/Slider.component';

import './Slider.style';

export class Slider extends SourceSlider {
    renderCrumb(prop, i) {
        const { activeImage } = this.props;
        const isActive = i === Math.abs(-activeImage);

        if (!prop) {
            return null;
        }

        return (
            <button
              block="Slider"
              elem="Image"
              aria-label={ __('button') }
              mods={ { type: 'single' } }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => this.changeActiveImage(i) }
            >
                <div
                  block="Slider"
                  elem="Crumb"
                  mods={ { isActive } }
                />
            </button>
        );
    }

    renderSliderContent() {
        const {
            activeImage, children, isNoDrag
        } = this.props;

        if (!this.getIsSlider()) {
            return children;
        }

        if (isNoDrag) {
            // dont use draging
            return (
                <Draggable
                  mix={ { block: 'Slider', elem: 'Wrapper' } }
                  draggableRef={ this.draggableRef }
                  shiftX={ -activeImage * this.getSlideWidth() }
                >
                    { children }
                </Draggable>
            );
        }

        return (
            <Draggable
              mix={ { block: 'Slider', elem: 'Wrapper' } }
              draggableRef={ this.draggableRef }
              onDragStart={ this.handleDragStart }
              onDragEnd={ this.handleDragEnd }
              onDrag={ this.handleDrag }
              onClick={ this.handleClick }
              shiftX={ -activeImage * this.getSlideWidth() }
            >
                { children }
            </Draggable>
        );
    }
}

export default Slider;
