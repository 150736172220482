import Field from 'Component/Field';
import {
    ProductAttributeValue as SourceProductAttributeValue
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';
import { getBooleanLabel } from 'Util/Product';

import './ProductAttributeValue.style.override';

/** @namespace Sofacompany/Component/ProductAttributeValue/Component/ProductAttributeValue */
export class ProductAttributeValue extends SourceProductAttributeValue {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getOptionLabel(value) {
        const {
            attribute: {
                attribute_options,
                is_boolean
            }
        } = this.props;

        if (attribute_options) {
            const optionValues = attribute_options[value];

            if (optionValues) {
                const { label } = optionValues;

                return {
                    ...optionValues,
                    label: `${getBooleanLabel(label, is_boolean)}`
                };
            }
        }

        return {};
    }

    renderColorValue(color, label) {
        const { isFormattedAsText } = this.props;

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        const style = {
            '--option-background-color': color
        };

        return (
            <>
                <data
                  block="ProductAttributeValue"
                  elem="Color"
                  value={ label }
                  title={ label }
                  style={ style }
                />
            { label }

            </>
        );
    }

    renderDropdown(value) {
        const { isSelected } = this.props;

        const trimmedText = value?.trim();

        return (
            <Field
              id={ value }
              name={ value }
              type="checkbox"
              label={ trimmedText }
              value={ value }
              mix={ {
                  block: 'ProductAttributeValue',
                  elem: 'Text',
                  mods: { isSelected }
              } }
              checked={ isSelected }
            />
        );
    }

    renderImageValue(img, label) {
        const { isFormattedAsText } = this.props;

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        return (
            <>

                <data
                  block="ProductAttributeValue"
                  elem="Image-Overlay"
                  value={ label }
                  title={ label }
                >
                    <img
                      block="ProductAttributeValue"
                      elem="Image"
                      src={ `/media/attribute/swatch${img}` }
                      alt={ label }
                    />
                </data>
                 { label }
            </>
        );
    }
}

export default ProductAttributeValue;
