import PropTypes from 'prop-types';

import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    Breadcrumb as SourceBreadcrumb
} from 'SourceComponent/Breadcrumb/Breadcrumb.component';
/** @namespace Sofacompany/Component/Breadcrumb/Component/Breadcrumb */
export class Breadcrumb extends SourceBreadcrumb {
    static propTypes = {
        ...SourceBreadcrumb.propTypes,
        name: PropTypes.PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ])
    };

    renderLink() {
        const {
            url,
            index,
            isDisabled,
            name,
            isPlaceHolder
        } = this.props;

        const linkTo = isDisabled ? '' : url;

        return (
            <Link
              block="Breadcrumb"
              elem="Link"
              to={ linkTo || '' }
              tabIndex={ isDisabled ? '-1' : '0' }
              isDisabled={ isDisabled }
            >
                <meta itemProp="item" content={ window.location.origin + (url || '') } />
                <span itemProp="name">
                    { name && this.renderName() }
                    { isPlaceHolder && <TextPlaceholder length="short" /> }
                </span>
                <meta itemProp="position" content={ index } />
            </Link>
        );
    }

    render() {
        const { index, name, isPlaceHolder } = this.props;

        if (name === '' && !isPlaceHolder) {
            return null;
        }

        return (
            <li
              block="Breadcrumb"
              key={ index }
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
            >
                { this.renderLink() }
            </li>
        );
    }
}

export default Breadcrumb;
