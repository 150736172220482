/** @namespace Sofacompany/Util/CategoryPath/ */
export const convertToPath = ({ url, url_key, categories }) => {
    if (url && url_key && categories) {
        return categories
            .filter((x) => url.replace(url_key, '').includes(x.url))
            .sort((a, b) => a.url.length - b.url.length)
            .map((acc) => `${acc.id}#${acc.url_key}`)
            .join(';');
    }

    return null;
};
