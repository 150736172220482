import { TOGGLE_NEWSLETTER } from './Newsletter.action';

/** @namespace Sofacompany/Store/Newsletter/Reducer/getInitialState */
export const getInitialState = () => ({
    isNewsletterVisible: true
});

/** @namespace Sofacompany/Store/Newsletter/Reducer/NewsletterReducer */
export const NewsletterReducer = (state = getInitialState(), action) => {
    const {
        isNewsletterVisible
    } = action;

    switch (action.type) {
    case TOGGLE_NEWSLETTER:
        return {
            ...state,
            isNewsletterVisible
        };

    default:
        return state;
    }
};

export default NewsletterReducer;
