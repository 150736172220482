export const UPDATE_CLICK_DATA_LAYER_EVENT = 'UPDATE_CLICK_DATA_LAYER_EVENT';
export const UPDATE_SEARCH_BAR = 'UPDATE_SEARCH_BAR';
export const UPDATE_SEARCH_LOAD_STATUS = 'UPDATE_SEARCH_LOAD_STATUS';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';

/** @namespace Sofacompany/Store/SearchBar/Action/updateSearchBar */
export const updateSearchBar = (result) => ({
    type: UPDATE_SEARCH_BAR,
    result
});

/** @namespace Sofacompany/Store/SearchBar/Action/updateLoadStatus */
export const updateLoadStatus = (status) => ({
    type: UPDATE_SEARCH_LOAD_STATUS,
    isLoading: status
});

/** @namespace Sofacompany/Store/SearchBar/Action/clearSearchResults */
export const clearSearchResults = () => ({
    type: CLEAR_SEARCH_RESULTS
});

/** @namespace Sofacompany/Store/SearchBar/Action/updateClickDataLayerEvent */
export const updateClickDataLayerEvent = (status) => ({
    type: UPDATE_CLICK_DATA_LAYER_EVENT,
    status
});
