import Breadcrumb from 'Component/Breadcrumb';
import {
    Breadcrumbs as SourceBreadcrumbs
} from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';

/** @namespace Sofacompany/Component/Breadcrumbs/Component/Breadcrumbs */
export class Breadcrumbs extends SourceBreadcrumbs {
    renderBreadcrumbList(breadcrumbs) {
        const breadcrumbsWithHome = Array.from(breadcrumbs);

        return breadcrumbsWithHome.map((_, i) => this.renderBreadcrumb(
            breadcrumbsWithHome[breadcrumbsWithHome.length - 1 - i], i
        ));
    }

    renderBreadcrumb({ url, name }, i) {
        const { breadcrumbs } = this.props;
        const isDisabled = !url || breadcrumbs.length - 1 === i;

        return (
            <Breadcrumb
              name={ name }
              url={ url }
              index={ i + 1 }
              key={ i }
              isDisabled={ isDisabled }
              isPlaceHolder={ !breadcrumbs?.length }
            />
        );
    }
}

export default Breadcrumbs;
