export const UPDATE_SINGLE_POST = 'UPDATE_SINGLE_POST';
export const NO_POST = 'NO_POST';

/** @namespace Sofacompany/Store/PostsDetails/Action/updateSinglePost */
export const updateSinglePost = (post) => ({
    type: UPDATE_SINGLE_POST,
    post
});

/** @namespace Sofacompany/Store/PostsDetails/Action/postException */
export const postException = (message) => ({
    type: NO_POST,
    message
});
