import BrowserDatabase from 'Util/BrowserDatabase';

export const UPDATEDATA = 'UPDATEDATA';
export const CLEARDATA = 'CLEARDATA';
export const CURRENT_INFO = 'Curent_checkout_info';

/** @namespace Sofacompany/Store/CurrentCheckoutInfo/Reducer/getInitialState */
export const getInitialState = () => ({
    data: BrowserDatabase.getItem(CURRENT_INFO) || {}
});

export const CurrentCheckoutInfoReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATEDATA:
        const { data } = action;
        BrowserDatabase.setItem(
            data,
            CURRENT_INFO
        );

        return {
            ...state,
            data
        };
    case CLEARDATA:
        BrowserDatabase.deleteItem(CURRENT_INFO);
        return {
            ...state,
            data: {}
        };

    default:
        return state;
    }
};

export default CurrentCheckoutInfoReducer;
