/* eslint-disable import/no-cycle */

import Event, { EVENT_GTM_PLP_FILTER_CLICK } from 'Util/Event';

import BaseEvent from './BaseEvent.event';

export const CLICK_EVENT_DELAY = 500;
export const SPAM_PROTECTION_DELAY = 1000;

/**
 * On checkout
 * @namespace Sofacompany/Component/GoogleTagManager/Events/PLPFilterClick/Event/PLPFilterClickEvent
 */
export class PLPFilterClickEvent extends BaseEvent {
    /**
     * Event fire delay
     *
     * @type {number}
     */
    eventHandleDelay = CLICK_EVENT_DELAY;

    /**
     * Bind
     */
    bindEvent() {
        Event.observer(EVENT_GTM_PLP_FILTER_CLICK, ({ value }) => {
            this.handle(value);
        });
    }

    /**
     * Handle
     */
    handler(value) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        this.pushEventData({
            filterClicked: value
        });
    }
}

export default PLPFilterClickEvent;
