/* eslint-disable max-len */
import { lazy } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';
import {
    WidgetFactory as SourceWidgetFactory
} from 'SourceComponent/WidgetFactory/WidgetFactory.component';

import {
    CONTENT_LIST,
    CONTENT_VIEW,
    HIGHLIGHTED_CATEGORY
} from './WidgetFactory.config';

export const ContentViewWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contentViewWidget" */ 'Component/ContentViewWidget'));
export const ContentListWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contentListWidget" */ 'Component/ContentListWidget'));
export const HighlightedCategoryWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "highlightedCategoryWidget" */ 'Component/HighlightedCategoryWidget'));

/** @namespace Sofacompany/Component/WidgetFactory/Component/WidgetFactory */
export class WidgetFactory extends SourceWidgetFactory {
    renderMap = {
        ...this.renderMap,
        [CONTENT_VIEW]: {
            component: ContentViewWidget
        },
        [CONTENT_LIST]: {
            component: ContentListWidget
        },
        [HIGHLIGHTED_CATEGORY]: {
            component: HighlightedCategoryWidget
        }
    };

    renderContent() {
        const { type } = this.props;
        const {
            component: Widget,
            fallback
        } = this.renderMap[type] || {};

        if (Widget !== undefined) {
            return (
                <RenderWhenVisible fallback={ fallback }>
                    <Widget { ...this.props } />
                </RenderWhenVisible>
            );
        }

        return null;
    }
}

export default WidgetFactory;
