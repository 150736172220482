import ProductListQuery from 'Query/ProductList.query';
import {
    LINKED_PRODUCTS,
    LinkedProductsDispatcher as SourceLinkedProductsDispatcher
} from 'SourceStore/LinkedProducts/LinkedProducts.dispatcher';
import { updateLinkedProducts } from 'Store/LinkedProducts/LinkedProducts.action';
import { showNotification } from 'Store/Notification/Notification.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';

export {
    LINKED_PRODUCTS
};

/** @namespace Sofacompany/Store/LinkedProducts/Dispatcher/LinkedProductsDispatcher */
export class LinkedProductsDispatcher extends SourceLinkedProductsDispatcher {
    getLinkedProducts(dispatch, data) {
        const { linkType, product } = data;

        if (!product) {
            return;
        }

        const options = {
            sku: product.sku,
            type: linkType,
            notRequireInfo: true,
            isMediaOnly: false
        };

        // Pass skus in case of multiple products in cart
        if (product.products) {
            const skus = [];
            product.products.forEach((item) => {
                const { sku } = item;
                skus.push(sku);
            });

            options.sku = skus;
        }

        const query = ProductListQuery.getLinkedProductsQuery(options);

        this.fetchData(
            [query],
            /** @namespace Sofacompany/Store/LinkedProducts/Dispatcher/fetchQuery/then */
            (data) => {
                // eslint-disable-next-line fp/no-let
                let linkedProducts = [];
                data.products.items.forEach((item) => {
                    const { products, id } = item;
                    linkedProducts.id = id;
                    products.forEach((item) => {
                        linkedProducts[item.id] = item;
                    });
                });

                // Sort products by price in case of multiple products in cart
                if (data.products.items.length > 1) {
                    linkedProducts = linkedProducts.sort(
                        // eslint-disable-next-line max-len
                        (a, b) => a.price_range.minimum_price.final_price.value - b.price_range.minimum_price.final_price.value
                    );
                }

                BrowserDatabase.setItem(linkedProducts, LINKED_PRODUCTS);
                dispatch(updateLinkedProducts({ [linkType]: linkedProducts }));
                this._requestMediaGallery(linkedProducts, dispatch, options);
            }
        );
    }

    clearLinkedProducts(dispatch, updateCrosssel = false) {
        const linkedProducts = {
            similar: { total_count: 0, items: [] },
            related: { total_count: 0, items: [] },
            upsell: { total_count: 0, items: [] },
            crosssell: { total_count: 0, items: [] },
            shop_the_look: { total_count: 0, items: [] }
        };

        BrowserDatabase.setItem(linkedProducts, LINKED_PRODUCTS);

        dispatch(updateLinkedProducts({
            ...linkedProducts,
            updateCrosssel
        }));
    }

    _requestMediaGallery(linkedProducts, dispatch, options) {
        const items = linkedProducts;

        const { type } = options;

        const skus = [];
        items.filter((e) => skus.push(`"${e.sku}"`));

        const requestOptions = {
            ...options,
            sku: null,
            type: null,
            args: {
                currentPage: 1,
                filter: {
                    productsSkuArray: skus
                }
            },
            isMediaOnly: true
        };

        const query = [ProductListQuery.getQuery(requestOptions)];

        executeGet(prepareQuery(query), 'ProductList', Number('86400')).then(
            /** @namespace Sofacompany/Store/LinkedProducts/Dispatcher/executeGet/then */
            (productData) => {
                const obj = {
                    items: [],
                    id: items.id
                };

                items.forEach((item) => {
                    const { sku } = item;

                    // eslint-disable-next-line fp/no-let
                    let newIndex;
                    // eslint-disable-next-line no-return-assign
                    productData.products.items.filter((e, index) => (e.sku === sku ? newIndex = index : null));

                    item = {
                        ...item,
                        ...productData.products.items[newIndex]
                    };

                    obj.items.push(item);
                });

                dispatch(updateLinkedProducts({ [type]: obj }));
            },
            /** @namespace Sofacompany/Store/LinkedProducts/Dispatcher/executeGet/then */
            (errors) => {
                dispatch(showNotification('error', errors[0].message || 'Error fetching Product Media Gallery'));
            }
        );
    }
}

export default new LinkedProductsDispatcher();
