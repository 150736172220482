import { getLocaleFromUrl } from 'Util/Global';

export * from 'SourceComponent/Header/Header.config';

export const globalRoutes = [];
globalRoutes['en-us'] = {
    designyourownsofa: 'design-your-own-sofa',
    designer: 'designer',
    categories: 'categories',
    products: 'products',
    product: 'product'
};
globalRoutes['da-dk'] = {
    designyourownsofa: 'design-din-egen-sofa',
    designer: 'designer',
    categories: 'kategorier',
    products: 'produkter',
    product: 'produkt'
};
globalRoutes['sv-se'] = {
    designyourownsofa: 'designa-sjalv',
    designer: 'designer',
    categories: 'kategorier',
    products: 'produkter',
    product: 'produkt'
};
globalRoutes['nb-no'] = {
    designyourownsofa: 'bygg-din-egen-sofa',
    designer: 'designer',
    categories: 'kategorier',
    products: 'produkter',
    product: 'produkt'
};
globalRoutes['nl-nl'] = {
    designyourownsofa: 'zelf-ontwerpen',
    designer: 'designer',
    categories: 'categorieen',
    products: 'producten',
    product: 'product'
};
globalRoutes['nl-be'] = {
    designyourownsofa: 'zelf-ontwerpen',
    designer: 'designer',
    categories: 'categorieen',
    products: 'producten',
    product: 'product'
};
globalRoutes['de-ch'] = {
    designyourownsofa: 'sofa-konfigurator',
    designer: 'designer',
    categories: 'kategorien',
    products: 'produkte',
    product: 'Produkt'
};
globalRoutes['de-de'] = {
    designyourownsofa: 'sofa-konfigurator',
    designer: 'designer',
    categories: 'kategorien',
    products: 'produkte',
    product: 'Produkt'
};
globalRoutes['de-at'] = {
    designyourownsofa: 'sofa-konfigurator',
    designer: 'designer',
    categories: 'kategorien',
    products: 'produkte',
    product: 'Produkt'
};
globalRoutes['fr-be'] = {
    designyourownsofa: 'design-your-own-sofa',
    designer: 'designer',
    categories: 'categories',
    products: 'products',
    product: 'product'
};
globalRoutes['fr-ch'] = {
    designyourownsofa: 'design-your-own-sofa',
    designer: 'designer',
    categories: 'categories',
    products: 'products',
    product: 'product'
};
globalRoutes['fr-fr'] = {
    designyourownsofa: 'design-your-own-sofa',
    designer: 'designer',
    categories: 'categories',
    products: 'products',
    product: 'product'
};

export const locale = getLocaleFromUrl();

export const CATEGORIES = globalRoutes[locale].categories;
export const PRODUCTS = globalRoutes[locale].products;
export const PRODUCT = globalRoutes[locale].product;
export const DYO = globalRoutes[locale].designyourownsofa;

export const SEARCH_POPUP = 'search-popup';
export const STORES = 'storelocator';
export const SHOWROOM = 'showroom';
export const CHECKOUT = 'checkout';
export const WISHLIST = 'wishlist';
export const CATEGORY_STICKY = 'category-sticky';
export const NEWSLETTER = 'newsletter';
export const FABRIC_SAMPLES = 'fabric-samples';
export const URL_REWRITE = 'url-rewrite';
export const UPDATE_SEARCH_FREQUENCY = 1000; // (ms)

export const DISABLED_STORES = ['at'];
