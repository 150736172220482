import { PROMOTIONBAR_ISHIDDEN } from 'Component/PromotionBar/PromotionBar.config';
import BrowserDatabase from 'Util/BrowserDatabase';

import {
    UPDATE_NOTIFICATION_BAR,
    UPDATE_VISIBILITY
} from './PromotionBar.action';

export const initialState = {
    hasFetchedOnce: BrowserDatabase.getItem(PROMOTIONBAR_ISHIDDEN) || false,
    promotionbars: [],
    isHidden: BrowserDatabase.getItem(PROMOTIONBAR_ISHIDDEN) || false
};

/** @namespace Sofacompany/Store/PromotionBar/Reducer/PromotionBarReducer */
export const PromotionBarReducer = (state = initialState, action) => {
    const { promotionbars, type } = action;

    switch (type) {
    case UPDATE_NOTIFICATION_BAR:
        const { storeConfig: { id: storeID = 1 } = {} } = BrowserDatabase.getItem('config') || {};
        const array = promotionbars.filter((e) => Number(e.store) === Number(storeID) && e.active === true);
        return { ...state, promotionbars: array, hasFetchedOnce: true };
    case UPDATE_VISIBILITY:
        const { status } = action;
        BrowserDatabase.setItem(status, PROMOTIONBAR_ISHIDDEN);
        return {
            ...state,
            isHidden: status
        };
    default:
        return state;
    }
};

export default PromotionBarReducer;
