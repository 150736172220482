import Icon from 'Component/Icon';
import {
    ProductWishlistButton as SourceProductWishlistButton
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';
import { GIFTCARD } from 'Store/GiftCard/GiftCard.dispatcher';

import './ProductWishlistButton.style';

/** @namespace Sofacompany/Component/ProductWishlistButton/Component/ProductWishlistButton */
export class ProductWishlistButton extends SourceProductWishlistButton {
    getTitle = () => {
        const { isInWishlist, isReady } = this.props;

        if (!isReady) {
            return __('Please select variant first!');
        }

        if (isInWishlist) {
            return __('Remove from Wishlist');
        }

        return __('Add to Wishlist');
    };

    renderButton() {
        const {
            isInWishlist,
            isDisabled,
            mix,
            product: { type_id }
        } = this.props;

        if (type_id === GIFTCARD) {
            return null;
        }

        return (
            <button
              block="ProductWishlistButton"
              elem="Button"
              mods={ { isInWishlist, isDisabled } }
              mix={ { mix } }
              title={ this.getTitle() }
              onClick={ this.onClick }
            >
                <span>
                    { __('Add to Wishlist') }
                </span>
                <Icon icon="heart" />
            </button>
        );
    }
}

export default ProductWishlistButton;
