export const UPDATECATEGORY = 'UPDATECATEGORY';
export const CLEARCATEGORY = 'CLEARCATEGORY';

/** @namespace Sofacompany/Store/CurrentCategory/Reducer/getInitialState */
export const getInitialState = () => ({
    id: '',
    identifier: ''
});

/** @namespace Sofacompany/Store/CurrentCategoryReducer/Reducer/CurrentCategoryReducer */
export const CurrentCategoryReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATECATEGORY:
        const { id, identifier } = action;
        return {
            ...state,
            id,
            identifier
        };
    case CLEARCATEGORY:
        return {
            ...state,
            id: '',
            identifier: ''
        };

    default:
        return state;
    }
};

export default CurrentCategoryReducer;
