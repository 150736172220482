import { getIndexedProducts } from 'Util/Product';

import {
    CLEAR_SEARCH_RESULTS,
    UPDATE_CLICK_DATA_LAYER_EVENT,
    UPDATE_SEARCH_BAR,
    UPDATE_SEARCH_LOAD_STATUS
} from './SearchBar.action';

/** @namespace Sofacompany/Store/SearchBar/Reducer/getInitialState */
export const getInitialState = () => ({
    productsInSearch: [],
    isLoading: true,
    clickDataLayerEvent: false
});

/** @namespace Sofacompany/Store/SearchBar/Reducer/SearchBarReducer */
export const SearchBarReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_SEARCH_BAR:
        const { result: { products: { items: initialItems } } } = action;

        return {
            ...state,
            productsInSearch: getIndexedProducts(initialItems)
        };

    case UPDATE_SEARCH_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    case CLEAR_SEARCH_RESULTS:
        return {
            ...state,
            productsInSearch: getInitialState().productsInSearch
        };

    case UPDATE_CLICK_DATA_LAYER_EVENT:
        const { status } = action;

        return {
            ...state,
            clickDataLayerEvent: status
        };

    default:
        return state;
    }
};

export default SearchBarReducer;
