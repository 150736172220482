import {
    filterData,
    getInitialState as SourceGetInitialState,
    updateEveryTime
} from 'SourceStore/Meta/Meta.reducer';
import Event, { EVENT_GTM_META_UPDATE } from 'Util/Event';

import { UPDATE_META } from './Meta.action';

export {
    updateEveryTime,
    filterData
};

// TODO: implement getInitialState
export const getInitialState = () => ({
    ...SourceGetInitialState,
    default_meta: false,
    is_ready: false
});

// TODO: implement MetaReducer
/** @namespace Sofacompany/Store/Meta/Reducer/MetaReducer */
export const MetaReducer = (
    state = getInitialState(),
    action
) => {
    const { payload = {}, type } = action;

    switch (type) {
    case UPDATE_META:
        const filteredData = filterData(payload);

        const { title } = filteredData;
        const { title: stateTitle } = state;

        if (!filteredData?.default_meta) {
            filteredData.is_ready = true;
        }
        if (title !== stateTitle) {
            Event.dispatch(EVENT_GTM_META_UPDATE, filteredData);
        }

        return {
            ...state,
            ...filteredData
        };

    default:
        return state;
    }
};

export default MetaReducer;
