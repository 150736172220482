/* eslint-disable max-len */
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

import ContentWrapper from 'Component/ContentWrapper';
import Image from 'Component/Image';
import Link from 'Component/Link/Link.component';
import ProductCard from 'Component/ProductCard';
import {
    PRODUCTCARD_WIDTH
} from 'Component/ProductCard/ProductCard.config';
import ProductCardList from 'Component/ProductCardList/ProductCardList.container';
import { SHOP_THE_LOOK_IMAGE } from 'Route/ProductPage/ProductPage.config';
import {
    ProductLinks as SourceProductLinks
} from 'SourceComponent/ProductLinks/ProductLinks.component';
import { SHOP_THE_LOOK } from 'Store/LinkedProducts/LinkedProducts.reducer';
import isMobile from 'Util/Mobile';

/** @namespace Sofacompany/Component/ProductLinks/Component/ProductLinks */
export class ProductLinks extends SourceProductLinks {
    static propTypes = {
        ...SourceProductLinks.propTypes,
        showAsGrid: PropTypes.bool.isRequired,
        linkedProducts: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array
        ]),
        title: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
            PropTypes.string
        ]).isRequired
    };

    renderHeading() {
        const {
            title, isCart, checkIfActive, linkType
        } = this.props;

        if (isCart && checkIfActive()) {
            return null;
        }

        return (
            <h2 block="ProductLinks" elem="Title" mods={ { isCart, shopTheLook: linkType === SHOP_THE_LOOK } }>
                { title }
            </h2>
        );
    }

    // eslint-disable-next-line no-unused-vars
    renderProductCard(product, i, isCart) {
        const { product: { products } } = this.props;
        const { id = i, sku } = product;

        if (isCart && !!products.find((x) => x?.sku === sku)) {
            return null;
        }

        if (isCart) {
            return (
                <ProductCardList
                  product={ product }
                  key={ id }
                  isCart={ isCart }
                  showAddToCard
                  displayQtyIncrementText={ false }
                />
            );
        }

        return (
            <ProductCard
              product={ product }
              key={ id }
              showAddToCard
              displayQtyIncrementText={ false }
            />
        );
    }

    renderShopTheLookProduct(product, index) {
        return (
            <li block="ProductLinks" elem="List-Item" key={ index }>
                <Link
                  to={ product.url }
                  block="ProductLinks"
                  elem="List-Item-Url"
                >
                    <span block="ProductLinks" elem="List-Item-Name">
                        { product.sofa_original_name }
                        &nbsp;
                    </span>
                    <span block="ProductLinks" elem="List-Item-Price">
                        { product.price_range?.maximum_price?.final_price?.currency }
                        &nbsp;
                        { product.price_range?.maximum_price?.final_price?.value }
                    </span>
                </Link>
            </li>
        );
    }

    renderBreadcrumbs() {
        const {
            scrolledTo,
            linkType,
            numberOfProductsToDisplay,
            linkedProducts: { [linkType]: { items } }
        } = this.props;

        if (!items) {
            return null;
        }

        return (
            <div block="ProductLinks" elem="Breadcrumbs">
                { items.slice(0, numberOfProductsToDisplay).map((_, i) => (
                    <div
                      key={ i }
                      block="ProductLinks"
                      elem="Breadcrumb"
                      mods={ {
                          isActive: scrolledTo >= (PRODUCTCARD_WIDTH * i)
                          && scrolledTo < (PRODUCTCARD_WIDTH * (i + 1))
                      } }
                    >
                        { i }
                    </div>
                )) }
            </div>
        );
    }

    renderItems() {
        const {
            linkType,
            linkedProducts: { [linkType]: { items } },
            numberOfProductsToDisplay,
            handleVisibilityChange = () => {},

            pageSize,
            isCart,
            addAllToBasketClick,
            isLoading
        } = this.props;

        if (!items) {
            return Array.from(
                { length: numberOfProductsToDisplay },
                (_, i) => this.renderProductCard({}, i)
            );
        }

        if (linkType === SHOP_THE_LOOK) {
            return (
                <>
                    { items.map((product, index) => (this.renderShopTheLookProduct(product, index))) }
                    <button
                      onClick={ addAllToBasketClick }
                      className="ProductLinks-Add-All-To-Cart"
                      block="AddToCart"
                      mods={ { isLoading } }
                      mix={ { elem: 'AddToCart' } }
                      disabled={ isLoading }
                    >
                        <span>{ __('Add all to basket') }</span>
                        <span className="adding">{ __('Adding...') }</span>
                    </button>
                </>
            );
        }

        const startAt = 1;
        const mulitiplyer = 0;
        const starPosition = pageSize * mulitiplyer;
        items.forEach((x, i) => {
            x.position = (starPosition + i + startAt);
        });

        return (
            <>
                { items.slice(0, numberOfProductsToDisplay).map((product, i) => (
                <VisibilitySensor
                  key={ i }
                  partialVisibility
                  delayedCall
                  onChange={ (isVisible) => handleVisibilityChange(isVisible, i, items, 1) }
                >
                    <>
                        { this.renderProductCard(product, i, isCart) }
                    </>
                </VisibilitySensor>

                )) }
            </>
        );
    }

    renderShopTheLook() {
        const {
            areDetailsLoaded,
            product
        } = this.props;

        if (!areDetailsLoaded) {
            return null;
        }

        const {
            media_gallery_entries
        } = product;

        // eslint-disable-next-line fp/no-let
        let shopTheLookImage = null;

        if (media_gallery_entries && media_gallery_entries.length > 0) {
            media_gallery_entries.map((media) => {
                const { types, label } = media;

                types.filter((e) => {
                    if (e === SHOP_THE_LOOK_IMAGE) {
                        shopTheLookImage = media.large.url;
                    }
                });

                shopTheLookImage = !shopTheLookImage && (label === SHOP_THE_LOOK_IMAGE) ? media.large.url : shopTheLookImage;
            });
        }

        return (
            <ContentWrapper
              mix={ { block: 'ProductLinks' } }
              wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper' } }
              label={ __('Linked products') }
            >
                { this.renderHeading() }
                <ContentWrapper
                  mix={ { block: 'ProductLinks' } }
                  wrapperMix={ { block: 'ProductLinks', elem: 'ShopTheLook-Wrapper' } }
                  label={ __('Shop The Look products') }
                >
                    <div block="ProductLinks" elem="ShopTheLook-Image-Wrapper">
                        <ul block="ProductLinks" elem="Image">
                            { shopTheLookImage && <Image src={ shopTheLookImage } /> }
                        </ul>
                    </div>
                    <div block="ProductLinks" elem="ShopTheLook-List-Wrapper">
                        <ul block="ProductLinks" elem="List">
                            { this.renderItems() }
                        </ul>
                    </div>
                </ContentWrapper>
            </ContentWrapper>
        );
    }

    render() {
        const {
            linkType,
            linkedProducts: { [linkType]: { items } },

            onScroll,
            areDetailsLoaded,
            showAsGrid,
            numberOfProductsToDisplay,
            isCart
        } = this.props;

        if (!areDetailsLoaded) {
            return null;
        }

        if (linkType === SHOP_THE_LOOK) {
            return this.renderShopTheLook();
        }

        const style = !isCart && !showAsGrid && isMobile.any() ? {
            // eslint-disable-next-line no-magic-numbers
            width: (220 + 15) * items.slice(0, numberOfProductsToDisplay).length
        } : {};

        return (
            <ContentWrapper
              mix={ { block: 'ProductLinks' } }
              wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper', mods: { showAsGrid, isCart } } }
              label={ __('Linked products') }
            >
                { this.renderHeading() }
                <div block="ProductLinks" elem="List-Wrapper" mods={ { isCart } } onScroll={ onScroll } onDrag={ onScroll }>
                    <ul block="ProductLinks" elem="List" mods={ { showAsGrid, isCart } } style={ style }>
                        { this.renderItems() }
                    </ul>
                </div>
                { !isCart && !showAsGrid && isMobile.any() && this.renderBreadcrumbs() }
            </ContentWrapper>
        );
    }
}

export default ProductLinks;
