import {
    UPDATE_STORE_FINDER,
    UPDATE_STORE_FINDER_LOADER
} from './StoreFinder.action';

export const initialState = {
    storeListCities: [],
    storeListMapped: {},
    storeByName: {},
    storeList: [],
    isLoading: false,
    selectedStoreSkus: {}
};

/** @namespace Sofacompany/Store/StoreFinder/Reducer/StoreFinderReducer */
export const StoreFinderReducer = (state = initialState, action) => {
    const { storeList, isLoading, type } = action;

    switch (type) {
    case UPDATE_STORE_FINDER:

        if (!storeList) {
            return {
                ...state
            };
        }

        if (Object.prototype.hasOwnProperty.call(storeList[0], 'skus')) {
            return {
                ...state,
                selectedStoreSkus: storeList[0]
            };
        }

        const storeListData = [];

        storeList.map((dataStore) => {
            const {
                name,
                storelocator_address,
                storelocator_city,
                storelocator_postcode,
                storelocator_phone,
                storelocator_lat,
                storelocator_lon,
                working_hours_block,
                storelocator_email,
                telephone_block,
                description,
                storelocator_image,
                storelocator_country_id,
                status,
                entity_id,
                storelocator_meta_title,
                storelocator_meta_description
            } = dataStore;

            const store = {
                store_name: name,
                address: storelocator_address,
                city: storelocator_city,
                postcode: storelocator_postcode,
                phone_number: storelocator_phone,
                latitude: storelocator_lat,
                longitude: storelocator_lon,
                store_hours: working_hours_block,
                store_email: storelocator_email,
                additional_info: telephone_block,
                description,
                image: {
                    image: storelocator_image
                },
                storelocator_country_id,
                status,
                entity_id,
                storelocator_meta_title,
                storelocator_meta_description
            };

            storeListData.push(store);
        });

        const storeListObject = storeListData.reduce((cities, store) => {
            const {
                city, longitude, latitude, store_name
            } = store;

            if (city && longitude && latitude) {
                if (!cities.storeListCities.includes(city)) {
                    cities.storeListCities.push(city);
                }

                if (!cities.storeListMapped[city]) {
                    cities.storeListMapped[city] = [];
                }

                cities.storeListMapped[city].push(store);

                cities.storeByName[store_name] = store;
            }

            return cities;
        }, { storeListCities: [], storeListMapped: {}, storeByName: {} });

        return {
            ...state,
            ...storeListObject,
            storeList: storeListData
        };

    case UPDATE_STORE_FINDER_LOADER:
        return { ...state, isLoading };

    default:
        return state;
    }
};

export default StoreFinderReducer;
