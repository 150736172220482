import {
    getInitialState as SourceGetInitialState
} from 'SourceStore/Category/Category.reducer';

import { UPDATE_CURRENT_CATEGORY, UPDATE_SIBLINGS } from './Category.action';

/** @namespace Sofacompany/Store/Category/Reducer/getInitialState */
export const getInitialState = () => ({
    ...SourceGetInitialState,
    category: {},
    categorySiblings: {}
});

/** @namespace Sofacompany/Store/Category/Reducer/CategoryReducer */
export const CategoryReducer = (
    state = getInitialState(),
    { type, category, categorySiblings }
) => {
    switch (type) {
    case UPDATE_CURRENT_CATEGORY:
        return {
            ...state,
            category: { ...category }
        };
    case UPDATE_SIBLINGS:
        return {
            ...state,
            categorySiblings: { ...categorySiblings }
        };

    default:
        return state;
    }
};

export default CategoryReducer;
