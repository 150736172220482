import PropTypes from 'prop-types';

import {
    ContentWrapper as SourceContentWrapper
} from 'SourceComponent/ContentWrapper/ContentWrapper.component';

import './ContentWrapper.style';

export class ContentWrapper extends SourceContentWrapper {
    static propTypes = {
        ...SourceContentWrapper.propTypes,
        label: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ]).isRequired
    };
}

export default ContentWrapper;
