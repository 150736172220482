/* eslint-disable import/no-cycle */

import Event, { EVENT_GTM_POPUP } from 'Util/Event';

import BaseEvent from './BaseEvent.event';

/**
 * Product click event
 * @namespace Sofacompany/Component/GoogleTagManager/Events/PopupEvent/Event/PopupEvent */
export class PopupEvent extends BaseEvent {
    eventHandleDelay = 0;

    bindEvent() {
        Event.observer(EVENT_GTM_POPUP, ({ popupBox }) => {
            this.handle(
                popupBox
            );
        });
    }

    handle(...args) {
        // Set little delay on event fire. make it async, to do not block main code
        setTimeout(() => {
            this.handler(...args);
        }, this.eventHandleDelay);
    }

    handler(popupBox) {
        this.pushEventData({
            popupBox
        });
    }
}

export default PopupEvent;
