import { lazy } from 'react';

import AttentionBox from 'Component/AttentionBox';
import FieldInput from 'Component/FieldInput';
import {
    Field as SourceField
} from 'SourceComponent/Field/Field.component';

import {
    CHECKBOX_TYPE,
    DATE_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    TELEPHONE_TYPE,
    TEXTAREA_TYPE,
    VAT_TYPE
} from './Field.config';

import './Field.style.override';

export const FieldTelephone = lazy(() => import('Component/FieldTelephone'));

/** @namespace Sofacompany/Component/Field/Component/Field */
export class Field extends SourceField {
    renderTypeTelephone() {
        return (
            <FieldTelephone
              { ...this.props }
              { ...this.formRef }
            />
        );
    }

    renderTypeNumber() {
        const {
            min,
            max,
            value,
            onKeyEnterDown,
            handleChange,
            readOnly = true,
            onBlur,
            changeButtonValue = 1
        } = this.props;

        return (
            <>
                <button
                  disabled={ +value === min }
                  onClick={ () => handleChange(+value - changeButtonValue) }
                >
                    <span>–</span>
                </button>
                <FieldInput
                  { ...this.props }
                  type="number"
                  readOnly={ readOnly }
                  onChange={ (e) => handleChange(e.target.value, false) }
                  onKeyDown={ onKeyEnterDown }
                  onBlur={ onBlur ? (e) => handleChange(e.target.value, false) : null }
                />
                <button
                  disabled={ +value === max }
                  onClick={ () => handleChange(+value + changeButtonValue) }
                >
                    <span>+</span>
                </button>
            </>
        );
    }

    renderTypeDate() {
        return (
            <FieldInput
              { ...this.props }
            />
        );
    }

    renderTypeText() {
        const cleanProps = { ...this.props };
        delete cleanProps.attentionMessage;
        delete cleanProps.attention;
        delete cleanProps.subContent;
        return (
            <FieldInput
              { ...cleanProps }
              type="text"
            />
        );
    }

    renderTypeVat() {
        const { value } = this.props;
        const newProps = {
            ...this.props,
            value: value.toUpperCase()
        };

        return (
            <FieldInput
              { ...newProps }
              type="text"
            />
        );
    }

    renderInputOfType(type) {
        switch (type) {
        case CHECKBOX_TYPE:
            return this.renderCheckbox();
        case RADIO_TYPE:
            return this.renderRadioButton();
        case NUMBER_TYPE:
            return this.renderTypeNumber();
        case TEXTAREA_TYPE:
            return this.renderTextarea();
        case PASSWORD_TYPE:
            return this.renderTypePassword();
        case SELECT_TYPE:
            return this.renderSelectWithOptions();
        case TELEPHONE_TYPE:
            return this.renderTypeTelephone();
        case DATE_TYPE:
            return this.renderTypeDate();
        case VAT_TYPE:
            return this.renderTypeVat();
        default:
            return this.renderTypeText();
        }
    }

    renderAttention() {
        const { attentionMessage } = this.props;
        return (
            <AttentionBox attentionMessage={ attentionMessage } />
        );
    }

    renderSubContent() {
        const { subContent } = this.props;
        if (!subContent) {
            return null;
        }

        return (
            <div block="Field" elem="Subcontent">
                { subContent }
            </div>
        );
    }

    render() {
        const {
            mix,
            type,
            message,
            value,
            attention,
            islabelbefore,
            validationStatus,
            name
        } = this.props;

        return (
            <>
                <div
                  block="Field"
                  mods={ {
                      type,
                      hasValue: value !== '',
                      attention: !!attention,
                      revert: !!islabelbefore,
                      hasError: validationStatus === false || !!message,
                      isValid: validationStatus === true,
                      isEmail: name === 'email'

                  } }
                  mix={ mix }
                >
                    { this.renderLabel() }
                    { this.renderInputOfType(type) }
                    { this.renderSubContent() }
                    { this.renderMessage() }
                </div>
                { attention && this.renderAttention() }
            </>
        );
    }
}

export default Field;
