/* eslint-disable import/no-cycle */

import Event, { EVENT_GTM_PRODUCT_REMOVE_FROM_CART } from 'Util/Event';

import ProductHelper from '../utils';
import BaseEvent from './BaseEvent.event';

export const SPAM_PROTECTION_DELAY = 200;
/**
 * Product add to cart event
 * @namespace Sofacompany/Component/GoogleTagManager/Events/RemoveFromCart/Event/RemoveFromCartEvent
 */
export class RemoveFromCartEvent extends BaseEvent {
    /**
     * Bind add to cart
     */
    bindEvent() {
        Event.observer(EVENT_GTM_PRODUCT_REMOVE_FROM_CART, ({
            item,
            quantity
        }) => {
            this.handle(item, quantity);
        });
    }

    /**
     * Handle product add to cart
     */
    handler(item, quantity) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const productData = ProductHelper.getItemData(item);
        const { id, price } = productData;
        const removedGroupedProduct = ProductHelper.updateGroupedProduct(id, -price * quantity);

        const removedProducts = removedGroupedProduct
            ? [
                { ...productData, quantity },
                removedGroupedProduct.data
            ]
            : [{ ...productData, quantity }];

        this.pushEventData({
            ecommerce: {
                remove: {
                    products: removedProducts
                },
                cart: this.prepareCartData()
            }
        });
    }
}
export default RemoveFromCartEvent;
