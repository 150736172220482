export const UPDATESTORES = 'UPDATESTORES';

/** @namespace Sofacompany/Store/CurrentPage/Reducer/getInitialState */
export const getInitialState = () => ({
    stores: []
});

/** @namespace Sofacompany/Store/Wishlist/Reducer/WishlistReducer */
export const StoreListReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATESTORES:
        const { stores } = action;
        return {
            ...state,
            stores
        };

    default:
        return state;
    }
};

export default StoreListReducer;
