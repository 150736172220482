/* eslint-disable import/no-cycle */

import Event, { EVENT_GTM_BLOG } from 'Util/Event';

import BaseEvent from './BaseEvent.event';

/**
 * Product click event
 * @namespace Sofacompany/Component/GoogleTagManager/Events/Blog/Event/BlogEvent */
export class BlogEvent extends BaseEvent {
    eventHandleDelay = 0;

    bindEvent() {
        Event.observer(EVENT_GTM_BLOG, (wordcount) => {
            this.handle(wordcount);
        });
    }

    handler(wordcount) {
        this.pushEventData({
            wordCount: wordcount
        });
    }
}

export default BlogEvent;
