import {
    FETCH_DELIVER_FROM_SHOWROOM,
    FETCH_DELIVER_FROM_SHOWROOM_FAILURE,
    FETCH_DELIVER_FROM_SHOWROOM_SUCCESS
} from './DeliverFromShowroom.action';

export const getInitialState = () => ({
    isLoading: false
});

export const DeliverFromShowroomReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;
    switch (type) {
    case FETCH_DELIVER_FROM_SHOWROOM:
        return {
            ...state,
            isLoading: true
        };
    case FETCH_DELIVER_FROM_SHOWROOM_FAILURE:
        const { error } = action;
        return {
            ...state,
            isLoading: false,
            error
        };
    case FETCH_DELIVER_FROM_SHOWROOM_SUCCESS:
        const { isDeliverFromShowroomAllowed } = action;
        return {
            ...state,
            isLoading: false,
            error: null,
            isDeliverFromShowroomAllowed
        };
    default:
        return state;
    }
};

export default DeliverFromShowroomReducer;
