import CartQuery from 'Query/Cart.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

/** @namespace Sofacompany/Query/GiftCard/Query/GiftCardQuery */
export class GiftCardQuery {
    /**
     * Get apply giftcard to cart mutation
     * @param giftCardCode
     * @param quoteId
     * @returns {*}
     */
    getApplyGiftCardMutation(giftCardCode, quoteId) {
        const mutation = new Field('applyGiftCard')
            .addArgument('gift_card_code', 'String!', giftCardCode)
            .addField(CartQuery.getCartQuery(quoteId));

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', quoteId);
        }

        return mutation;
    }

    /**
     * Get remove giftcard from cart mutation
     * @param giftCardCode
     * @param quoteId
     * @returns {*}
     */
    getRemoveGiftCardFromCartMutation(giftCardCode, quoteId) {
        const mutation = new Field('removeGiftCard')
            .addArgument('gift_card_code', 'String!', giftCardCode)
            .addField(CartQuery.getCartQuery(quoteId));

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', quoteId);
        }

        return mutation;
    }
}

export default new GiftCardQuery();
