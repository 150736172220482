/* eslint-disable import/no-cycle */

import Event, { EVENT_GTM_404 } from 'Util/Event';

import BaseEvent from './BaseEvent.event';

/**
 * Product click event
 * @namespace Sofacompany/Component/GoogleTagManager/Events/NotFound/Event/NotFoundEvent */
export class NotFoundEvent extends BaseEvent {
    eventHandleDelay = 0;

    bindEvent() {
        Event.observer(EVENT_GTM_404, () => {
            this.handle();
        });
    }

    handle(...args) {
        // Set little delay on event fire. make it async, to do not block main code
        setTimeout(() => {
            this.handler(...args);
        }, this.eventHandleDelay);
    }

    handler() {
        this.pushEventData({
            pagePath: window.location.pathname
        });
    }
}

export default NotFoundEvent;
