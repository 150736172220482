import ProductListQuery from 'Query/ProductList.query';
import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

/** @namespace Sofacompany/Query/Cart/Query/CartQuery */
export class CartQuery extends SourceCartQuery {
    getCartQuery(quoteId) {
        const query = new Field('getCartForCustomer')
            .addFieldList(this._getCartTotalsFields(quoteId))
            .setAlias('cartData');

        if (!isSignedIn()) {
            query.addArgument('guestCartId', 'String', quoteId);
        }

        return query;
    }

    getAddressQuery(quoteId) {
        const query = new Field('cart')
            .addFieldList(this._getAddressFields())
            .addField('email')
            .setAlias('addressData');

        if (!isSignedIn()) {
            query.addArgument('cart_id', 'String!', quoteId);
        }

        return query;
    }

    getRestoreCartAddressQuery(quoteId) {
        const query = new Field('cart')
            .addFieldList(this._getTrimmedAddressFields())
            .addField('email')
            .setAlias('addressData');

        if (!isSignedIn()) {
            query.addArgument('cart_id', 'String!', quoteId);
        }

        return query;
    }

    restoreQuote(id) {
        const query = new Field('restoreQuote');
        query.addArgument('maskedId', 'String', id);

        return query;
    }

    _getCartTotalsFields(quoteId) {
        return [
            'subtotal',
            'subtotal_incl_tax',
            'items_qty',
            'tax_amount',
            'grand_total',
            'discount_amount',
            'quote_currency_code',
            'subtotal_with_discount',
            'coupon_code',
            'shipping_amount',
            'is_virtual',
            'applied_rule_ids',
            'store_id',
            'shipping_method',
            this._getCartItemsField(),
            this._getAppliedGiftCards(quoteId)
        ];
    }

    _getAddressFields() {
        return [
            this._getshippingAddresses(),
            this._getBillingAddress()
        ];
    }

    _getBillingAddress() {
        return new Field('billing_address')
            .addFieldList([
                'city',
                'company',
                'firstname',
                'lastname',
                'postcode',
                'street',
                'telephone',
                this._getCountry()
            ]);
    }

    _getshippingAddresses() {
        return new Field('shipping_addresses')
            .addFieldList([
                'city',
                'company',
                'customer_notes',
                'delivery_comment',
                'firstname',
                'lastname',
                'postcode',
                this._getSelectedShippingMethod(),
                'street',
                'telephone',
                this._getCountry()
            ]);
    }

    _getCustomizableOptionValueFields() {
        return [
            'id',
            'label',
            'value',
            'cylindo_code',
            'cylindo_name',
            this._getCustomizableOptionPriceField()
        ];
    }

    _getCountry() {
        return new Field('country')
            .addFieldList([
                'code',
                'label'
            ]);
    }

    _getSelectedShippingMethod() {
        return new Field('selected_shipping_method')
            .addFieldList([
                'carrier_code',
                'carrier_title',
                'method_code',
                'method_title',
                this._getAmount(),
                this._getAmountInclTax()
            ]);
    }

    _getAmount() {
        return new Field('amount')
            .addFieldList([
                'value'
            ]);
    }

    _getAmountInclTax() {
        return new Field('amount_incl_tax')
            .addFieldList([
                'value'
            ]);
    }

    _getAppliedGiftCards(quoteId) {
        const mutation = new Field('applied_gift_cards')
            .addFieldList([
                'code',
                'expiration_date',
                this._getCurrentBalance(),
                this._getAppliedBalance()
            ]);

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', quoteId);
        }

        return mutation;
    }

    _getCurrentBalance() {
        return new Field('current_balance')
            .addFieldList([
                'value',
                'currency'
            ]);
    }

    _getAppliedBalance() {
        return new Field('applied_balance')
            .addFieldList([
                'value',
                'currency'
            ]);
    }

    getAddProductsToCartMutation(products, quoteId) {
        const query = new Field('addProductsToCart')
            .addArgument('cartItems', '[CartItemInput!]!', products)
            .addFieldList(this.getAddProductsToCartQuery());

        if (!isSignedIn()) {
            query.addArgument('cartId', 'String!', quoteId);
        }

        return query;
    }

    getSaveCartItemsMutation(products, quoteId) {
        const query = new Field('saveCartItems')
            .addArgument('cartItems', '[CartItemInput!]!', products)
            .addFieldList(this._getSaveCartItemFields(quoteId));

        if (!isSignedIn()) {
            query.addArgument('guestCartId', 'String', quoteId);
        }

        return query;
    }

    getAddProductsToCartQuery() {
        const query = new Field('cart')
            .addFieldList(['id'])
            .setAlias('cartData');

        return [query];
    }

    _getProductField() {
        ProductListQuery.options.isForLinkedProducts = true;
        ProductListQuery.options.isMediaOnly = false;
        ProductListQuery.options.isMissingDyoData = false;
        ProductListQuery.options.isFabricOnly = false;

        const productQuery = new Field('product')
            .addFieldList(ProductListQuery._getProductInterfaceFields(false, true));

        ProductListQuery.options.isForLinkedProducts = false;

        return productQuery;
    }

    _getTrimmedAddressFields() {
        return [
            this._getTrimmedBillingAddress()
        ];
    }

    _getTrimmedBillingAddress() {
        return new Field('billing_address')
            .addFieldList([
                'firstname',
                'lastname',
                'telephone'
            ]);
    }

    getAllowDeliveryFromShowroom(quoteId, showroomCode) {
        const query = new Field('getIfCartAllowsDeliverFromShowroom');

        if (!isSignedIn()) {
            query.addArgument('guestCartId', 'String', quoteId);
        }
        query.addArgument('showroomCode', 'String', showroomCode);

        return query;
    }
}

export default new CartQuery();
