export * from 'SourceRoute/ProductPage/ProductPage.config';

export const THREESIXTY_VIEWER_POPUP = 'threesixty-viewer-popup';
export const FABRIC_CODE_IMAGE = 'fabric_code_image';
export const FABRIC_IMAGE = 'fabric_image';
export const SOFA_LEGS_IMAGE = 'sofa_legs_image';
export const MEASUREMENT_FRONT = 'measurement_front';
export const MEASUREMENT_SIDE = 'measurement_side';
export const SWATCH_IMAGE = 'swatch_image';
export const FEATURED_IMAGE = 'featured_image';
export const SOFA_SKETCH = 'sketch_image';
export const SHOP_THE_LOOK_IMAGE = 'shop_the_look_image';
export const CYLINDO_ANGLED_IMAGE = 'cylindo_angled_image';
export const RESPONSE_OK = 200;
