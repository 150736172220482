/* eslint-disable max-len */
import PropTypes from 'prop-types';

import AddToCart from 'Component/AddToCart';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import ProductBadges from 'Component/ProductBadges';
import ProductPrice from 'Component/ProductPrice';
import ProductWishlistButton from 'Component/ProductWishlistButton';
import Slider from 'Component/Slider';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ProductCard as SourceProductCard
} from 'SourceComponent/ProductCard/ProductCard.component';
import { GIFTCARD } from 'Store/GiftCard/GiftCard.dispatcher';
import Event, { EVENT_GTM_KLEVU_SEARCH, EVENT_GTM_PRODUCT_CLICK } from 'Util/Event';
import { SIMPLE } from 'Util/Product';

import {
    FABRIC_CODE_IMAGE,
    PRODUCTCARD_MAX_AVAILABLEPICTURES,
    PRODUCTCARD_MAX_AVAILABLEVISUALOPTIONS
} from './ProductCardList.config';

import './ProductCardList.style';
/** @namespace Sofacompany/Component/ProductCard/Component/ProductCard */
export class ProductCardList extends SourceProductCard {
    static propTypes = {
        ...SourceProductCard.propTypes,
        onActiveImageChange: PropTypes.func.isRequired,
        activeImage: PropTypes.number.isRequired,
        showAddToCard: PropTypes.bool
    };

    static defaultProps = {
        ...SourceProductCard.defaultProps,
        showAddToCard: true
    };

    handleClick = () => {
        const {
            currentVariantIndex: configurableVariantIndex,
            selectedFilters,
            product,
            clickDataLayerEvent,
            category,
            categoryPath,
            currentPageId,
            currentPageName,
            currentPageAppender
        } = this.props;

        const isSearchPage = window.location.pathname.includes('/search');

        const productToPass = Object.keys(selectedFilters).length
            ? { ...product, configurableVariantIndex }
            : product;
        const currentPage = {
            currentPageId,
            currentPageName,
            currentPageAppender
        };

        productToPass.category = category;
        productToPass.list = currentPage;
        productToPass.catPath = categoryPath;

        Event.dispatch(EVENT_GTM_PRODUCT_CLICK, productToPass);

        if (isSearchPage) {
            const { searchTimeout } = window;

            if (clickDataLayerEvent && searchTimeout) {
                clearTimeout(searchTimeout);

                Event.dispatch(EVENT_GTM_KLEVU_SEARCH, window.location.pathname.split('/search')[1].replace('/', ''));
            }
        }

        this.registerSharedElement();
    };

    renderWishlistButton() {
        const {
            product,
            quantity,
            configurableVariantIndex,
            onProductValidationError
        } = this.props;

        if (product && !product.sku) {
            return null;
        }

        return (
            <ProductWishlistButton
              mix={ { block: 'ProductCardList', elem: 'WishlistButton' } }
              product={ product }
              quantity={ quantity }
              configurableVariantIndex={ configurableVariantIndex }
              onProductValidationError={ onProductValidationError }
            />
        );
    }

    renderAdditionalProductDetails() {
        const { product: { sku }, getAttribute } = this.props;
        const { product_list_content: { attribute_to_display } = {} } = window.contentConfiguration;
        const brand = getAttribute(attribute_to_display || 'brand') || {};

        if (sku && !brand) {
            return null;
        }

        if (Object.keys(brand).length <= 0) {
            return null;
        }

        return (
            <div
              block="ProductCard"
              elem="Brand"
              mods={ { isLoaded: !!brand } }
            >
                <ProductAttributeValue
                  attribute={ brand }
                  isFormattedAsText
                />
            </div>
        );
    }

    renderCrumbs(gallery) {
        const gallerySplitted = gallery.slice(0, PRODUCTCARD_MAX_AVAILABLEPICTURES);

        return (
            <div
              block="ProductCardList"
              elem="Crumbs"
            >
                { gallerySplitted.map((_, i) => this.renderCrumb(i)) }
            </div>
        );
    }

    renderCrumb(i) {
        const { activeImage } = this.props;
        const isActive = i === Math.abs(-activeImage);

        return (
            <button
              block="ProductCardList"
              elem="Crumb"
              key={ i }
              mods={ { isActive } }
            >
                { i }
            </button>
        );
    }

    renderSlider(gallery) {
        const {
            product: { name, thumbnail },
            activeImage,
            onActiveImageChange
        } = this.props;

        // eslint-disable-next-line fp/no-let
        let firstImage = thumbnail;

        gallery.map((image) => {
            const { types } = image;

            if (types && types.length > 0) {
                types.map((type) => {
                    if (type === 'thumbnail') {
                        firstImage = image.thumbnail;
                    }
                });
            }
        });

        // eslint-disable-next-line fp/no-let
        let gallerySplitted = gallery;

        try {
            // eslint-disable-next-line max-len
            gallerySplitted = gallery.filter((e) => !e.base.url.includes('images/product/placeholder')).splice(1, PRODUCTCARD_MAX_AVAILABLEPICTURES);
        } catch (e) {
            gallerySplitted = gallery.splice(1, PRODUCTCARD_MAX_AVAILABLEPICTURES);
        }

        return (
            <div block="ProductCardList" elem="SliderWrapper">
                <Slider
                  ref={ this.sliderRef }
                  mix={ { block: 'ProductCardList', elem: 'Slider' } }
                  activeImage={ activeImage }
                  onActiveImageChange={ onActiveImageChange }
                >
                    <div block="ProductCardList" elem="PictureWrapper">
                        <Image
                          src={ firstImage && firstImage.url }
                          alt={ name }
                          ratio="custom"
                          mix={ { block: 'ProductCardList', elem: 'Picture' } }
                          isPlaceholder={ !firstImage }
                        />
                    </div>
                    { gallerySplitted.map((image) => (
                        <div block="ProductCardList" elem="PictureWrapper" key={ `${ image.id }PictureWrapper` }>
                            <Image
                              src={ image.thumbnail && image.thumbnail.url }
                              alt={ image.label || name }
                              ratio="custom"
                              mix={ { block: 'ProductCard', elem: 'Picture' } }
                              isPlaceholder={ !image.id }
                            />
                        </div>
                    )) }
                </Slider>
            </div>
        );
    }

    renderImage(gallery) {
        const {
            product: {
                name, small_image
            }
        } = this.props;

        // eslint-disable-next-line fp/no-let
        let firstImage = small_image;

        gallery.map((image) => {
            const { types } = image;

            if (types && types.length > 0) {
                types.map((type) => {
                    if (type === 'thumbnail') {
                        firstImage = image.base;
                    }
                });
            }
        });

        try {
            gallery = gallery.filter((e) => !e.base.url.includes('images/product/placeholder')).splice(1, 1);
        } catch (e) {
            gallery = gallery.splice(1, 1);
        }

        return (
            <>
                <div block="ProductCardList" elem="PictureHover">
                    <Image
                      src={ firstImage && firstImage.url }
                      alt={ name }
                      ratio="custom"
                      mix={ { block: 'ProductCardList', elem: 'Picture' } }
                      isPlaceholder={ !firstImage }
                    />
                </div>
                { gallery.map((image, i) => (
                    <div block="ProductCardList" elem="PictureHover" key={ `${image.id}-${i}` }>
                        <Image
                          src={ image.base && image.base.url }
                          alt={ image.label || name }
                          ratio="custom"
                          mix={ { block: 'ProductCard', elem: 'Picture' } }
                          isPlaceholder={ !image.id }
                        />
                    </div>
                )) }
            </>
        );
    }

    renderPicture() {
        const {
            product: { name, thumbnail },
            getProductGallery
        } = this.props;
        const gallery = getProductGallery();
        const galleryLength = gallery.length;

        return (
            <>

                <div block="ProductCardList" elem="PictureHoverWrapper" mods={ { singleImage: galleryLength <= 1 } }>
                    { this.renderImage(gallery) }
                </div>

                <img
                  style={ { display: 'none' } }
                  loading="lazy"
                  alt={ name }
                  src={ (thumbnail && thumbnail.url) || thumbnail }
                />
            </>
        );
    }

    renderProductPrice() {
        const { product: { price_range, type_id, stock_item } } = this.props;

        if (!price_range) {
            return <TextPlaceholder />;
        }

        if (type_id === GIFTCARD) {
            const {
                productOrVariant: {
                    allow_open_amount,
                    open_amount_min,
                    open_amount_max,
                    giftcard_amounts,
                    price_range
                }
            } = this.props;

            if (allow_open_amount) {
                const price = { price_min: open_amount_min, price_max: open_amount_max, currency: price_range };

                return (
                    <ProductPrice
                      price={ price }
                      isGiftCard
                      mix={ { block: 'ProductCardList', elem: 'Price' } }
                      stock_item={ stock_item }
                      displayQtyIncrementText={ false }
                    />
                );
            }

            const price = {
                price_min: giftcard_amounts[0]?.value,
                price_max: giftcard_amounts[giftcard_amounts.length - 1]?.value
            };

            return (
                <ProductPrice
                  price={ price }
                  isGiftCard
                  mix={ { block: 'ProductCardList', elem: 'Price' } }
                  stock_item={ stock_item }
                  displayQtyIncrementText={ false }
                />
            );
        }

        return (
            <ProductPrice
              price={ price_range }
              mix={ { block: 'ProductCardList', elem: 'Price' } }
              stock_item={ stock_item }
              displayQtyIncrementText={ false }
            />
        );
    }

    renderSpacer() {
        return (
            <div block="ProductCardList" elem="Spacer" />
        );
    }

    renderVisualConfigurableOptions() {
        const { product } = this.props;
        const { sofa_variants: { items } } = product;

        // eslint-disable-next-line fp/no-let
        let colorsArray = [];
        items.forEach((item) => {
            if (item.id !== product.id) {
                item.media_gallery.forEach((image) => {
                    if (image.label === FABRIC_CODE_IMAGE) {
                        colorsArray.push(image);
                    }
                });
            }
        });

        const extraColors = colorsArray.length > PRODUCTCARD_MAX_AVAILABLEVISUALOPTIONS;

        // if more than 3 colors then add a + button || FOR NOW WE DON'T SHOW A + BUTTON, SO IT IS COMMENTED OUT BELOW
        if (extraColors) {
            colorsArray = colorsArray.splice(0, PRODUCTCARD_MAX_AVAILABLEVISUALOPTIONS);
        }

        if (!colorsArray?.length) {
            return null;
        }

        return (
            <div block="ProductCardList" elem="ConfigurableOptions">
                { colorsArray.map(({ url }, index) => (
                    <img
                      block="ProductCardList"
                      elem="Color"
                      loading="lazy"
                      key={ index }
                      alt="Fabric color"
                      src={ url }
                    />
                )) }
            </div>
        );
    }

    renderName2() {
        const { product: { sofa_original_name } } = this.props;

        return (
            <p
              block="ProductCardList"
              elem="Name2"
            >
                { sofa_original_name }
            </p>
        );
    }

    renderName3() {
        const { product: { name2 } } = this.props;

        return (
            <p
              block="ProductCardList"
              elem="Name3"
            >
                { name2?.split(',')?.[0] }
            </p>
        );
    }

    renderSofaName() {
        const { product: { sofa_original_name } } = this.props;

        return (
            <p
              block="ProductCardList"
              elem="SofaName"
            >
                { sofa_original_name }
            </p>
        );
    }

    renderMainDetails() {
        return (
            <div block="ProductCardList" elem="MainDetails">
                { this.renderSofaName() }

                    <div block="ProductCardList" elem="PriceWrapper">
                        { this.renderTierPrice() }
                        { this.renderProductPrice() }
                    </div>

            </div>
        );
    }

    renderCmsProduct() {
        const {
            isLoading,
            product: {
                name,
                small_image,
                short_description
            }
        } = this.props;

        const style = {
            backgroundImage: `url('${ small_image && small_image.url }')`
        };

        return (
            <li
              block="ProductCardList"
              elem="CmsProduct"
              style={ style }
            >
                <Loader isLoading={ isLoading } />
                <div block="ProductCardList" elem="Box">
                    <p
                      block="ProductCardList"
                      elem="Name"
                      mods={ { isLoaded: !!name } }
                    >
                        <TextPlaceholder content={ name } length="medium" />
                    </p>
                    { short_description && <Html content={ short_description.html } /> }
                </div>
            </li>
        );
    }

    renderAddToCardButton() {
        const {
            product, product: { type_id, stock_item: { enable_qty_increments, qty_increments } = {} }, category, searchPage, isCart
        } = this.props;

        if (type_id !== SIMPLE && !searchPage) {
            return null;
        }

        return (
            <AddToCart
              product={ product }
              quantity={ enable_qty_increments ? qty_increments : 1 }
              icon
              mix={ { block: 'ProductCardList', elem: 'AddToCart' } }
              category={ category }
              isWhite={ isCart }
            />
        );
    }

    renderCardWrapper(children) {
        const { linkTo, product: { url } } = this.props;

        if (!url) {
            return (<div>{ children }</div>);
        }

        return (
            <Link
              block="ProductCardList"
              elem="Link"
              to={ linkTo }
              onClick={ this.handleClick }
            >
              { children }
            </Link>
        );
    }

    renderBadges() {
        const { product: { category_badges } } = this.props;

        return <ProductBadges badges={ category_badges } type="category" />;
    }

    renderWrapperContent() {
        return (

            <figure block="ProductCardList" elem="Wrapper">
                { this.renderPicture() }
            </figure>
        );
    }

    renderWrapper() {
        const { linkTo } = this.props;
        return (
            <Link
              block="ProductCardList"
              elem="Link"
              to={ linkTo }
              onClick={ this.handleClick }
            >
                { this.renderWrapperContent() }
            </Link>
        );
    }

    render() {
        const {
            isLoading,
            showAddToCard,
            product: {
                cms_product = null

            } = {}
        } = this.props;

        if (cms_product === 1) {
            return this.renderCmsProduct();
        }

        return (
            <li block="ProductCardList">
                <Loader isLoading={ isLoading } />

                            { this.renderWrapper() }
                            { this.renderBadges() }

                        <div block="ProductCardList" elem="Data">
                            { this.renderMainDetails() }
                            { (window.location.pathname.includes('cart') || showAddToCard) && this.renderAddToCardButton() }
                        </div>

            </li>
        );
    }
}

export default ProductCardList;
