import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { POPUP } from 'Component/Header/Header.config';
import {
    PopupContainer as SourcePopup
} from 'SourceComponent/Popup/Popup.container';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

import Popup from './Popup.component';

/** @namespace Component/Popup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeOverlay: state.OverlayReducer.activeOverlay,
    areOtherOverlaysOpen: state.OverlayReducer.areOtherOverlaysOpen,
    payload: state.PopupReducer.popupPayload,
    device: state.ConfigReducer.device,
    shouldPopupClose: state.PopupReducer.shouldPopupClose,
    navigationState: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState
});

/** @namespace Component/Popup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state)),
    goToPreviousNavigationState: (state) => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE, state))
});

/** @namespace Sofacompany/Component/Popup */
export class PopupContainer extends SourcePopup {
    static propTypes = {
        ...SourcePopup.propTypes,
        small: PropTypes.bool,
        payload: PropTypes.objectOf(
            PropTypes.shape({
                title: PropTypes.oneOfType([
                    PropTypes.object,
                    PropTypes.string
                ])
            })
        ).isRequired
    };

    static defaultProps = {
        ...SourcePopup.defaultProps,
        small: false
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            ...this.state,
            innerContainerClicked: false,
            innerContainerHover: false
        };
    }

    whenDown() {
        this.setState({ innerContainerClicked: true });
    }

    whenUp() {
        this.setState({ innerContainerClicked: false });
    }

    whenEnter() {
        this.setState({ innerContainerHover: true });
    }

    whenLeave() {
        this.setState({ innerContainerHover: false });
    }

    onVisible() {
        const {
            changeHeaderState, activeOverlay, onVisible, navigationState: { name }
        } = this.props;

        const curName = `${POPUP} ${POPUP}_${activeOverlay} Header_name_${name}`;
        changeHeaderState({
            name: curName,
            title: this._getPopupTitle(),
            onCloseClick: () => {
                history.back();
            }
        });

        onVisible();
    }

    containerFunctions = {
        ...this.containerFunctions,
        whenDown: this.whenDown.bind(this),
        whenUp: this.whenUp.bind(this),
        whenEnter: this.whenEnter.bind(this),
        whenLeave: this.whenLeave.bind(this)
    };

    render() {
        return (
            <Popup
              { ...this.props }
              { ...this.state }
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
