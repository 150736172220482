/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-useless-escape */
import browserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase';
import slugify from 'slugify';
import { calcDiscountProcent } from 'src/util/Price/Price';
/** @namespace Sofacompany/Util/Global/minimizeContent */
export const minimizeContent = (content, maxLength) => {
    if (content && content.length > Number(maxLength)) {
        content = `${content.slice(0, Number(maxLength))} ...`;
    }

    return content;
};

export const ONE_MONTH_IN_SECONDS = 2592000;

/** @namespace Sofacompany/Util/Global/camelCase */
export const camelCase = (string) => {
    if (!string) {
        return null;
    }
    const stringArray = string.split(' ').map((x) => x.replace(/^./, (str) => str.toUpperCase()));
    const returnString = stringArray.join(' ');
    return returnString;
};

/** @namespace Sofacompany/Util/Global/doesTranslationExist */
export const doesTranslationExist = (translation) => {
    if (translation) {
        const translationLength = translation.length;
        const translationNameArray = Object.values(translation);
        translationNameArray.splice(translationLength);
        const translationName = translationNameArray.join('');

        // if it taskes default us language dont use, return null
        if (translationName && translationName !== translation.value) {
            return translationName;
        }
    }

    return null;
};

/** @namespace Sofacompany/Util/Global/removeHtml */
export const removeHtml = (content, tag) => {
    if (content) {
        content = content.replace(`<${tag}>`, '').replace(`</${tag}>`, '');
    }

    return content;
};

/** @namespace Sofacompany/Util/Global/tinymceCleaner */
export const tinymceCleaner = (content) => {
    // eslint-disable-next-line fp/no-let
    let contentString = content;

    try {
        if (contentString.indexOf('<p><widget') > -1) {
            contentString = contentString.split('<p><widget').join('<widget');
            contentString = contentString.split('</widget></p>').join('</widget>');
        } else if (contentString.indexOf('<p><div') > -1) {
            contentString = contentString.split('<p><div').join('<div');
            contentString = contentString.split('</div></p>').join('</div>');
        } else if (contentString.indexOf('<p><img') > -1) {
            contentString = contentString.split('<p><img').join('<img');
            contentString = contentString.split('alt=""></p>').join('alt="">');
        } else if (contentString.indexOf('<p><a href=""><img') > -1) {
            contentString = contentString.split('<p><a href=""><img').join('<a href=""><img');
            contentString = contentString.split('alt=""></a></p>').join('alt=""></a>');
        }

        return contentString;
    } catch (e) {
        return contentString;
    }
};

/** @namespace Sofacompany/Util/Global/convertDateFormat */
export function convertDateFormat(
    timestamp,
    date_options = { year: 'numeric', month: 'long', day: 'numeric' }
) {
    const date = new Date(timestamp);
    return date.toLocaleDateString(undefined, date_options);
}

/** @namespace Sofacompany/Util/Global/isCrawler */
export const isCrawler = () => {
    // eslint-disable-next-line max-len
    const botPattern = '(googlebot\/|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis|HeadlessChrome)';
    const re = new RegExp(botPattern, 'i');
    const { userAgent } = navigator;

    if (re.test(userAgent)) {
        return true;
    }

    return false;
};

/** @namespace Sofacompany/Util/Global/highlightSearch */
export const highlightSearch = (name, search) => {
    // eslint-disable-next-line fp/no-let
    let content = name;
    const lowercaseName = name.toLowerCase();

    if (lowercaseName.slice(0, search.length) === search) {
        content = `<strong>${name.slice(0, search.length)}</strong>${name.substring(search.length)}`;
    }

    return content;
};

/** @namespace Sofacompany/Util/Global/scrollTop */
export const scrollTop = () => {
    window.scrollTo(0, 0);
};

/** @namespace Sofacompany/Util/Global/chunkArray */
export const chunkArray = (myArray, chunk_size) => {
    // eslint-disable-next-line fp/no-let
    let index = 0;
    const arrayLength = myArray.length;
    const tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
        const myChunk = myArray.slice(index, index + chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
    }

    return tempArray;
};

/** @namespace Sofacompany/Util/Global/getLocaleFromUrl */
export const getLocaleFromUrl = (localData = 'da-dk') => {
    // eslint-disable-next-line fp/no-let
    let locale = 'da-dk';
    window.storeList.some((item) => {
        if (window.location.pathname.includes(item)) {
            locale = item;
            return true;
        }
    });

    return locale || localData;
};
/** @namespace Sofacompany/Util/Global/arrayObjContains */
export const arrayObjContains = (arrayObj = null, key = null, value = null) => {
    if (!arrayObj || !key || !value) {
        return null;
    }

    return arrayObj?.some((x) => x[key] === value);
};

/** @namespace Sofacompany/Util/Global/truncate */
export const truncate = (string, limit) => (string.length > limit ? `${string.substr(0, limit)}...` : string);

/** @namespace Sofacompany/Util/Global/convertTolocal */
export const convertTolocal = (data) => {
    const modData = data?.split('_')?.join('-')?.toLowerCase();
    return modData;
};

export const toURLSlug = (value) => encodeURIComponent(slugify(value)).replace(/'/g, '').toLowerCase();

export const removeAllSpaces = (x) => x.replaceAll(/\s/g, '');

/** @namespace Sofacompany/Util/Global/telephoneSanitizer */
export const telephoneSanitizer = (data, specialCase) => {
    if (data) {
        const dataArray = Object.values(data);
        const dataKeys = Object.keys(data);
        dataArray.forEach((x, index) => {
            if (x?.telephone) {
                data[dataKeys[index]].telephone = removeAllSpaces(x.telephone);
            }
            if (x !== undefined && x[specialCase]?.telephone) {
                data[dataKeys[index]][specialCase].telephone = removeAllSpaces(x[specialCase].telephone);
            }
        });

        return data;
    }

    return null;
};

export const waitForElm = (selector) => new Promise((resolve) => {
    if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
        }
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true
    });
});

export const lastChar = (word) => word.charAt(word.length - 1);

export const removeLastChar = (word) => word.slice(0, -1);

export const findBetween = (text, toFind) => {
    if (!text || !toFind) {
        return null;
    }
    const f1 = "'[";
    const f2 = "]'";
    const tObj = text.split(f1)[1].split(f2)[0];
    const obj = JSON.parse(tObj);
    return obj?.[toFind];
};

export const renderPriceChange = (option) => {
    const { priceRange, attribute_values } = option;
    if (!priceRange || !attribute_values) {
        return false;
    }

    const converValues = attribute_values[0].split('_').map(Number);
    const minVal = converValues[0];
    const maxVal = converValues[1];
    const { min, max } = priceRange;

    const isChanged = min !== minVal || max !== maxVal;

    if (min === 0 && max === 0) {
        return false;
    }
    if (isChanged) {
        return true;
    }

    return false;
};

export const findBadges = (product, type = 'category') => {
    const { name, categories, price_range: { minimum_price = {} } = {} } = product;

    const badgeCat = ['news', 'prelaunch', 'giftcard', 'award winner', 'limited edition', 'outlet', 'not in design your own'];

    const badges = {};
    if (name) {
        const oldPrice = minimum_price?.regular_price?.value;
        const salePrice = minimum_price?.final_price?.value;

        if (!categories) {
            return {};
        }

        const badgeCategories = [];
        categories.forEach((category) => {
            if (badgeCat.includes(category?.url_key?.replaceAll('-', ' '))) {
                if (lastChar(category?.url_key) === 's') {
                    badgeCategories.push(removeLastChar(category?.url_key).replaceAll('-', ' '));
                } else {
                    badgeCategories.push(category.url_key.replaceAll('-', ' '));
                }
            }
        });

        const discountPct = calcDiscountProcent(oldPrice || 1, salePrice || 1);

        if (badgeCategories.length || discountPct) {
            badges.items = [];

            const font_style = `${type}_font_style`;
            const position = `${type}_position`;
            const position_style = `${type}_position_style`;
            const value = `${type}_value`;

            if (badgeCategories.includes('giftcard')) {
                return badges;
            }

            badgeCategories.forEach((x) => {
                badges.items.push({
                    [font_style]: 'color: #fff;',
                    [position]: 'top-left',
                    [position_style]: 'background: #000;',
                    [value]: x
                });
            });

            if (discountPct) {
                /* badges.items.push(
                    {
                        [font_style]: 'color: #fff;',
                        [position]: 'top-left',
                        [position_style]: 'background-color: #000;',
                        [value]: 'Outlet'
                    }
                ); */
                badges.items.push(
                    {
                        [font_style]: 'color: #fff;',
                        [position]: 'top-left',
                        [position_style]: 'background-color: #000;',
                        [value]: `${discountPct} %`
                    }
                );
            }
        }
    }

    return badges;
};

export const isModule = (item) => {
    const {
        name, breadcrumbs, categories
    } = item;

    const moduleIDs = [77, 405];

    if (name) {
        if (!breadcrumbs && !categories) {
            return false;
        }

        const type = breadcrumbs || categories;
        const module = type.find((x) => moduleIDs.includes(x.id));

        if (module) {
            return true;
        }
    }

    return false;
};

export const isOutdoorModule = (item) => {
    const {
        name, breadcrumbs, categories
    } = item;

    const moduleID = 405;

    if (name) {
        if (!breadcrumbs && !categories) {
            return false;
        }

        const type = breadcrumbs || categories;
        const module = type.find((x) => x.id === moduleID);

        if (module) {
            return true;
        }
    }

    return false;
};

export const submitCookies = (run = () => {}) => {
    if (window.CookieInformation) {
        window.CookieInformation.submitAllCategories();
        run();
    } else {
        setTimeout(() => {
            submitCookies(run);
        }, Number('10'));
    }
};

export const enableShowroom = (run) => {
    browserDatabase.setItem('1', 'sofa_ext_enabled', ONE_MONTH_IN_SECONDS);
    browserDatabase.setItem('1', 'showroom_ext', ONE_MONTH_IN_SECONDS);
    document.cookie = 'allow_invoice=1';
    waitForElm('#CookieConsent').then(() => {
        submitCookies(run);
    });
};

export const formatNumber = (number, local = 'da-dk') => {
    const local_code = local.replace('_', '-');
    const intlNumber = new Intl.NumberFormat(local_code).format(number);

    if (typeof number === 'string' && number.trim().startsWith('+')) {
        return `+${intlNumber}`;
    }

    return intlNumber;
};

export const darkenColor = (color, percent) => {
    const num = parseInt(color.replace('#', ''), 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const B = (num >> 8 & 0x00FF) + amt;
    const G = (num & 0x0000FF) + amt;

    return `#${(0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1)}`;
};

export const getIndexes = (arr, value) => {
    const indexes = arr
        .map((element, index) => {
            const name = typeof element === 'object' ? Object.keys(element)[0] : element;
            return name === value ? index : -1;
        })
        .filter((element) => element !== -1);

    return indexes;
};

export const getCookie = (name) => {
    const cookies = `; ${document.cookie}`;
    const split = cookies.split(`; ${name}=`);
    return split.length > 1 ? split.pop().split(';').shift() : false;
};
