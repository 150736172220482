export const UPDATE_MEILISEARCH = 'UPDATE_MEILISEARCH';
export const UPDATE_MEILISEARCH_CATEGORIES = 'UPDATE_MEILISEARCH_CATEGORIES';
export const UPDATE_MEILISEARCH_PAGES = 'UPDATE_MEILISEARCH_PAGES';
export const UPDATE_MEILISEARCH_LOAD_STATUS = 'UPDATE_MEILISEARCH_LOAD_STATUS';
export const CLEAR_MEILISEARCH_RESULTS = 'CLEAR_MEILISEARCH_RESULTS';
export const UPDATE_MEILISEARCH_HOME = 'UPDATE_MEILISEARCH_HOME';

/** @namespace Sofacompany/Store/MeiliSearch/Action/updateMeiliSearch */
export const updateMeiliSearch = (result) => ({
    type: UPDATE_MEILISEARCH,
    result
});
/** @namespace Sofacompany/Store/MeiliSearch/Action/updateMeiliSearch */
export const updateMeiliSearchCategories = (result) => ({
    type: UPDATE_MEILISEARCH_CATEGORIES,
    result
});
/** @namespace Sofacompany/Store/MeiliSearch/Action/updateMeiliSearch */
export const updateMeiliSearchPages = (result) => ({
    type: UPDATE_MEILISEARCH_PAGES,
    result
});

/** @namespace Sofacompany/Store/MeiliSearch/Action/updateMeiliSearchHome */
export const updateMeiliSearchHome = (result) => ({
    type: UPDATE_MEILISEARCH_HOME,
    result
});

/** @namespace Sofacompany/Store/MeiliSearch/Action/updateLoadStatus */
export const updateLoadStatus = (status) => ({
    type: UPDATE_MEILISEARCH_LOAD_STATUS,
    isLoading: status
});

/** @namespace Sofacompany/Store/MeiliSearch/Action/clearMeiliSearchResults */
export const clearMeiliSearchResults = () => ({
    type: CLEAR_MEILISEARCH_RESULTS
});
