/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */

import Icon from 'Component/Icon';
import Image from 'Component/Image';
import Popup from 'Component/Popup';
import {
    StoreSwitcher as SourceStoreSwitcher
} from 'SourceComponent/StoreSwitcher/StoreSwitcher.component';

import { HIDDEN_STORES, storeCodes, STORESWITCHER_OVERLAY } from './StoreSwitcher.config';

/** @namespace Sofacompany/Component/StoreSwitcher/Component/StoreSwitcher */
export class StoreSwitcher extends SourceStoreSwitcher {
    renderDesktopStoreSwitcher() {
        const {
            storeList,
            onStoreSwitcherClick,
            storeLabel,
            currentStoreCode
        } = this.props;

        // eslint-disable-next-line fp/no-let
        let icon = null;

        try {
            // eslint-disable-next-line import/no-dynamic-require
            icon = require(`../../../public/icons/flags/${currentStoreCode}.png`);
        } catch (m) {
            // eslint-disable-next-line no-console
            console.error(m);
        }

        storeList.sort(this.storeListSortByGroup());

        if (currentStoreCode !== 'default') {
            storeList.sort(this.storeListSort(currentStoreCode));
        }

        return (
            <div block="StoreSwitcher">
                <button
                  block="StoreSwitcher"
                  elem="Title"
                  aria-label={ __('button') }
                  onClick={ onStoreSwitcherClick }
                >
                    { icon && <Image src={ icon.default } ratio="custom" alt={ storeLabel } /> }
                    { storeLabel }
                    <Icon icon="arrow-down" />
                </button>

                <Popup
                  id={ STORESWITCHER_OVERLAY }
                  mix={ { block: 'StoreSwitcher', elem: 'Popup' } }
                  clickOutside={ false }
                >
                    <div block="StoreSwitcher" elem="StoreList">
                        { storeList.filter((store) => !HIDDEN_STORES.includes(store.value)).map(this.renderStoreList) }
                    </div>
                </Popup>
            </div>
        );
    }

    storeListSortByGroup() {
        return (a, b) => {
            // eslint-disable-next-line max-len
            if (!Object.prototype.hasOwnProperty.call(storeCodes, a.value.toLowerCase()) || !Object.prototype.hasOwnProperty.call(storeCodes, b.value.toLowerCase())) {
                return 0;
            }
            const storeA = storeCodes[a.value.toLowerCase()].group;
            const storeB = storeCodes[b.value.toLowerCase()].group;

            if (storeA !== storeB) {
                return -1;
            }

            return 0;
        };
    }

    storeListSort(currentStoreCode) {
        const code = storeCodes[currentStoreCode].group;
        const locale = storeCodes[currentStoreCode].full;

        return (a, b) => {
            // eslint-disable-next-line max-len
            if (!Object.prototype.hasOwnProperty.call(storeCodes, a.value.toLowerCase()) || !Object.prototype.hasOwnProperty.call(storeCodes, b.value.toLowerCase())) {
                return 0;
            }
            const storeA = storeCodes[a.value.toLowerCase()].group;
            const storeB = storeCodes[b.value.toLowerCase()].group;
            const localA = storeCodes[a.value.toLowerCase()].full;
            const localB = storeCodes[b.value.toLowerCase()].full;
            if (localA === locale) {
                return -1;
            }
            if (localB === locale) {
                return 1;
            }
            if (storeA === code) {
                return -1;
            }
            if (storeB === code) {
                return 1;
            }

            return 0;
        };
    }

    renderHeaderSwitcher() {
        const {
            storeList,
            onStoreSwitcherClick,
            storeLabel,
            currentStoreCode
        } = this.props;
        // eslint-disable-next-line fp/no-let
        let icon = null;

        try {
            // eslint-disable-next-line import/no-dynamic-require
            icon = require(`../../../public/icons/flags/${currentStoreCode}.png`);
        } catch (m) {
            // eslint-disable-next-line no-console
            console.error(m);
        }

        return (
            <div block="StoreSwitcher" elem="Header">
                <button
                  block="StoreSwitcher"
                  elem="Title"
                  onClick={ onStoreSwitcherClick }
                >
                    { icon && <Image src={ icon.default } ratio="custom" alt={ storeLabel } /> }
                    <Icon icon="arrow-down" />
                </button>

                <Popup
                  id={ STORESWITCHER_OVERLAY }
                  mix={ { block: 'StoreSwitcher', elem: 'Popup' } }
                  clickOutside={ false }
                >
                    <div block="StoreSwitcher" elem="StoreList">
                        { storeList.filter((store) => !HIDDEN_STORES.includes(store.value)).map(this.renderStoreList) }
                    </div>
                </Popup>
            </div>
        );
    }

    renderMobileStoreItem(storeItem) {
        const { handleStoreSelect } = this.props;
        const { id, label, value } = storeItem;

        return (
            <button
              block="StoreSwitcher"
              elem="Link"
              key={ id }
              onClick={ () => handleStoreSelect(value) }
            >
                { label }
            </button>
        );
    }

    renderMobileStoreSwitcher() {
        const {
            onStoreSwitcherClick,
            isOpened,
            storeList,
            storeLabel,
            currentStoreCode
        } = this.props;

        const mods = { isOpen: isOpened };

        // eslint-disable-next-line fp/no-let
        let icon = null;

        if (currentStoreCode !== 'default') {
            storeList.sort(this.storeListSort(currentStoreCode));
        }

        try {
            // eslint-disable-next-line import/no-dynamic-require
            icon = require(`../../../public/icons/flags/${currentStoreCode}.png`);
        } catch (m) {
            // eslint-disable-next-line no-console
            console.error(m);
        }

        return (
            <div block="StoreSwitcher">
                <button block="StoreSwitcher" elem="Button" onClick={ onStoreSwitcherClick }>
                    { icon && <Image src={ icon.default } ratio="custom" alt={ storeLabel } /> }
                    { storeLabel }
                    <Icon icon="arrow-down" />
                </button>
                <div block="StoreSwitcher" elem="SubMenu" mods={ mods }>
                    { storeList.filter((storeItem) => !HIDDEN_STORES.includes(storeItem.value))
                        .map((storeItem) => this.renderMobileStoreItem(storeItem)) }
                </div>
            </div>
        );
    }

    render() {
        const {
            storeList, device, headerVersion
        } = this.props;

        if (storeList.length <= 1) {
            return null;
        }

        if (headerVersion) {
            return this.renderHeaderSwitcher();
        }

        if (device.isMobile) {
            return this.renderMobileStoreSwitcher();
        }

        return this.renderDesktopStoreSwitcher();
    }
}

export default StoreSwitcher;
