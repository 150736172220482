export const UPDATEPAGE = 'UPDATEPAGE';
export const CLEARPAGE = 'CLEARPAGE';

/** @namespace Sofacompany/Store/CurrentPage/Reducer/getInitialState */
export const getInitialState = () => ({
    pageId: '',
    pageName: '',
    appender: ''
});

/** @namespace Sofacompany/Store/CurrentPage/Reducer/CurrentPageReducer */
export const CurrentPageReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATEPAGE:
        const { appender, pageId, pageName } = action;
        return {
            ...state,
            appender,
            pageId,
            pageName
        };
    case CLEARPAGE:
        return {
            ...state,
            productsInSearch: getInitialState().productsInSearch
        };

    default:
        return state;
    }
};

export default CurrentPageReducer;
