import BrowserDatabase from 'Util/BrowserDatabase';
import { getLocaleFromUrl } from 'Util/Global';

import {
    FETCH_SHOWROOMS,
    FETCH_SHOWROOMS_FAILURE,
    FETCH_SHOWROOMS_SUCCESS, UPDATE_ACTIVE_SHOWROOM
} from './ShowroomSettings.action';

export const _getActiveShowroom = () => {
    const showroomFromLocal = BrowserDatabase.getItem('showroom_ext');
    const local = getLocaleFromUrl().toUpperCase();
    const country_code = showroomFromLocal?.storelocator_country_id;

    if (showroomFromLocal === 'undefined' || !local.includes(country_code)) {
        return null;
    }

    return showroomFromLocal ?? null;
};

export const getInitialState = () => ({
    showrooms: [],
    activeShowroom: _getActiveShowroom(),
    isLoading: false
});

export const ShowroomSettingsReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;
    switch (type) {
    case FETCH_SHOWROOMS:
        return {
            ...state,
            isLoading: true
        };
    case FETCH_SHOWROOMS_FAILURE:
        const { error } = action;
        return {
            ...state,
            isLoading: false,
            error
        };
    case FETCH_SHOWROOMS_SUCCESS:
        const { showrooms } = action;
        return {
            ...state,
            isLoading: false,
            error: null,
            showrooms
        };
    case UPDATE_ACTIVE_SHOWROOM:
        const { activeShowroom } = action;
        return {
            ...state,
            activeShowroom
        };
    default:
        return state;
    }
};

export default ShowroomSettingsReducer;
