export const UPDATE_NOTIFICATION_BAR = 'UPDATE_NOTIFICATION_BAR';
export const UPDATE_VISIBILITY = 'UPDATE_VISIBILITY';

/**
 * Update Promotion Bar information
 * @namespace Sofacompany/Store/PromotionBar/Action/updatePromotionBars */
export const updatePromotionBars = (promotionbars) => ({
    type: UPDATE_NOTIFICATION_BAR,
    promotionbars
});

/** @namespace Sofacompany/Store/PromotionBar/Action/updatePromotionBarVisibility */
export const updatePromotionBarVisibility = (status) => ({
    type: UPDATE_VISIBILITY,
    status
});
