/**
 * GTM scripts
 * @namespace Sofacompany/Component/GoogleTagManager/Scripts/Scripts
 */
export class Scripts {
    static getScript({
        id, dataLayerName
    }) {
        return `(function(w,d,s,l,i){w[l]=w[l]||[];
            w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='${dataLayerName}'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','${dataLayerName}','${id}');`;
    }
}

export default Scripts;
