import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    StoreSwitcherContainer as SourceStoreSwitcherContainer
} from 'SourceComponent/StoreSwitcher/StoreSwitcher.container';
import { showPopup } from 'Store/Popup/Popup.action';
import isMobile from 'Util/Mobile';

import StoreSwitcher from './StoreSwitcher.component';
import { STORESWITCHER_OVERLAY } from './StoreSwitcher.config';

export const StoreListDispatcher = import(
    'Store/StoreList/StoreList.dispatcher'
);

/** @namespace Sofacompany/Component/StoreSwitcher/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showPopup: (payload) => dispatch(showPopup(STORESWITCHER_OVERLAY, payload)),
    getStoreList: () => StoreListDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getStoreList(dispatch)
    )

});

/** @namespace Sofacompany/Component/StoreSwitcher/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    storeList: state.StoreListReducer.stores
});

/** @namespace Sofacompany/Component/StoreSwitcher/Container/StoreSwitcherContainer */
export class StoreSwitcherContainer extends SourceStoreSwitcherContainer {
    componentDidMount() {
        const { getStoreList, storeList, currentStoreCode } = this.props;
        const { storeLabel } = this.state;
        if (!storeList?.length) {
            getStoreList();
        }
        if (!storeLabel) {
            this.getCurrentLabel(currentStoreCode);
        }
    }

    componentDidUpdate(prevProps) {
        const { currentStoreCode, getStoreList, storeList } = this.props;
        const { prevStoreCode } = prevProps;
        const { storeLabel } = this.state;

        if (!storeList?.length) {
            getStoreList();
        }

        if (currentStoreCode && (!storeLabel || (prevStoreCode !== currentStoreCode))) {
            this.getCurrentLabel(currentStoreCode);
        }
    }

    getCurrentLabel(storeCode) {
        const { storeList } = this.props;
        const store = storeList.find(
            ({ value }) => value === storeCode
        );

        if (!store) {
            return;
        }

        const { label } = store;

        this.setState({ storeLabel: label });
    }

    onStoreSwitcherClick() {
        const { showPopup } = this.props;
        const { isOpened } = this.state;

        this.setState({ isOpened: !isOpened });

        if (!isMobile.any()) {
            showPopup({
                title: __('Switch to another store')
            });
        }
    }

    _handleStoreSelect(storeCode) {
        const { showErrorNotification } = this.props;
        const { storeList } = this.props;

        const store = storeList.find(
            ({ value }) => value === storeCode
        );

        if (!store) {
            showErrorNotification(__('This store can not be opened!'));
            return;
        }

        window.location = store.storeLinkUrl;
    }

    render() {
        return (
            <StoreSwitcher
              { ...this.containerFunctions }
              { ...this.containerProps() }
              { ...this.state }
              { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreSwitcherContainer);
