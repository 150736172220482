import CmsPageBlock from 'Component/CmsPageBlock';
import ContentWrapper from 'Component/ContentWrapper';
import {
    NoMatch as SourceNoMatch
} from 'SourceRoute/NoMatch/NoMatch.component';

import { NOMATCH_PAGE_IDENTIFIER } from './NoMatch.config';

/** @namespace Sofacompany/Route/NoMatch/Component/NoMatch */
export class NoMatch extends SourceNoMatch {
    render() {
        return (
            <main block="NoMatch" aria-label={ __('Page not found') }>
                <ContentWrapper
                  mix={ { block: 'NoMatch' } }
                  wrapperMix={ { block: 'NoMatch', elem: 'Wrapper' } }
                  label={ __('Page Not Found Content') }
                >
                    <CmsPageBlock identifier={ NOMATCH_PAGE_IDENTIFIER } />
                </ContentWrapper>
            </main>
        );
    }
}

export default NoMatch;
