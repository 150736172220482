/** @namespace Sofacompany/Util/DyoChecker/inObj */
export const inObj = (newData, oldData) => {
    if (newData) {
        if (!Object.prototype.hasOwnProperty.call(oldData, 'items')) {
            oldData.items = [];
        }
        // eslint-disable-next-line arrow-parens
        const data = oldData.items.find((x) => x.id === newData.id);

        if (data) {
            return oldData;
        }
        oldData.items.push(newData);
        return oldData;
    }

    return oldData;
};

export const convertToDyo = (dyoData, cartTotal) => {
    const dyoUsed = [];
    const { items } = dyoData;
    const itemsPresent = items ? items.length : false;
    const itemsInCart = !!Object.keys(cartTotal).length;

    if (!itemsInCart) {
        const cartDyo = {
            convertedCartTotal: {},
            convertedDyoTotal: {}
        };

        return cartDyo;
    }

    if (itemsPresent) {
        cartTotal.items.forEach((elem) => {
            const elmSku = elem.sku.replaceAll('-', '');
            const data = dyoData.items.find((x) => x.id === elmSku);
            if (data) {
                elem.dyo_data = data;
                const dyoElm = dyoUsed.length ? dyoUsed.find((x) => x.id === elem.id) : false;
                if (!dyoElm) {
                    dyoUsed.push(data);
                }
            }
        });
    }
    dyoData.items = dyoUsed;
    const cartDyo = {
        convertedCartTotal: cartTotal,
        convertedDyoTotal: dyoData
    };

    return cartDyo;
};
