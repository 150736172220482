export const UPDATE_FABRIC_CATEGORY = 'UPDATE_FABRIC_CATEGORY';
export const UPDATE_FABRIC_LOADING = 'UPDATE_FABRIC_LOADING';
export const UPDATE_FABRIC_PRODUCTS = 'UPDATE_FABRIC_PRODUCTS';
export const UPDATE_FABRIC_LOADING_CHECKOUT = 'UPDATE_FABRIC_LOADING_CHECKOUT';
export const UPDATE_FABRIC_SORTS = 'UPDATE_FABRIC_SORTS';
export const UPDATE_FABRIC_FILTERS = 'UPDATE_FABRIC_FILTERS';
export const UPDATE_FABRIC_CHECKOUT_DONE = 'UPDATE_FABRIC_CHECKOUT_DONE';
export const UPDATE_META = 'UPDATE_META';

/** @namespace Sofacompany/Store/FabricSamples/Action/updateCategory */
export const updateCategory = (category) => ({
    type: UPDATE_FABRIC_CATEGORY,
    category
});

/** @namespace Sofacompany/Store/FabricSamples/Action/updateLoader */
export const updateLoader = (isFabricLoading) => ({
    type: UPDATE_FABRIC_LOADING,
    isFabricLoading
});

/** @namespace Sofacompany/Store/FabricSamples/Action/updateProducts */
export const updateProducts = (products) => ({
    type: UPDATE_FABRIC_PRODUCTS,
    products
});

/** @namespace Sofacompany/Store/FabricSamples/Action/updateLoaderCheckout */
export const updateLoaderCheckout = (isFabricCheckoutLoading) => ({
    type: UPDATE_FABRIC_LOADING_CHECKOUT,
    isFabricCheckoutLoading
});

/** @namespace Sofacompany/Store/FabricSamples/Action/updateSorts */
export const updateSorts = (sortFields) => ({
    type: UPDATE_FABRIC_SORTS,
    sortFields
});

/** @namespace Sofacompany/Store/FabricSamples/Action/updateFilters */
export const updateFilters = (filters) => ({
    type: UPDATE_FABRIC_FILTERS,
    filters
});

/** @namespace Sofacompany/Store/FabricSamples/Action/updateCheckoneDone */
export const updateCheckoneDone = (isFabricCheckoutDone) => ({
    type: UPDATE_FABRIC_CHECKOUT_DONE,
    isFabricCheckoutDone
});

/** @namespace Sofacompany/Store/DesignYourOwn/Action/updateMeta */
export const updateMeta = (meta) => ({
    type: UPDATE_META,
    meta
});
