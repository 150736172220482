/* eslint-disable new-cap */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    CartDispatcher,
    ConfigDispatcher,
    mapDispatchToProps as SourceMapDispatchToProps,
    mapStateToProps as SourceMapStateToProps,
    RouterContainer as SourceRouterContainer,
    WishlistDispatcher
} from 'SourceComponent/Router/Router.container';
import BrowserDatabase from 'Util/BrowserDatabase';
import { scrollTop } from 'Util/Global';
import hist from 'Util/History';

import Router from './Router.component';

export {
    CartDispatcher,
    ConfigDispatcher,
    WishlistDispatcher
};

/** @namespace Sofacompany/Component/Router/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state),
    robots: state.MetaReducer.robots,
    is_ready: state.MetaReducer.is_ready,
    currentPageName: state.CurrentPageReducer.pageName
});

/** @namespace Sofacompany/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...SourceMapDispatchToProps(dispatch),
    init: () => {
        ConfigDispatcher.then(
            /** @namespace Component/Router/Container/then */
            ({ default: dispatcher }) => dispatcher.updateConfigData(dispatch)
        );
        CartDispatcher.then(
            /** @namespace Component/Router/Container/then */
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        );
    }
});

/** @namespace Sofacompany/Component/Router/Container/RouterContainer */
export class RouterContainer extends SourceRouterContainer {
    static propTypes = {
        ...SourceRouterContainer.propTypes,
        default_title: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ]),
        default_description: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ]),
        meta_title: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ])
    };

    static defaultProps = {
        ...SourceRouterContainer.defaultProps,
        // eslint-disable-next-line max-len
        default_description: __('All our furniture is handmade in our own factory, and is sent to you without expensive intermediaries. That is why you always get a fixed low price and a 5-year guarantee.'),
        default_title: __('Danish design furniture in handmade quality | SOFACOMPANY')
    };

    state = {
        ...this.state,
        location: hist.location
    };

    componentDidMount() {
        const sofa_ext_enabled = BrowserDatabase.getItem('sofa_ext_enabled');
        if (sofa_ext_enabled) {
            document.cookie = 'allow_invoice=1';
        }

        window.addEventListener('resize', this.handleResize);
        this.listenForInstallPrompt();
    }

    componentDidUpdate(prevProps, prevState) {
        const { isLoading, updateMeta } = this.props;
        const { isLoading: prevIsLoading } = prevProps;
        const { location: prevLocation } = prevState;
        const default_meta = true;

        if (!isLoading && isLoading !== prevIsLoading) {
            const {
                default_description,
                default_keywords,
                default_title,
                title_prefix,
                title_suffix,
                meta_title,
                status_code,
                robots,
                is_ready
            } = this.props;

            updateMeta({
                default_title,
                title: meta_title || default_title,
                default_description,
                description: default_description,
                default_keywords,
                keywords: default_keywords,
                title_prefix,
                title_suffix,
                status_code,
                robots,
                is_ready,
                default_meta
            });
        }

        if (hist?.location?.pathname !== prevLocation?.pathname) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ location: hist.location });
            scrollTop();
        }
    }

    listenForInstallPrompt() {
        window.addEventListener('beforeinstallprompt', (event) => {
            event.preventDefault();
        });
    }

    render() {
        return (
            <Router
              { ...this.containerProps() }
              { ...this.state }
              { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
