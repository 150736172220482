/* eslint-disable import/no-cycle */

import BrowserDatabase from 'Util/BrowserDatabase';
import Event, { EVENT_GTM_PRODUCT_ADD_TO_CART } from 'Util/Event';

import ProductHelper from '../utils';
import BaseEvent from './BaseEvent.event';

export const GROUPED_CART_PRODUCTS = 'GROUPED_CART_PRODUCTS';
export const SPAM_PROTECTION_DELAY = 200;
/**
 * Product add to cart event
 * @namespace Sofacompany/Component/GoogleTagManager/Events/AddToCart/Event/AddToCartEvent
 */
export class AddToCartEvent extends BaseEvent {
    /**
     * Bind add to cart
     */
    bindEvent() {
        Event.observer(EVENT_GTM_PRODUCT_ADD_TO_CART, ({
            product,
            quantity,
            configurableVariantIndex,
            massAddAction = false,
            isFromCart = false,
            isGrouped = false,
            isItem = false,
            addType = false
        }) => {
            const productData = product instanceof Array ? product : { configurableVariantIndex, ...product };
            this.handle(
                productData,
                quantity || 1,
                isItem,
                isGrouped,
                isFromCart,
                massAddAction,
                addType
            );
        });
    }

    /**
     * Handle product add to cart
     */
    handler(product, quantity, isItem, isGrouped, isFromCart, massAddAction, addType) {
        if (!massAddAction && this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const products = [];

        if (isGrouped) {
            const { items, quantities, attributes: parentAttributes } = product;
            // eslint-disable-next-line fp/no-let
            let groupedProductPrice = 0;

            items.forEach(
                ({ product }) => {
                    const { id, attributes } = product;
                    const attributesToPass = attributes.reduce((acc, attribute) => {
                        const { attribute_code } = attribute;
                        return {
                            ...acc,
                            [attribute_code]: {
                                ...parentAttributes[attribute_code],
                                ...attribute
                            }
                        };
                    }, {});

                    const productToPush = {
                        ...ProductHelper.getProductData({
                            // pass attributes to every children
                            ...product,
                            attributes: attributesToPass
                        }, true),
                        quantity: quantities[id],
                        availability: true
                    };

                    const { price, quantity } = productToPush;
                    groupedProductPrice += price * quantity;

                    products.push(productToPush);
                }
            );

            const groupedProductData = {
                ...ProductHelper.getProductData({ ...product, groupedProductPrice }),
                quantity: 0
            };

            ProductHelper.addGroupedProduct(groupedProductData, product, groupedProductPrice);
            products.push({
                ...groupedProductData,
                availability: true
            });
        } else if (product instanceof Array) {
            // eslint-disable-next-line fp/no-let
            for (let i = 0; i < product.length; i++) {
                products.push(this.addProductEvent(product[i], isItem, quantity, isFromCart));
            }
        } else {
            products.push(this.addProductEvent(product, products, isItem, quantity, isFromCart));
        }

        const productCategories = products.reduce((acc, current) => {
            const { id, category } = current;
            return {
                ...acc,
                [id]: category
            };
        }, {});

        const productVariants = products.reduce((acc, current) => {
            const { id, variant } = current;
            return {
                ...acc,
                [id]: variant
            };
        }, {});

        const existingProductCategories = BrowserDatabase.getItem('product_categories') || {};
        BrowserDatabase.setItem({ ...existingProductCategories, ...productCategories }, 'product_categories');

        const existingProductVariants = BrowserDatabase.getItem('product_variants') || {};
        BrowserDatabase.setItem({ ...existingProductVariants, ...productVariants }, 'product_variants');

        const eventData = {
            ecommerce: {
                currencyCode: this.getCurrencyCode(),
                add: {
                    products
                },
                cart: this.prepareCartData()
            }
        };

        if (addType) {
            eventData.ecommerce.addType = addType;
        }

        this.pushEventData(eventData);
    }

    addProductEvent(product, isItem, quantity, isFromCart) {
        const { type_id } = product;
        const productData = ProductHelper.getProductData(product, type_id === 'configurable');

        if (isFromCart) {
            const { id, price } = productData;
            if (!quantity) {
                quantity = 1;
            }
            ProductHelper.updateGroupedProduct(id, price * quantity);
        }

        return {
            ...productData,
            quantity,
            availability: true
        };
    }
}

export default AddToCartEvent;
