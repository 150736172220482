import AdyenPosCloudReducer from 'Store/AdyenPosCloud/AdyenPosCloud.reducer';
import BreadcrumbsReducer from 'Store/Breadcrumbs/Breadcrumbs.reducer';
import CartReducer from 'Store/Cart/Cart.reducer';
import CategoriesReducer from 'Store/Categories/Categories.reducer';
import CategoryReducer from 'Store/Category/Category.reducer';
import CheckoutReducer from 'Store/Checkout/Checkout.reducer';
import ConfigReducer from 'Store/Config/Config.reducer';
import ContentBlockReducer from 'Store/ContentBlock/ContentBlock.reducer';
import ContentSpotReducer from 'Store/ContentSpot/ContentSpot.reducer';
import CurrentCategoryReducer from 'Store/CurrentCategory/CurrentCategory.reducer';
import CurrentCheckoutInfoReducer from 'Store/CurrentCheckoutInfo/CurrentCheckoutInfo.reducer';
import CurrentCityReducer from 'Store/CurrentCity/CurrentCity.reducer';
import CurrentPageReducer from 'Store/CurrentPage/CurrentPage.reducer';
import DeliverFromShowroomReducer from 'Store/DeliverFromShowroom/DeliverFromShowroom.reducer';
import DesignYourOwnReducer from 'Store/DesignYourOwn/DesignYourOwn.reducer';
import FabricSamplesReducer from 'Store/FabricSamples/FabricSamples.reducer';
import FabricScrolledReducer from 'Store/FabricScrolled/FabricScrolled.reducer';
import LinkedProductsReducer from 'Store/LinkedProducts/LinkedProducts.reducer';
import MeiliSearchReducer from 'Store/MeiliSearch/MeiliSearch.reducer';
import MetaReducer from 'Store/Meta/Meta.reducer';
import MyAccountReducer from 'Store/MyAccount/MyAccount.reducer';
import NavigationReducer from 'Store/Navigation/Navigation.reducer';
import NewsletterReducer from 'Store/Newsletter/Newsletter.reducer';
import NoMatchReducer from 'Store/NoMatch/NoMatch.reducer';
import NotificationReducer from 'Store/Notification/Notification.reducer';
import OfflineReducer from 'Store/Offline/Offline.reducer';
import OrderReducer from 'Store/Order/Order.reducer';
import OverlayReducer from 'Store/Overlay/Overlay.reducer';
import PopupReducer from 'Store/Popup/Popup.reducer';
import PostsReducer from 'Store/Posts/Posts.reducer';
import PostsDetailsReducer from 'Store/PostsDetails/PostsDetails.reducer';
import PostsSearchReducer from 'Store/PostsSearch/PostsSearch.reducer';
import PowerStepReducer from 'Store/PowerStep/PowerStep.reducer';
import ProductReducer from 'Store/Product/Product.reducer';
import ProductListReducer from 'Store/ProductList/ProductList.reducer';
import ProductListInfoReducer from 'Store/ProductListInfo/ProductListInfo.reducer';
import PromotionBarReducer from 'Store/PromotionBar/PromotionBar.reducer';
import SearchBarReducer from 'Store/SearchBar/SearchBar.reducer';
import ShowroomSettingsReducer from 'Store/ShowroomSettings/ShowroomSettings.reducer';
import ShowroomStockReducer from 'Store/ShowroomStock/ShowroomStock.reducer';
import StoreFinderReducer from 'Store/StoreFinder/StoreFinder.reducer';
import StoreListReducer from 'Store/StoreList/StoreList.reducer';
import StudioImageReducer from 'Store/StudioImage/StudioImage.reducer';
import UrlRewritesReducer from 'Store/UrlRewrites/UrlRewrites.reducer';
import WishlistReducer from 'Store/Wishlist/Wishlist.reducer';

/** @namespace Sofacompany/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    CurrentCategoryReducer,
    CurrentPageReducer,
    ContentSpotReducer,
    CurrentCityReducer,
    CurrentCheckoutInfoReducer,
    StoreListReducer,
    PowerStepReducer,
    CategoryReducer,
    NotificationReducer,
    BreadcrumbsReducer,
    ProductReducer,
    ProductListReducer,
    ProductListInfoReducer,
    CartReducer,
    ContentBlockReducer,
    OrderReducer,
    WishlistReducer,
    NoMatchReducer,
    SearchBarReducer,
    MyAccountReducer,
    NavigationReducer,
    OverlayReducer,
    OfflineReducer,
    PopupReducer,
    UrlRewritesReducer,
    ConfigReducer,
    MetaReducer,
    LinkedProductsReducer,
    CheckoutReducer,
    NewsletterReducer,
    PromotionBarReducer,
    CategoriesReducer,
    PostsDetailsReducer,
    PostsReducer,
    PostsSearchReducer,
    StoreFinderReducer,
    DesignYourOwnReducer,
    MeiliSearchReducer,
    FabricSamplesReducer,
    ShowroomSettingsReducer,
    AdyenPosCloudReducer,
    DeliverFromShowroomReducer,
    FabricScrolledReducer,
    StudioImageReducer,
    ShowroomStockReducer
});

export default function injectStaticReducers(store) {
    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => store.injectReducer(name, reducer)
    );

    return store;
}
