import { Field } from 'Util/Query';

export class Contents {
    getQuery(title) {
        return new Field('contents')
            .addArgument(
                'filters',
                'ContentFilterInput',
                {
                    title: { eq: title }
                }
            )
            .addField('status')
            .addField(new Field('custom_attributes')
                .addField('identifier')
                .addField(new Field('repeater_field_contents')
                    .addField(new Field('custom_attributes')
                        .addField('identifier')
                        .addField('value'))));
    }
}

export default new Contents();
