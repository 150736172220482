import PropTypes from 'prop-types';

import CmsPageQuery from 'Query/CmsPage.query';
import { showNotification } from 'Store/Notification/Notification.action';
import DataContainer from 'Util/Request/DataContainer';

import CmsPageBlock from './CmsPageBlock.component';

/** @namespace Sofacompany/Component/CmsPageBlock/Container/CmsPageBlockContainer */
export class CmsPageBlockContainer extends DataContainer {
    static propTypes = {
        identifier: PropTypes.string,
        id: PropTypes.number
    };

    static defaultProps = {
        id: null,
        identifier: ''
    };

    state = {
        isLoading: false,
        page: {}
    };

    componentDidMount() {
        this.requestPage();
    }

    requestPage() {
        const { identifier, id } = this.props;

        this.setState({
            isLoading: true
        });

        const request = id ? { id } : { identifier };

        this.fetchData(
            [CmsPageQuery.getQuery(request)],
            (result) => {
                this.setState({
                    page: result.cmsPage,
                    isLoading: false
                });
            },
            (error) => {
                this.setState({
                    isLoading: false,
                    page: { content: null }
                });

                showNotification('error', error[0].message);
            }
        );
    }

    render() {
        return (
            <CmsPageBlock
              { ...this.state }
            />
        );
    }
}

export default CmsPageBlockContainer;
