import { connect } from 'react-redux';

import { routesArray } from 'Component/Router/Router.component';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    NoMatchDispatcher,
    UrlRewritesContainer as SourceUrlRewritesContainer,
    UrlRewritesDispatcher
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';
import { updateUrlRewrite } from 'Store/UrlRewrites/UrlRewrites.action';
import { getLocaleFromUrl } from 'Util/Global';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import UrlRewrites from './UrlRewrites.component';

export {
    UrlRewritesDispatcher,
    NoMatchDispatcher,
    mapStateToProps
};

/** @namespace Sofacompany/Route/UrlRewrites/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    clearUrlRewrites: () => dispatch(updateUrlRewrite({}, ''))
});

/** @namespace Sofacompany/Route/UrlRewrites/Container/UrlRewritesContainer */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    __construct(props) {
        super.__construct(props);
        const { pathname } = window.location;

        // eslint-disable-next-line fp/no-let
        let index = null;

        /*
            Go threw all routesArray and figure out if current pathname is in one of them.
            if yes then set index of the index key of the value.
        */
        Object.values(routesArray).forEach((routes) => {
            const values = Object.values(routes);
            values.forEach((value, i) => {
                if (value === pathname.split('/')[2]) {
                    index = i;
                }
            });
        });

        /*
            if there is a index and the value in right routesArray currentLocale value isn't the same as current pathname in url
            then run this if statement.
        */
        if (index !== null && Object.entries(routesArray[getLocaleFromUrl()])[index][1] !== pathname.split('/')[2]) {
            /*
                get the key from routesArray (we just using the 'en-us' for just taking one of them its dosn't matter witch of them we choose)
                Then get the new pathname of the current locale routesArray out from key.
            */

            const key = Object.keys(routesArray['en-us'])[index];
            const newPathname = `${routesArray[getLocaleFromUrl()][key]}`;

            history.push(appendWithStoreCode(`/${newPathname}`));
        }
    }

    componentWillUnmount() {
        const { clearUrlRewrites } = this.props;
        clearUrlRewrites();
    }

    render() {
        return (
            <UrlRewrites
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
