import BrowserDatabase from 'Util/BrowserDatabase';

import { UPDATE_CONFIG, UPDATE_CONFIG_CHAT, UPDATE_CONFIG_DEVICE } from './Config.action';

export const MAX_WIDTH = 150;
export const MAX_HEIGHT = 40;
export const DEFAULT_CATGORY_URL_SUFFIX = '.html';

/** @namespace Sofacompany/Store/Config/Reducer/filterStoreConfig */
export const filterStoreConfig = (config) => Object.entries(config).reduce(
    (acc, [key, value]) => (value !== null ? { ...acc, [key]: value } : acc),
    {}
);

/** @namespace Sofacompany/Store/Config/Reducer/mappedMaterials */
export const mappedMaterials = (materials) => {
    if (materials === undefined) {
        return materials;
    }
    const mappedMaterial = {};
    materials.forEach((material) => {
        if (mappedMaterial[material.type] === undefined) {
            mappedMaterial[material.type] = {};
        }
        mappedMaterial[material.type][material.name] = material;
    });

    return mappedMaterial;
};

export const {
    countries, reviewRatings, storeConfig, cartDisplayConfig, materials, restrictedZipCodes
} = BrowserDatabase.getItem('config') || {
    countries: [],
    reviewRatings: [],
    storeConfig: {},
    currencyData: {},
    cartDisplayConfig: {
        display_tax_in_price: '',
        display_tax_in_subtotal: '',
        display_tax_in_shipping_amount: '',
        include_tax_in_order_total: false,
        display_full_tax_summary: false,
        display_zero_tax_subtotal: false
    },
    materials: [],
    restrictedZipCodes: []
};

/** @namespace Store/Config/Reducer/getIndexedRatings */
export const getIndexedRatings = (reviewRatings) => ((reviewRatings) ? reviewRatings.items || [] : []);

/** @namespace Store/Config/Reducer/getCurrencyData */
export const getCurrencyData = (base, state) => (base || state.currencyData || {});

/** @namespace Store/Config/Reducer/getCountryData */
export const getCountryData = (base, state) => (base || state.countries || {});

/** @namespace Store/Config/Reducer/getCheckoutAgreementData */
export const getCheckoutAgreementData = (base, state) => (base || state.checkoutAgreements || {});

/** @namespace Store/Config/Reducer/getMaterials */
export const getMaterials = (base, state) => (base || state.materials || {});

/** @namespace Store/Config/Reducer/getRestrictedZipCodesMethod */
export const getRestrictedZipCodesMethod = (base, state) => (base || state.restrictedZipCodes || {});

/** @namespace Sofacompany/Store/Config/Reducer/getInitialState */
export const getInitialState = () => ({
    ...filterStoreConfig(storeConfig),
    materials: {},
    restrictedZipCodes: [],
    countries,
    reviewRatings,
    checkoutAgreements: [],
    isLoading: true,
    cartDisplayConfig,
    priceTaxDisplay: {},
    gtm: BrowserDatabase.getItem('config') || { gtm: {} },
    config: BrowserDatabase.getItem('config') || { config: {} },
    storeNumber: BrowserDatabase.getItem('config') || { },
    newsletter_general_active: false,
    category_url_suffix: DEFAULT_CATGORY_URL_SUFFIX,
    wishlist_general_active: false,
    layered_navigation_product_count_enabled: false,
    chatIsOnline: false,
    currencyData: {
        current_currency_code: 'DKK'
    },
    device: {
        isMobile: true,
        android: true,
        ios: false,
        blackberry: false,
        opera: false,
        windows: false,
        standaloneMode: window.matchMedia('(display-mode: standalone)').matches
    }
});

/** @namespace Sofacompany/Store/Config/Reducer/ConfigReducer */
export const ConfigReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        config: {
            countries,
            materials,
            getRestrictedZipCodes,
            reviewRatings,
            checkoutAgreements,
            currencyData,
            storeConfig = {},
            cartDisplayConfig = {},
            gtm = {},
            storeNumber = {},
            chatIsOnline
        } = {},
        device
    } = action;

    switch (type) {
    case UPDATE_CONFIG:
        const filteredStoreConfig = filterStoreConfig(storeConfig);
        const { secure_base_media_url } = filteredStoreConfig;
        window.secure_base_media_url = secure_base_media_url;

        const mappedMaterialsData = mappedMaterials(materials);

        return {
            ...state,
            countries: getCountryData(countries, state),
            materials: getMaterials(mappedMaterialsData, state),
            restrictedZipCodes: getRestrictedZipCodesMethod(getRestrictedZipCodes, state),
            reviewRatings: getIndexedRatings(reviewRatings),
            checkoutAgreements: getCheckoutAgreementData(checkoutAgreements, state),
            currencyData: getCurrencyData(currencyData, state),
            gtm,
            ...filteredStoreConfig,
            // Should be updated manually as filteredStoreConfig does not contain header_logo_src when it is null
            // and header_logo_src takes old value
            isLoading: false,
            storeNumber,
            cartDisplayConfig,
            chatIsOnline
        };

    case UPDATE_CONFIG_CHAT:

        return {
            ...state,
            chatIsOnline
        };
    case UPDATE_CONFIG_DEVICE:
        return {
            ...state,
            device: {
                ...state.device,
                ...device
            }
        };

    default:
        return state;
    }
};

export default ConfigReducer;
