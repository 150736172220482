import { Link as RouterLink } from 'react-router-dom';
import { stringify } from 'rebem-classname';

import {
    Link as SourceLink
} from 'SourceComponent/Link/Link.component';

/** @namespace Sofacompany/Component/Link/Component/Link */
export class Link extends SourceLink {
    render() {
        const {
            className,
            bemProps,
            children,
            to,
            isDisabled,
            blank,
            // eslint-disable-next-line no-unused-vars
            isOpenInNewTab,
            ...props
        } = this.props;

        // eslint-disable-next-line fp/no-let
        let link = typeof to === 'object' ? to.pathname : to;

        if (!link || isDisabled) {
            return (
                <div { ...props }>
                    { children }
                </div>
            );
        }

        if (/^#/.test(link)) {
            return (
                // eslint-disable-next-line
                <a
                  { ...props }
                  onClick={ this.scrollToElement }
                  href={ link }
                  target={ blank ? '_blank' : '' }
                  className={ `${className } ${ stringify(bemProps)}` }
                >
                    { children }
                </a>
            );
        }

        if (link.endsWith('/')) {
            link = link.slice(0, -1);
        }

        if (/^https?:\/\//.test(link)) {
            return (
                // eslint-disable-next-line
                <a
                  { ...props }
                  href={ link }
                  target={ blank ? '_blank' : '' }
                  className={ `${className } ${ stringify(bemProps)}` }
                >
                    { children }
                </a>
            );
        }

        return (
            <RouterLink
              { ...props }
              to={ link }
              // eslint-disable-next-line react/forbid-component-props
              className={ `${className } ${ stringify(bemProps)}` }
            >
                { children }
            </RouterLink>
        );
    }
}

export default Link;
