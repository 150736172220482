export const FETCH_CONNECTED_TERMINALS = 'FETCH_CONNECTED_TERMINALS';
export const FETCH_CONNECTED_TERMINALS_FAILURE = 'FETCH_CONNECTED_TERMINALS_FAILURE';
export const FETCH_CONNECTED_TERMINALS_SUCCESS = 'FETCH_CONNECTED_TERMINALS_SUCCESS';

export const fetchConnectedTerminals = () => ({
    type: FETCH_CONNECTED_TERMINALS,
    loading: true
});

export const fetchConnectedTerminalsFailure = (error) => ({
    type: FETCH_CONNECTED_TERMINALS_FAILURE,
    error
});
export const fetchConnectedTerminalsSuccess = (terminals) => ({
    type: FETCH_CONNECTED_TERMINALS_SUCCESS,
    terminals
});
