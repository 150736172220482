import {
    CROSS_SELL,
    RELATED,
    UPSELL
} from 'SourceStore/LinkedProducts/LinkedProducts.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';

import { UPDATE_LINKED_PRODUCTS } from './LinkedProducts.action';
import { LINKED_PRODUCTS } from './LinkedProducts.dispatcher';

export {
    UPSELL,
    RELATED,
    CROSS_SELL
};

export const SIMILAR = 'similar';
export const SHOP_THE_LOOK = 'shop_the_look';

/** @namespace Sofacompany/Store/LinkedProducts/Reducer/getInitialState */
export const getInitialState = () => ({
    linkedProducts: BrowserDatabase.getItem(LINKED_PRODUCTS) || {
        similar: {},
        related: {},
        upsell: {},
        crosssell: {},
        shop_the_look: {}
    }
});

/** @namespace Sofacompany/Store/LinkedProducts/Reducer/LinkedProductsReducer */
export const LinkedProductsReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type
    } = action;

    if (type !== UPDATE_LINKED_PRODUCTS) {
        return state;
    }

    const {
        linkedProducts: {
            [RELATED]: related,
            [SIMILAR]: similar,
            [UPSELL]: upsell,
            [CROSS_SELL]: crosssell,
            [SHOP_THE_LOOK]: shop_the_look,
            updateCrosssel = false
        }
    } = action;

    if (updateCrosssel) {
        return {
            ...state,
            linkedProducts: {
                ...state.linkedProducts,
                [RELATED]: related,
                [SIMILAR]: similar,
                [UPSELL]: upsell,
                [CROSS_SELL]: crosssell,
                [SHOP_THE_LOOK]: shop_the_look
            }
        };
    }

    if (related) {
        return {
            ...state,
            linkedProducts: {
                ...state.linkedProducts,
                [RELATED]: related
            }
        };
    }

    if (similar) {
        return {
            ...state,
            linkedProducts: {
                ...state.linkedProducts,
                [SIMILAR]: similar
            }
        };
    }

    if (upsell) {
        return {
            ...state,
            linkedProducts: {
                ...state.linkedProducts,
                [UPSELL]: upsell
            }
        };
    }

    if (crosssell) {
        return {
            ...state,
            linkedProducts: {
                ...state.linkedProducts,
                [CROSS_SELL]: crosssell
            }
        };
    }

    if (shop_the_look) {
        return {
            ...state,
            linkedProducts: {
                ...state.linkedProducts,
                [SHOP_THE_LOOK]: shop_the_look
            }
        };
    }

    return {
        ...state,
        linkedProducts: {
            ...state.linkedProducts,
            [RELATED]: related,
            [SIMILAR]: similar,
            [UPSELL]: upsell,
            [CROSS_SELL]: crosssell,
            [SHOP_THE_LOOK]: shop_the_look
        }
    };
};

export default LinkedProductsReducer;
