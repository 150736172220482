import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ProductBadges.style';

/** @namespace Sofacompany/Component/ProductBadges/Component/ProductBadges */
export class ProductBadges extends PureComponent {
    static propTypes = {
        badges: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array
        ]),
        type: PropTypes.string.isRequired
    };

    static defaultProps = {
        badges: []
    };

    renderBadge(badge) {
        const { type } = this.props;

        const {
            [`${type}_font_style`]: font_style,
            [`${type}_position_style`]: position_style,
            [`${type}_value`]: value
        } = badge;

        const styles = attributesToProps({ style: `${font_style} ${position_style}` });

        return (
            <div
              block="ProductBadges"
              elem="Badge"
              key={ value }
              { ...styles }
            >
                <div block="ProductBadges" elem="BadgeContent">
                    <span block="ProductBadges" elem="BadgeLabel">{ value }</span>
                </div>
            </div>
        );
    }

    renderGroup(group, items) {
        return (
            <div
              block="ProductBadges"
              elem="BadgeGroup"
              key={ JSON.stringify(items) }
              mods={ { position: group } }
            >
                { items.map((item) => this.renderBadge(item)) }
            </div>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    groupBadges() {
        const { badges: { items } = {}, type } = this.props;

        const groupedBadges = {};

        items.forEach((item) => {
            const { [`${type}_position`]: badgeType } = item;
            const key = groupedBadges[badgeType];

            if (key) {
                groupedBadges[badgeType].push(item);
            } else {
                groupedBadges[badgeType] = [item];
            }
        });

        return groupedBadges;
    }

    render() {
        const { badges: { items } = {} } = this.props;

        if (!items) {
            return null;
        }

        const groupedBadges = this.groupBadges();

        return Object.entries(groupedBadges).map(([group, items]) => this.renderGroup(group, items));
    }
}

export default ProductBadges;
