/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import parser, { domToReact } from 'html-react-parser';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import { lazy } from 'react';

import {
    Html as SourceHtml
} from 'SourceComponent/Html/Html.component';
import { tinymceCleaner } from 'Util/Global';

import './Html.style';

export const FabricSampleBlock = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "fabric-samples" */ 'Component/FabricSampleBlock'));

/** @namespace Sofacompany/Component/Html/Component/Html */
export class Html extends SourceHtml {
    replaceTrustpilotScript = this.replaceTrustpilotScript.bind(this);

    replaceBodyScript = this.replaceBodyScript.bind(this);

    rules = [
        {
            query: { attribs: [{ id: 'CookiePolicy' }] },
            replace: this.replaceBodyScript
        },
        ...this.rules,
        {
            query: { attribs: [{ className: ['fabric-sample-block'] }] },
            replace: this.replaceFabricSample
        },
        {
            query: { attribs: [{ className: ['trustpilot-widget'] }] },
            replace: this.replaceTrustpilotScript
        }
    ];

    parserOptions = {
        replace: (domNode) => {
            const { data, name: domName, attribs: domAttrs } = domNode;

            if (data && data.includes('pagebreak')) {
                return <br block="RenderWhenVisible" elem="LineBreak" />;
            }

            // Let's remove empty text nodes
            if (data && !data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                // eslint-disable-next-line react/jsx-no-useless-fragment
                return <></>;
            }
            const rule = this.rules.find((rule) => {
                const { query: { name, attribs } } = rule;

                if (name && domName && name.indexOf(domName) !== -1) {
                    return true;
                } if (attribs && domAttrs) {
                    // eslint-disable-next-line fp/no-loops, fp/no-let
                    for (let i = 0; i < attribs.length; i++) {
                        const attrib = attribs[i];

                        if (typeof attrib === 'object') {
                            const queryAttrib = Object.keys(attrib)[0];
                            // add className if only class is supplied
                            if (Object.prototype.hasOwnProperty.call(domAttrs, 'class')) {
                                domAttrs.className = domAttrs.class;
                                delete domAttrs.class;
                            }
                            if (Object.prototype.hasOwnProperty.call(domAttrs, queryAttrib)) {
                                return domAttrs[queryAttrib].match(Object.values(attrib)[0]);
                            }
                        } else if (Object.prototype.hasOwnProperty.call(domAttrs, attrib)) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (rule) {
                const { replace } = rule;
                return replace.call(this, domNode);
            }
        }
    };

    replaceImages({ attribs }) {
        const attributes = attributesToProps(attribs);

        if (attribs.src) {
            return (
                <img loading="lazy" { ...attributes } />
            );
        }
    }

    replaceFabricSample() {
        return <FabricSampleBlock />;
    }

    replaceTrustpilotScript({ attribs, children }) {
        const { trustpilotRef } = this.props;

        return (
            <div
              ref={ trustpilotRef }
              { ...attribs }
            >
                { domToReact(children) }
            </div>
        );
    }

    replaceBodyScript(elem) {
        const { scriptContainerRef } = this.props;
        const { attribs, children } = elem;

        const script = document.createElement('script');

        Object.entries(attribs).forEach(([attr, value]) => script.setAttribute(attr, value));

        if (children && children[0]) {
            script.appendChild(document.createTextNode(children[0].data));
        }

        if (scriptContainerRef && scriptContainerRef.current) {
            scriptContainerRef.current.appendChild(script);
        }

        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    render() {
        const { content = '' } = this.props;

        try {
            return parser(tinymceCleaner(content), this.parserOptions);
        } catch (m) {
            return <p>{ __('There interupted a error in the widget you trying to add') }</p>;
        }
    }
}

export default Html;
