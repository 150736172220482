/* eslint-disable max-len */
import CmsBlock from 'Component/CmsBlock';
import ExtraMenu from 'Component/ExtraMenu';
import Html from 'Component/Html';
import Icon from 'Component/Icon';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Newsletter from 'Component/Newsletter';
import OfflineNotice from 'Component/OfflineNotice';
import Popup from 'Component/Popup';
import ProductLinks from 'Component/ProductLinks';
import ProductPrice from 'Component/ProductPrice';
import SocialFooterItems from 'Component/SocialFooterItems';
import StoreSwitcher from 'Component/StoreSwitcher';
import TrustpilotBlock from 'Component/TrustpilotBlock';
import {
    Footer as SourceFooter
} from 'SourceComponent/Footer/Footer.component';
import {
    RELATED
} from 'Store/LinkedProducts/LinkedProducts.reducer';
import { getSortedItems } from 'Util/Menu';
import isMobile from 'Util/Mobile';

import {
    BOTTOM_MENU,
    FOOTER_CARD_BLOCK,
    FOOTER_SOCIAL_BLOCK,
    NEWS,
    PAYMENT_ICONS,
    POWERSTEP_OVERLAY
} from './Footer.config';

/** @namespace Sofacompany/Component/Footer/Component/Footer */
export class Footer extends SourceFooter {
    static defaultProps = {
        ...SourceFooter.defaultProps,
        isVisibleOnMobile: true
    };

    renderMenuItem(item) {
        const {
            url: { pathname },
            url,
            item_id,
            title
        } = item;

        const linkPath = (pathname !== '' ? pathname : '#') || (url !== '' ? url : '#');

        return (
            <li block="Footer" elem="Menu-Item" key={ item_id }>
                <Link
                  block="Footer"
                  elem="Menu-Link"
                  to={ linkPath }
                >
                    { title }
                </Link>
            </li>
        );
    }

    renderCookie() {
        const { openCookieConsent } = this.props;

        return (

            <div block="Cookie" onClick={ openCookieConsent }>
                { __('Open Cookie Consent') }
            </div>

        );
    }

    renderMenu(menu, index) {
        const { children, title, id } = menu;
        const childrenArray = getSortedItems(Object.values(children));

        return (
            <div block="Footer" elem="Menu" key={ `${id}-${index}` }>
                <label block="Footer" elem="Menu-Title" htmlFor={ `footer-menu-${title}` }>
                    { title }
                </label>
                <input type="checkbox" id={ `footer-menu-${title}` } />
                <Icon icon="arrow-down-line" />
                <ul
                  block="Footer"
                  elem="Menu-ItemList"
                  aria-label={ title }
                >
                    { childrenArray.map((child) => this.renderMenuItem(child)) }
                </ul>
            </div>
        );
    }

    renderSocial() {
        return (
            <div block="Footer" elem="Social">
                <p block="Footer" elem="Social-Title">
                    { __('Follow us') }
                </p>
                <SocialFooterItems />
            </div>
        );
    }

    renderContent() {
        // const { footer_content: { footer_cms } = {} } = window.contentConfiguration;
        const { menu } = this.props;
        const categoryArray = Object.values(menu);

        if (categoryArray.length < Number('3')) {
            // eslint-disable-next-line fp/no-let
            for (let index = categoryArray.length; index < Number('3'); index++) {
                categoryArray.push({
                    children: {},
                    icon: null,
                    is_active: true,
                    item_class: '',
                    item_id: '',
                    parent_id: 0,
                    position: 1,
                    title: '',
                    url: ''
                });
            }
        }

        return (
            <>
                { isMobile.any() && this.renderSocial() }
                <div block="Footer" elem="Menu-List">
                    { categoryArray.length > 0 && categoryArray.map((menu, index) => this.renderMenu(menu, index)) }
                    <div block="Footer" elem="Social">
                        <p block="Footer" elem="Social-Title">
                            { __('Follow us') }
                        </p>
                        <SocialFooterItems />
                        <CmsBlock identifier={ FOOTER_SOCIAL_BLOCK } />
                        <p block="Footer" elem="Payment-Title">
                            { __('Payment') }
                        </p>
                         <CmsBlock identifier={ PAYMENT_ICONS } />
                    </div>
                </div>
                { !isMobile.any() && (
                    <div block="Footer" elem="Bottom">
                        <div block="Footer" elem="Bottom-Container">
                                <StoreSwitcher />
                                { this.renderBottomMenu() }
                        </div>
                    </div>
                ) }
                { isMobile.any() && (
                     <div block="Footer" elem="Cards">
                        <CmsBlock identifier={ FOOTER_CARD_BLOCK } />
                     </div>
                ) }

            </>
        );
    }

    renderBottomMenu() {
        if (isMobile.any()) {
            return null;
        }

        return (
            <ExtraMenu menu={ BOTTOM_MENU } extra={ this.renderCookie() } />
        );
    }

    renderPowerStep() {
        const { powerStepProduct, hidePopup, powerStepDYOToS } = this.props;

        if (!powerStepProduct || !Object.keys(powerStepProduct).length) {
            return null;
        }

        const {
            thumbnail,
            dyoData,
            sofa_original_name,
            name,
            priceValidUntil,
            productPrice,
            related_products,
            isGiftCard,
            showStockNotification,
            stock_item
        } = powerStepProduct;

        const relatedProducts = !!related_products?.length;
        const image = dyoData?.image ?? thumbnail?.url;

        return (
            <Popup
              id={ POWERSTEP_OVERLAY }
              mix={ { block: 'PowerStep', elem: 'Popup' } }
              mods={ { relatedProducts } }
              small
              clickOutside
            >
            <div block="Footer" elem="PowerStep" mods={ { relatedProducts } }>
                <div className="wrapper">
                    <div block="Footer" elem="PowerStep-Product">
                        <div block="Footer" elem="PowerStep-Image">
                            <Image src={ image } ratio="custom" />
                        </div>
                        <div block="Footer" elem="PowerStep-Content">
                        { isGiftCard ? (
                            <p block="Footer" elem="PowerStep-Name">
                                { sofa_original_name }
                            </p>
                        ) : (
                            <>
                                <div block="Footer" elem="PowerStep-PriceNameWrapper">
                                    <p block="Footer" elem="PowerStep-Name">
                                        { name }
                                    </p>
                                    <ProductPrice
                                      isSchemaRequired
                                      price={ productPrice }
                                      priceValidUntil={ priceValidUntil }
                                      mix={ { block: 'ProductActions', elem: 'Price' } }
                                      stock_item={ stock_item }
                                      displayQtyIncrementText={ false }
                                      isPowerstep
                                    />
                                </div>
                                <p block="Footer" elem="PowerStep-Name2">
                                    { sofa_original_name.split(', ')?.slice(1)?.join(', ') ?? sofa_original_name }
                                </p>
                            </>
                        ) }

                        </div>

                    </div>

                    { !dyoData && showStockNotification && (
                        <div block="Footer" elem="PowerStep-Stock-Warning">
                            <div className="Note">
                                <Icon icon="warning" />
                            </div>
                            <div className="Title">{ __('Please note longer delivery time, as we have not ordered quantity in stock.') }</div>
                        </div>

                    ) }

                    { dyoData && (
                        <div block="Footer" elem="PowerStep-tos">
                            <div className="Title">{ __('Special terms and conditions') }</div>
                            <Html content={ powerStepDYOToS } />
                        </div>

                    ) }

                    <Link
                      block="Footer"
                      elem="PowerStep-Button"
                      mix={ { block: 'Button', mods: { isHollow: false } } }
                      to="/cart"
                      onClick={ hidePopup }
                    >
                        { __('View cart') }
                    </Link>

                    <div
                      block="Footer"
                      elem="PowerStep-Link"
                      onClick={ () => {
                          hidePopup();
                      } }
                    >
                        { __('Continue shopping') }
                    </div>
                </div>

                { relatedProducts && (
                    <ProductLinks
                      product={ powerStepProduct }
                      linkType={ RELATED }
                      title={ __('Frequently bought together') }
                      areDetailsLoaded
                    />
                ) }

            </div>
            </Popup>
        );
    }

    render() {
        const {
            isVisibleOnMobile, isLoading, navigationState: { name }, currentCategory: { id }
        } = this.props;
        const newsPage = id === NEWS;
        if (isLoading || !isVisibleOnMobile) {
            return null;
        }

        return (
            <>
                { newsPage && (
                    <>
                        <Newsletter />
                        <TrustpilotBlock />
                    </>
                ) }
                { !newsPage && (
                    <>
                        <TrustpilotBlock />
                        <Newsletter />
                    </>
                ) }
                <footer block="Footer" aria-label={ __('Footer') } mods={ { name } }>
                    { this.renderContent() }
                </footer>

                <OfflineNotice />
                { this.renderPowerStep() }
            </>
        );
    }
}

export default Footer;
