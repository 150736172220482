/* eslint-disable no-useless-escape */
/* eslint-disable max-len */

import {
    UPDATE_CATEGORIES,
    UPDATE_DEFAULT_PRODUCT,
    UPDATE_DEFAULT_PRODUCTS,
    UPDATE_LOADER,
    UPDATE_META,
    UPDATE_PRODUCTS,
    UPDATE_SELECTED_CATEGORY,
    UPDATE_SELECTED_PRODUCT, UPDATE_STUDIO_IMAGES
} from './DesignYourOwn.action';

/** @namespace Sofacompany/Store/DesignYourOwn/Reducer/getInitialState */
export const getInitialState = () => ({
    categories: [],
    products: [],
    selectedCategory: {},
    selectedProduct: {},
    defaultProducts: [],
    isLoading: false,
    meta: {}
});

/** @namespace Sofacompany/Store/DesignYourOwn/Reducer/DesignYourOwnReducer */
export const DesignYourOwnReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_CATEGORIES:
        const { categories } = action;

        return {
            ...state,
            categories
        };
    case UPDATE_PRODUCTS:
        const { products } = action;

        return {
            ...state,
            products
        };

    case UPDATE_DEFAULT_PRODUCTS:
        const { defaultProducts } = action;

        return {
            ...state,
            defaultProducts
        };

    case UPDATE_DEFAULT_PRODUCT:
        const { defaultProduct } = action;

        return {
            ...state,
            defaultProduct
        };

    case UPDATE_SELECTED_CATEGORY:
        const { selectedCategory } = action;

        return {
            ...state,
            selectedCategory
        };

    case UPDATE_SELECTED_PRODUCT:
        const { selectedProduct } = action;

        return {
            ...state,
            selectedProduct
        };

    case UPDATE_STUDIO_IMAGES:
        const { studioImages, studioImagesSku } = action;

        return {
            ...state,
            studioImages,
            studioImagesSku
        };
    case UPDATE_LOADER:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    case UPDATE_META:
        const { meta } = action;

        return {
            ...state,
            meta
        };

    default:
        return state;
    }
};

export default DesignYourOwnReducer;
