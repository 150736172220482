import BrowserDatabase from 'Util/BrowserDatabase';
import { convertToDyo, inObj } from 'Util/DyoChecker/checker';
import { getLocaleFromUrl } from 'Util/Global';
import { getIndexedProduct } from 'Util/Product';

import { UPDATE_TOTALS } from './Cart.action';

export const CART_TOTALS = `cart_totals_${getLocaleFromUrl()}`;
export const DYO_ARRAY = `dyo_array_${getLocaleFromUrl()}`;

/** @namespace Sofacompany/Store/Cart/Reducer/getInitialState */
export const getInitialState = () => ({
    cartTotals: BrowserDatabase.getItem(CART_TOTALS) || {}
});

/** @namespace Sofacompany/Store/Cart/Reducer/updateCartTotals */
export const updateCartTotals = (action) => {
    const {
        cartData: cartTotals,
        cartData: {
            dyoData
        }
    } = action;

    if (Object.hasOwnProperty.call(cartTotals, 'items')) {
        const normalizedItemsProduct = cartTotals.items.map((item) => {
            const normalizedItem = item;
            normalizedItem.product = getIndexedProduct(item.product);

            return normalizedItem;
        });

        cartTotals.items = normalizedItemsProduct;
    }

    const curData = BrowserDatabase.getItem(DYO_ARRAY) || {};
    const modDyoData = inObj(dyoData, curData);
    const combinedCart = convertToDyo(modDyoData, cartTotals);

    BrowserDatabase.setItem(
        combinedCart.convertedDyoTotal,
        DYO_ARRAY
    );

    BrowserDatabase.setItem(
        combinedCart.convertedCartTotal,
        CART_TOTALS
    );

    return { cartTotals };
};

/** @namespace Sofacompany/Store/Cart/Reducer/CartReducer */
export const CartReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_TOTALS:
        return updateCartTotals(action, state);

    default:
        return state;
    }
};

export default CartReducer;
