import { Field } from 'Util/Query';

/** @namespace Sofacompany/Query/StoreProduct/Query/MaterialImage */
export class MaterialImage {
    getMaterialImage(type, name) {
        return new Field('GetMaterialImage')
            .setAlias('material')
            .addArgument('type', 'String', type)
            .addArgument('name', 'String', name)
            .addField('material_image');
    }

    getMaterialImages() {
        return new Field('GetMaterialImages')
            .setAlias('materials')
            .addField('cylindo_code')
            .addField('cylindo_name')
            .addField('material_image')
            .addField('name')
            .addField('type');
    }
}

export default new (MaterialImage)();
