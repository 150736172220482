/* eslint-disable import/no-cycle */

import Event, {
    EVENT_GTM_404,
    EVENT_GTM_GENERAL_INIT,
    EVENT_GTM_JOB_SEEKERS,
    EVENT_GTM_META_UPDATE
} from 'Util/Event';
import { isEventEnabled } from 'Util/EventConfig';

import { DATA_LAYER_NAME } from '../GoogleTagManager.component';
import BaseEvent from './BaseEvent.event';

export const GENERAL_EVENT_DELAY = 500;

/**
 * GTM PWA General event
 *
 * On: page load, page change location
 * @namespace Sofacompany/Component/GoogleTagManager/Events/General/Event/General
 */
export class General extends BaseEvent {
    /**
     * If already loading data, do not do second request
     *
     * @type {boolean}
     */
    isLoadingData = false;

    /**
     * Set base event call delay
     *
     * @type {number}
     */
    eventHandleDelay = 0;

    /**
     * Current meta data
     *
     * @type {{}}
     */
    currentMeta = { title: '' };

    currentDataLayerName = DATA_LAYER_NAME;

    /**
     * Bind PWA event handling
     */
    bindEvent() {
        // Page load, wait a bit for better user performance
        setTimeout(() => {
            this.saveCartDataToStorage();
        }, GENERAL_EVENT_DELAY);

        // eslint-disable-next-line fp/no-let
        let isFirstPageLoad = true;

        // eslint-disable-next-line prefer-destructuring
        const history = this.getGTM().props.history;

        // eslint-disable-next-line fp/no-let
        let prevLocation = history.location;

        history.listen((location) => { // On page change
            const { pathname } = location;
            const { pathname: prevPathname } = prevLocation;

            // prevents from firing general on filter change (PLP) and on attribute change (PDP)
            if (pathname === prevPathname || history.action === 'REPLACE') {
                return;
            }

            if (isFirstPageLoad) {
                isFirstPageLoad = false;
            }

            this.saveCartDataToStorage();
            prevLocation = location;
        });

        // Receive current meta
        Event.observer(EVENT_GTM_META_UPDATE, (meta) => {
            this.currentMeta = meta;

            if (isFirstPageLoad) {
                isFirstPageLoad = false;
                return;
            }

            this.handle(window.location.pathname);
        });
    }

    saveCartDataToStorage() {
        const storage = this.getStorage() || {};
        storage.cartLast = storage.cart;
        storage.cart = this.getAppState().CartReducer.cartTotals.items;
        this.setStorage(storage);
    }

    handle(pathname) {
        this.handler(pathname);
    }

    /**
     * Handler General
     */
    handler(pathname) {
        Event.dispatch(EVENT_GTM_GENERAL_INIT, {});

        if (!isEventEnabled(EVENT_GTM_GENERAL_INIT)) {
            return;
        }

        if (this.getMetaTitle() === __('Page not found').toLowerCase()) {
            Event.dispatch(EVENT_GTM_404);

            return;
        }
        if (this.getMetaTitle() === __('jobsekers').toLowerCase()) {
            Event.dispatch(EVENT_GTM_JOB_SEEKERS);

            return;
        }

        if (this.getMetaTitle() && this.getMetaTitle() !== '') {
            this.pushEventData({
                pageType: this.getPageType(),
                virtualPageTitle: this.getMetaTitle(),
                virtualPagePath: pathname
            });
        }
    }

    /**
     * Get current store view
     *
     * @return {string}
     */
    getStoreView() {
        return this.getAppState().ConfigReducer.code || '';
    }

    getMetaTitle() {
        const { title = '' } = this.currentMeta;

        // eslint-disable-next-line max-len
        return title.toLowerCase();
    }

    /**
     * @param {*} item
     */
    getQuantity({ qty }) {
        return qty;
    }

    /**
     * Get current language
     *
     * @return {string}
     */
    getLanguage() {
        return this.getAppState().ConfigReducer.locale || '';
    }

    /**
     * Get current signed in customer id
     *
     * @return {string}
     */
    getCustomerId() {
        return this.isSignedIn() ? this.getAppState().MyAccountReducer.customer.id || '' : '';
    }

    /**
     * Get customer city
     *
     * @return {string}
     */
    getCountryName() {
        return this.getAppState().ConfigReducer.default_country || '';
    }
}

export default General;
