import { getLocaleFromUrl } from 'Util/Global';

export * from 'SourceRoute/Checkout/Checkout.config';

export const PAYEVER_STEP = 'PAYEVER_STEP';
export const ADYEN_STEP = 'ADYEN_STEP';
export const PAYMENTS_STEP = 'PAYMENTS_STEP';
export const FAIL_STEP = 'FAIL_STEP';
export const SEND_STEP = 'SEND_STEP';
export const CART_TOTALS = `cart_totals_${getLocaleFromUrl()}`;

export const CheckoutRoutes = [];
CheckoutRoutes['en-us'] = {
    sofas: 'sofas',
    furniture: 'furniture',
    news: 'news',
    designyourownsofa: 'design-your-own-sofa'
};
CheckoutRoutes['da-dk'] = {
    sofas: 'sofaer',
    furniture: 'mobler',
    news: 'news',
    designyourownsofa: 'design-din-egen-sofa'
};
CheckoutRoutes['sv-se'] = {
    sofas: 'soffa',
    furniture: 'stolar-och-bord',
    news: 'news',
    designyourownsofa: 'designa-sjalv'
};
CheckoutRoutes['nb-no'] = {
    sofas: 'design-sofa',
    furniture: 'bord-og-stoler',
    news: 'news',
    designyourownsofa: 'bygg-din-egen-sofa'
};
CheckoutRoutes['nl-nl'] = {
    sofas: 'designbank',
    furniture: 'tafel-en-stoelen',
    news: 'news',
    designyourownsofa: 'zelf-ontwerpen'
};
CheckoutRoutes['nl-be'] = {
    sofas: 'design-zetel',
    furniture: 'tafel-en-stoelen',
    news: 'news',
    designyourownsofa: 'zelf-ontwerpen'
};
CheckoutRoutes['de-ch'] = {
    sofas: 'sofas',
    furniture: 'tische-und-stuhle',
    news: 'news',
    designyourownsofa: 'sofa-konfigurator'
};
CheckoutRoutes['de-de'] = {
    sofas: 'design-sofa',
    furniture: 'tische-und-stuhle',
    news: 'news',
    designyourownsofa: 'sofa-konfigurator'
};
CheckoutRoutes['de-at'] = {
    sofas: 'design-sofa',
    furniture: 'tische-stuhle',
    news: 'news',
    designyourownsofa: 'sofa-konfigurator'
};
CheckoutRoutes['fr-be'] = {
    sofas: 'sofas',
    furniture: 'furniture',
    news: 'news',
    designyourownsofa: 'design-your-own-sofa'
};
CheckoutRoutes['fr-ch'] = {
    sofas: 'sofas',
    furniture: 'furniture',
    news: 'news',
    designyourownsofa: 'design-your-own-sofa'
};

export const goTo = (siteToGo, locale) => CheckoutRoutes[locale][siteToGo];
