import Icon from 'Component/Icon';
import {
    AddToCart as SourceAddToCart
} from 'SourceComponent/AddToCart/AddToCart.component';

/** @namespace Sofacompany/Component/AddToCart/Component/AddToCart */
export class AddToCart extends SourceAddToCart {
    render() {
        const {
            beforeElem,
            mix,
            product: { type_id, stock_item: { enable_qty_increments, qty_increments } = {} },
            isLoading,
            isDisabled,
            icon,
            buttonClick,
            isWhite
        } = this.props;

        if (!type_id) {
            this.renderPlaceholder();
        }

        const white = isWhite ? 'Button_white' : '';

        return (
            <button
              block="AddToCart"
              className={ `Button ${ white }` }
              onClick={ buttonClick }
              mix={ mix }
              mods={ { isLoading, white: isWhite, beforeElem: !!beforeElem } }
              disabled={ isDisabled || isLoading }
            >
                { beforeElem && (
                    <div className="beforeElemContainer">
                    { beforeElem() }
                    </div>
                ) }
                { !beforeElem && !isWhite && icon && <Icon icon="plus" /> }
                <span>{ enable_qty_increments ? __('Add %s to basket', qty_increments) : __('Add to cart') }</span>
                <span>{ __('Adding...') }</span>
            </button>
        );
    }
}

export default AddToCart;
