/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable import/no-cycle */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ABTestEvent from 'Component/GoogleTagManager/events/ABTest.event';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';
import Event, { EVENT_GTM_USER_IP } from 'Util/Event';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import AddToCartEvent from './events/AddToCart.event';
import BlogEvent from './events/Blog.event';
import CheckoutEvent from './events/Checkout.event';
import CheckoutEmailEvent from './events/CheckoutEmail.event';
import CheckoutOptionEvent from './events/CheckoutOption.event';
import FeaturedCatClickEvent from './events/FeaturedCatClick.event';
import General from './events/General.event';
import Impression from './events/Impression.event';
import JobSeekersEvent from './events/JobSeekers.event';
import KlevuSearchEvent from './events/KlevuSearch.event';
import NotFoundEvent from './events/NotFound.event';
import PLPFilterClickEvent from './events/PLPFilterClick.event';
import PopupEvent from './events/Popup.event';
import ProductClickEvent from './events/ProductClick.event';
import ProductDetailEvent from './events/ProductDetail.event';
import PurchaseEvent from './events/Purchase.event';
import RemoveFromCartEvent from './events/RemoveFromCart.event';
import SliderClickEvent from './events/SliderClick.event';
import UserIPEvent from './events/UserIP.event';
import UserLoginEvent from './events/UserLogin.event';
import UserRegisterEvent from './events/UserRegister.event';
import Scripts from './Scripts';

/**
 * Event list
 */
export const EVENT_GENERAL = 'virtualPageview';
export const EVENT_IMPRESSION = 'productImpression';
export const EVENT_PRODUCT_CLICK = 'productClick';
export const EVENT_ADD_TO_CART = 'addToCart';
export const EVENT_REMOVE_FROM_CART = 'removeFromCart';
export const EVENT_PRODUCT_DETAIL = 'productDetail';
export const EVENT_PURCHASE = 'purchase';
export const EVENT_CHECKOUT = 'checkout';
export const EVENT_CHECKOUT_OPTION = 'checkoutOption';
export const EVENT_CHECKOUT_CHECKOUTEMAIL = 'checkoutEmail';
export const EVENT_USER_REGISTER = 'userRegister';
export const EVENT_USER_LOGIN = 'userLogin';
export const EVENT_NOT_FOUND = 'notFound';
export const EVENT_CATEGORY_FILTERS = 'categoryFilters';
export const EVENT_ADDITIONAL = 'additional';
export const EVENT_KLEVU = 'search';
export const EVENT_BLOG = 'blog';
export const EVENT_USERIP = 'ipEvent';
export const EVENT_404 = '404';
export const EVENT_JOB_SEEKERS = 'jobSeekers';
export const EVENT_AB_TEST = 'abTest';
export const EVENT_POPUP = 'popup';
export const EVENT_SlIDER_CLICK = 'sliderClick';
export const EVENT_PLP_FILTER_CLICK = 'plpFilterClick';
export const EVENT_FEATURED_CAT_CLICK = 'featuredCategoryClick';

/**
 * Const
 */
export const DATA_LAYER_NAME = 'dataLayer';
export const DATA_LAYER_STORAGE = 'GTM_dataLayer';
export const GROUPED_PRODUCTS_PREFIX = 'GROUPED_PRODUCTS_';
export const GROUPED_PRODUCTS_GUEST = `${ GROUPED_PRODUCTS_PREFIX }GUEST`;
export const GTM_INJECTION_TIMEOUT = 500;

/**
 * Google tag manager wrapper
 * This should have 1 instance to avoid multiple initializations
 * @namespace Sofacompany/Component/GoogleTagManager/Component/GoogleTagManager
 */
export class GoogleTagManager extends PureComponent {
    static propTypes = {
        gtm: PropTypes.shape()
    };

    static defaultProps = {
        gtm: {
            enabled: false,
            gtm_id: ''
        }
    };

    /**
     * Event list used in GTM
     * All used events should be registered in this data mapping
     *
     * @type {{[p: string]: General|Purchase|CheckoutEvent|OrderData|Impression|AddToCartEvent|ProductClickEvent|ProductDetail|CheckoutOptionEvent|RemoveFromCartEvent}}
     */
    static eventList = {
        [EVENT_GENERAL]: General,
        [EVENT_PURCHASE]: PurchaseEvent,
        [EVENT_CHECKOUT]: CheckoutEvent,
        [EVENT_CHECKOUT_OPTION]: CheckoutOptionEvent,
        [EVENT_CHECKOUT_CHECKOUTEMAIL]: CheckoutEmailEvent,
        [EVENT_IMPRESSION]: Impression,
        [EVENT_ADD_TO_CART]: AddToCartEvent,
        [EVENT_PRODUCT_CLICK]: ProductClickEvent,
        [EVENT_PRODUCT_DETAIL]: ProductDetailEvent,
        [EVENT_REMOVE_FROM_CART]: RemoveFromCartEvent,
        [EVENT_USER_REGISTER]: UserRegisterEvent,
        [EVENT_USER_LOGIN]: UserLoginEvent,
        [EVENT_USERIP]: UserIPEvent,
        [EVENT_KLEVU]: KlevuSearchEvent,
        [EVENT_BLOG]: BlogEvent,
        [EVENT_404]: NotFoundEvent,
        [EVENT_JOB_SEEKERS]: JobSeekersEvent,
        [EVENT_AB_TEST]: ABTestEvent,
        [EVENT_POPUP]: PopupEvent,
        [EVENT_SlIDER_CLICK]: SliderClickEvent,
        [EVENT_PLP_FILTER_CLICK]: PLPFilterClickEvent,
        [EVENT_FEATURED_CAT_CLICK]: FeaturedCatClickEvent
    };

    /**
     * GoogleTagManager instance
     *
     * @type {GoogleTagManager}
     */
    static instance = null;

    /**
     * Push data to GTM
     *
     * @param event
     * @param data
     */
    static pushData(event, data) {
        if (this.getInstance()) {
            this.getInstance().processDataPush(event, data);
        }
    }

    /**
     * Append Data Layer with new data
     *
     * @param data
     */
    static appendData(data) {
        if (this.getInstance()) {
            this.getInstance().addDataLayer(data);
        }
    }

    /**
     * Get event by name
     *
     * @param name
     * @return {null|BaseEvent}
     */
    static getEvent(name) {
        if (this.getInstance()) {
            return this.getInstance().getEvent(name);
        }

        return null;
    }

    /**
     * Get GoogleTagManager Instance
     *
     * @return {GoogleTagManager}
     */
    static getInstance() {
        return this.instance;
    }

    /**
     * Is GoogleTagManager enabled
     *
     * @type {boolean}
     */
    enabled = false;

    /**
     * Prepared Data Layer
     *
     * @type {{}}
     */
    currentDataLayer = {};

    /**
     * Data layer name
     *
     * @type {string}
     */
    currentDataLayerName = DATA_LAYER_NAME;

    /**
     * groupedProducts
     */
    groupedProductsStorageName = GROUPED_PRODUCTS_GUEST;

    /**
     * Event data object
     *
     * @type {{}}
     */
    events = {};

    /**
     * Data storage for event data
     *
     * @type {{}}
     */
    eventDataStorage = {};

    /**
     * Grouped product storage
     */
    groupedProducts = {};

    /**
     * Did mount
     */
    componentDidMount() {
        this.initialize();
    }

    /**
     * If props is updated
     */
    componentDidUpdate() {
        this.initialize();
    }

    /**
     * Unregister component
     */
    componentWillUnmount() {
        this.destruct();
    }

    /**
     * Get event by name
     *
     * @param name
     * @return {null|*}
     */
    getEvent(name) {
        if (Object.keys(this.events).indexOf(name) >= 0) {
            return this.events[name];
        }

        return null;
    }

    /**
     * Set event storage
     *
     * @param event
     * @param data
     */
    setEventStorage(event, data) {
        this.eventDataStorage[event] = data;
    }

    /**
     * Set grouped products to storage
     *
     * @param groupedProducts
     */
    setGroupedProducts(groupedProducts) {
        BrowserDatabase.setItem(groupedProducts, this.groupedProductsStorageName, ONE_MONTH_IN_SECONDS);
        this.groupedProducts = groupedProducts;
    }

    /**
     * Get reference to grouped products
     */
    getGroupedProducts() {
        return this.groupedProducts;
    }

    /**
     * Get reference to the storage
     *
     * @param event
     * @return {*}
     */
    getEventDataStorage(event) {
        if (typeof this.eventDataStorage[event] === 'undefined') {
            this.resetEventDataStorage(event);
        }

        return this.eventDataStorage[event];
    }

    /**
     * Reset storage data
     *
     * @param event
     */
    resetEventDataStorage(event) {
        this.eventDataStorage[event] = {};
    }

    updateGroupedProducts() {
        this.groupedProducts = BrowserDatabase.getItem(this.groupedProductsStorageName) || {};
    }

    updateGroupedProductsStorageName(name) {
        this.groupedProductsStorageName = name
            ? `${ GROUPED_PRODUCTS_PREFIX }${ name }`
            : GROUPED_PRODUCTS_GUEST;

        this.updateGroupedProducts();
    }

    /**
     * Register GTM event handlers
     */
    registerEvents() {
        this.events = Object.entries(GoogleTagManager.eventList).reduce((acc, [name, Event]) => {
            acc[name] = new Event(name, this);
            acc[name].bindEvent();

            return acc;
        }, {});
    }

    geoIPEvent() {
        const interval = setInterval(() => {
            if (window[this.currentDataLayerName].length > 0) {
                clearInterval(interval);

                try {
                    fetch('https://api.ipify.org?format=json')
                        .then(
                        /** @namespace Sofacompany/Component/GoogleTagManager/Component/fetch/then */
                            (response) => response.json()
                        )
                        .then(
                            /** @namespace Sofacompany/Component/GoogleTagManager/Component/fetch/then/then */
                            (data) => {
                                if (data && data.ip) {
                                    Event.dispatch(
                                        EVENT_GTM_USER_IP,
                                        data.ip
                                    );
                                }
                            }
                        );
                } catch (e) {
                    return null;
                }
            }
        }, Number('500'));
    }

    /**
     * Send event and data to the GoogleTagManager
     *
     * @param event
     * @param data
     */
    processDataPush(event, data) {
        if (this.enabled) {
            this.addDataLayer(data);

            if (this.debug) {
                // eslint-disable-next-line no-console
                console.error(event, data);
            }

            window[this.currentDataLayerName].push({
                event,
                ...this.currentDataLayer
            });

            this.currentDataLayer = {};
        } else {
            // eslint-disable-next-line fp/no-let
            let events = BrowserDatabase.getItem(DATA_LAYER_STORAGE);
            if (events == null || !events.isArray()) {
                events = [];
            }
            events.push({ eventName: event, eventData: data });
            BrowserDatabase.setItem(events, DATA_LAYER_STORAGE, Number('3600'));
        }
    }

    /**
     * Unregister/ destruct all parts related to the gtm
     */
    destruct() {
        Object.values(this.events).forEach((event, name) => {
            event.destruct();

            delete this.events[name];
        });

        this.events = {};
    }

    /**
     * Append current DataLayer with new nata
     *
     * @param data
     */
    addDataLayer(data) {
        if (this.enabled) {
            this.currentDataLayer = { ...this.currentDataLayer, ...data };
        }
    }

    /**
     * Initialize GTM
     */
    initialize() {
        const { gtm: { enabled } } = this.props;

        if (this.enabled || !enabled || GoogleTagManager.getInstance()) {
            return;
        }

        this.enabled = true;
        GoogleTagManager.instance = this;

        this.initGroupedProducts();
        this.injectGTMScripts();
        this.registerEvents();
        this.geoIPEvent();
        const events = BrowserDatabase.getItem(DATA_LAYER_STORAGE);
        if (events !== null && events.isArray()) {
            events.forEach((event) => {
                self.processDataPush(event.eventName, event.eventData);
            });
            BrowserDatabase.deleteItem(DATA_LAYER_STORAGE);
        }
    }

    /**
     * Insert GTM scripts to the document
     */
    injectGTMScripts() {
        const { gtm: { gtm_id: id } } = this.props;

        const script = document.createElement('script');
        script.innerHTML = Scripts.getScript(
            { id, dataLayerName: this.currentDataLayerName }
        );
        document.head.insertBefore(script, document.head.childNodes[2]);
        window[this.currentDataLayerName] = window[this.currentDataLayerName] || [];
    }

    /**
     * Initialize grouped products
     */
    initGroupedProducts() {
        const customer = BrowserDatabase.getItem(CUSTOMER);
        this.updateGroupedProductsStorageName(customer && customer.id);
        this.groupedProducts = BrowserDatabase.getItem(this.groupedProductsStorageName) || {};
    }

    /**
     * Skip render
     *
     * @return {null}
     */
    render() {
        return null;
    }
}

export default GoogleTagManager;
