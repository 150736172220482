export * from 'SourceComponent/ProductCard/ProductCard.config';

export const PRODUCTCARD_SHORTDESCRIPTION_MAXLENGTH = '55';
export const PRODUCTCARD_NAME_MAXLENGTH = '16';
export const PRODUCTCARD_MAX_AVAILABLEVISUALOPTIONS = '4';
export const PRODUCTCARD_MAX_AVAILABLEPICTURES = '3';
export const PRODUCTCARD_WIDTH = '180';
export const FABRIC_CODE_IMAGE = 'fabric_code_image';
export const FABRIC_IMAGE = 'fabric_image';
export const NEWS = 'news';
export const IMAGE_SIZE = '?width=350&height=350';
export const IMAGE_SIZE_XS = '?width=250&height=250';
export const SWATCH_IMAGE_SIZE = '?width=18&height=18';
