export const UPDATECATEGORY = 'UPDATECATEGORY';
export const CLEARCATEGORY = 'CLEARCATEGORY';

export const updateCurrentCategory = (id = '', identifier = '') => ({
    type: UPDATECATEGORY,
    id,
    identifier
});

export const clearCurrentCategory = (page) => ({
    type: CLEARCATEGORY,
    page
});
