import {
    UPDATE_POSTS_SEARCH,
    UPDATE_POSTS_SEARCH_LOAD_STATUS
} from './PostsSearch.action';

export const initialState = {
    postsInSearch: [],
    isLoading: true
};

/** @namespace Sofacompany/Store/PostsSearch/Reducer/PostsSearchReducer */
export const PostsSearchReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_POSTS_SEARCH:
        const { items } = action;

        return {
            ...state,
            postsInSearch: items
        };

    case UPDATE_POSTS_SEARCH_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default PostsSearchReducer;
