import { EVENT_IMPRESSION } from 'Component/GoogleTagManager/GoogleTagManager.component';
import BrowserDatabase from 'Util/BrowserDatabase';

/**
 * Map GTM event names to config event names
 * @param name
 * @returns {string}
 * @namespace Sofacompany/Util/EventConfig/Index/mapGtmEventNames
 */
export const mapGtmEventNames = (name) => {
    switch (name) {
    case name.includes('impressions'):
        return EVENT_IMPRESSION;
    default:
        return name;
    }
};

// overrule grqphql list
export const allowList = [
    'gtm_meta_update',
    'gtm_404',
    'gtm_user_ip',
    'gtm_klevu_search',
    'gtm_job_seekers',
    'gtm_ab_test',
    'gtm_popup',
    'gtm_plp_filter_click',
    'gtm_slider_click',
    'gtm_featured_cat_click',
    'gtm_slider_click'
];

/**
 * Check if push event is enabled in config
 * @param eventName
 * @returns {boolean}
 * @namespace Sofacompany/Util/EventConfig/Index/isEventEnabled
 */
export const isEventEnabled = (eventName) => {
    const {
        gtm: {
            events = {}
        } = {}
    } = BrowserDatabase.getItem('config') || {};

    if (allowList.includes(eventName)) {
        return true;
    }

    return !!events[mapGtmEventNames(eventName)];
};
