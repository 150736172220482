/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';

import {
    ProductPrice as SourceProductPrice
} from 'SourceComponent/ProductPrice/ProductPrice.component';
import { formatPrice, roundPrice } from 'Util/Price';

/** @namespace Sofacompany/Component/ProductPrice/Component/ProductPrice */
export class ProductPrice extends SourceProductPrice {
    static propTypes = {
        ...SourceProductPrice.propTypes,
        isGiftCard: PropTypes.bool
    };

    renderCurrentPrice() {
        const {
            discountPercentage,
            formattedFinalPrice,
            formattedFinalCleanPrice,
            currencyIcon,
            formattedCleanPriceIcon,
            stock_item: { enable_qty_increments } = {},
            displayQtyIncrementText = true,
            isPowerstep = false
        } = this.props;

        const priceSchema = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        // should we use clean number and should number de displayed before currency
        const cleanNumber = true;
        const numberBCurrency = false;
        const finalPrice = cleanNumber ? formattedFinalCleanPrice ?? '0' : formattedFinalPrice ?? '0';
        const finalPriceWithIcon = formattedCleanPriceIcon;
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';
        // const formated = formattedFinalPrice.split(/\b(\s)/);
        const formated = finalPrice.split(/\b(\s)/);
        const regex = /\d/;
        const containsNumber = regex.test(formated[0]);

        if (currencyIcon) {
            return (
                <PriceSemanticElementName
                  block="ProductPrice"
                  elem="InnerWrapper"
                  mods={ { isVisible: true } }
                >
                    <span { ...priceSchema }>
                        { finalPriceWithIcon }
                    </span>
                    { enable_qty_increments && displayQtyIncrementText && (
                        <span
                          block="ProductPrice"
                          elem="Each"
                        >
                            { ` ${ __('/PCS')}` }
                        </span>
                    ) }
                </PriceSemanticElementName>
            );
        }

        // reverse order of price string
        if (!containsNumber && numberBCurrency) {
            return (
                <PriceSemanticElementName
                  block="ProductPrice"
                  elem="InnerWrapper"
                  mods={ { isVisible: true } }
                >
                    <span { ...priceSchema }>
                        { formated.reverse().join('') }
                    </span>
                    { enable_qty_increments && displayQtyIncrementText && (
                        <span
                          block="ProductPrice"
                          elem="Each"
                        >
                            { ` ${ __('/PCS')}` }
                        </span>
                    ) }
                </PriceSemanticElementName>
            );
        }

        return (
            <PriceSemanticElementName
              block="ProductPrice"
              elem="InnerWrapper"
              mods={ { isVisible: true } }
            >
                { isPowerstep && (
                    <span { ...priceSchema }>
                        { enable_qty_increments
                            ? `${this.getQtyIncrementFormattedPrice(finalPrice)}`
                            : finalPrice }
                    </span>
                ) }
                { !isPowerstep && (
                    <>
                        <span { ...priceSchema }>
                            { finalPrice }
                        </span>
                        { enable_qty_increments && displayQtyIncrementText && (
                            <span
                              block="ProductPrice"
                              elem="Each"
                            >
                                { ` ${ __('/PCS')}` }
                            </span>
                        ) }
                    </>
                ) }
            </PriceSemanticElementName>
        );
    }

    renderOldPrice() {
        const {
            roundedRegularPrice,
            roundedRegularCleanPrice,
            discountPercentage,
            isSchemaRequired,
            variantsCount,
            stock_item: { enable_qty_increments },
            displayQtyIncrementText = true
        } = this.props;

        const schema = isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : {};
        const cleanNumber = true;
        const regularPrice = cleanNumber ? roundedRegularCleanPrice : roundedRegularPrice;

        return (
            <div
              block="ProductPrice"
              elem="InnerWrapper"
              mods={ { isVisible: discountPercentage > 0 } }
            >
                <del
                  block="ProductPrice"
                  elem="HighPrice"
                  mods={ { isVisible: discountPercentage > 0 } }
                  aria-label={ __('Old product price') }
                  { ...schema }
                >
                    { regularPrice }
                </del>
                { enable_qty_increments && displayQtyIncrementText && (
                    <del
                      block="ProductPrice"
                      elem="Each"
                    >
                        { ` ${ __('/PCS')}` }
                    </del>
                ) }
            </div>
        );
    }

    renderGiftCardPrice() {
        const {
            price: {
                price_min,
                price_max,
                currency: {
                    minimum_price: {
                        final_price: {
                            currency = 'DKK'
                        } = {}
                    } = {}
                } = {}
            },
            mix
        } = this.props;
        const priceFrom = roundPrice(price_min);
        const priceTo = roundPrice(price_max);

        if (!price_min) {
            return null;
        }

        return (
            <p
              block="ProductPrice"
              mix={ mix }
              aria-label={ price_max
                  ? `Product price: ${ priceFrom }-${ priceTo }${ currency }`
                  : `Product price: ${ formatPrice(priceFrom, currency) }` }
              itemProp="offers"
              itemScope
              itemType="https://schema.org/AggregateOffer"
            >
                <span>
                    <data value={ priceFrom }>
                        <span itemProp="lowPrice">{ formatPrice(priceFrom, currency) }</span>
                    </data>
                </span>
                { price_max && (
                    <>
                        -
                        <span>
                            <data value={ priceTo }>
                                <span itemProp="lowPrice">{ formatPrice(priceTo, currency) }</span>
                            </data>
                        </span>
                    </>
                ) }

                { this.renderSchema() }
            </p>
        );
    }

    getPriceValidUntilSchema() {
        const { priceValidUntil } = this.props;
        return { itemProp: 'priceValidUntil', content: priceValidUntil };
    }

    renderSchema() {
        const { isSchemaRequired } = this.props;

        if (isSchemaRequired) {
            const currencySchema = this.getCurrencySchema();
            const priceValidUntil = this.getPriceValidUntilSchema();
            return (
                <>
                    <meta { ...currencySchema } />
                    <meta { ...priceValidUntil } />
                </>
            );
        }

        return null;
    }

    getQtyIncrementFormattedPrice(formattedPrice, reverse = false) {
        const {
            stock_item: { qty_increments }
        } = this.props;

        // Split up formatted price, multiply by qty increments and join again to keep formatting
        const formattedArray = formattedPrice.split(/\b(\s)/);
        const finalPrice = parseFloat(formattedArray.pop().replace('.', '').replace(',', '.'));
        formattedArray.push(this.formatNumberWithCommas(finalPrice * qty_increments));
        return reverse ? formattedArray.reverse().join('') : formattedArray.join('');
    }

    formatNumberWithCommas(number) {
        // Round to 2 decimals, set dot every third digit before decimal, change decimal dot to comma
        const parts = String(number).split('.');
        const integerPart = parts.shift().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        parts.unshift(integerPart);
        return parts.join(',');
    }

    renderQtyIncrementWarning() {
        const {
            stock_item: { enable_qty_increments, qty_increments } = {},
            displayQtyIncrementText = true
        } = this.props;

        if (!enable_qty_increments || !displayQtyIncrementText) {
            return null;
        }

        return (
            <span
              block="ProductPrice"
              elem="QtyIncrementWarning"
            >
                { __('Sold in packs of %s pieces.', qty_increments) }
            </span>
        );
    }

    render() {
        const {
            price: {
                minimum_price: {
                    final_price,
                    regular_price
                } = {}
            } = {},
            formattedFinalPrice,
            isGiftCard,
            mix
        } = this.props;

        if (isGiftCard) {
            return this.renderGiftCardPrice();
        }

        if (!final_price || !regular_price) {
            return this.renderPlaceholder();
        }

        return (
            <p
              block="ProductPrice"
              mix={ mix }
              aria-label={ `Product price: ${formattedFinalPrice}` }
            >
                <div
                  block="ProductPrice"
                  elem="OuterWrapper"
                >
                    { this.renderOldPrice() }
                    { this.renderCurrentPrice() }
                </div>
                { this.renderQtyIncrementWarning() }
                { this.renderSchema() }
            </p>
        );
    }
}

export default ProductPrice;
