import { UPDATE_SINGLE_POST } from './PostsDetails.action';

/** @namespace Sofacompany/Store/PostsDetails/Reducer/getInitialState */
export const getInitialState = () => ({
    post: {}
});

/** @namespace Sofacompany/Store/PostsDetails/Reducer/PostsDetailsReducer */
export const PostsDetailsReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_SINGLE_POST:
        const { post } = action;

        return {
            ...state,
            post
        };

    default:
        return state;
    }
};

export default PostsDetailsReducer;
