/* eslint-disable import/no-cycle */

import Event, { EVENT_GTM_AB_TEST } from 'Util/Event';

import BaseEvent from './BaseEvent.event';

/**
 * Product click event
 * @namespace Sofacompany/Component/GoogleTagManager/Events/ABTestEvent/Event/ABTestEvent */
export class ABTestEvent extends BaseEvent {
    eventHandleDelay = 0;

    eventSend = false;

    bindEvent() {
        Event.observer(EVENT_GTM_AB_TEST, ({ flag, enabled }) => {
            if (!this.eventSend) {
                this.handle(
                    flag,
                    enabled
                );
            }
        });
    }

    handle(...args) {
        // Set little delay on event fire. make it async, to do not block main code
        setTimeout(() => {
            this.handler(...args);
        }, this.eventHandleDelay);
    }

    handler(flag, enabled) {
        this.pushEventData({
            flag,
            enabled
        });
        this.eventSend = true;
    }
}

export default ABTestEvent;
