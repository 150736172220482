/* eslint-disable import/no-cycle */

import Event, { EVENT_GTM_KLEVU_SEARCH } from 'Util/Event';

import BaseEvent from './BaseEvent.event';

/**
 * Product click event
 * @namespace Sofacompany/Component/GoogleTagManager/Events/KlevuSearch/Event/KlevuSearchEvent */
export class KlevuSearchEvent extends BaseEvent {
    eventHandleDelay = 0;

    bindEvent() {
        Event.observer(EVENT_GTM_KLEVU_SEARCH, (searchCriteria) => {
            this.handle(searchCriteria);
        });
    }

    handler(searchCriteria) {
        this.pushEventData({
            searchTerm: searchCriteria,
            pageType: 'search'
        });
    }
}

export default KlevuSearchEvent;
