import PropTypes from 'prop-types';

import {
    Menu as SourceMenu
} from 'SourceComponent/Menu/Menu.component';
import { SCROLL_DEBOUNCE_DELAY } from 'SourceComponent/Menu/Menu.config';
import { getSortedItems } from 'Util/Menu';
import isMobile from 'Util/Mobile';
import { debounce } from 'Util/Request';

import './ExtraMenu.style';

/** @namespace Sofacompany/Component/ExtraMenu/Component/ExtraMenu */
export class ExtraMenu extends SourceMenu {
    static propTypes = {
        menu: PropTypes.object.isRequired
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        const { closeMenu, isNoScroll } = this.props;

        if (!isNoScroll) {
            this.debouncedCloseMenu = debounce(closeMenu, SCROLL_DEBOUNCE_DELAY);

            window.addEventListener('scroll', this.debouncedCloseMenu);
        }
    }

    renderFirstLevel = (item) => {
        const { item_id } = item;
        const { activeMenuItemsStack } = this.props;

        const isActive = activeMenuItemsStack.includes(item_id);

        return (
            <li key={ item_id } block="Menu" elem="Item" mods={ { isActive, extra: true } }>
                { this.renderFirstLevelItems(item) }
            </li>
        );
    };

    renderExtra() {
        const { extra } = this.props;
        return (
            <li block="Menu" elem="Item" mods={ { extra: true } }>
                <div block="Menu" elem="Link" mods={ { cookie: true } }>
                    <figure className="Menu-ItemFigure Menu-ItemFigure_type_main">
                        <figcaption className="Menu-ItemCaption Menu-ItemCaption_type_main">
                        { extra }
                        </figcaption>
                    </figure>
                </div>

            </li>
        );
    }

    render() {
        const { menu, childs, extra } = this.props;
        const categoryArray = Object.values(menu);

        if (!categoryArray.length) {
            return <div block="ExtraMenu" elem="Container" />;
        }

        const [{ children, title: mainCategoriesTitle }] = categoryArray;
        const childrenArray = getSortedItems(Object.values(children));

        return (
            <div block="ExtraMenu" elem="Container">
                <ul
                  block="ExtraMenu"
                  elem="ItemList"
                  mix={ {
                      block: 'Menu',
                      elem: 'ItemList'
                  } }
                  mods={ { type: 'main' } }
                  aria-label={ mainCategoriesTitle }
                >

                    { extra && this.renderExtra(extra) }
                    { !childs && !isMobile.any() && childrenArray.map(this.renderFirstLevel) }
                    { !childs && isMobile.any() && categoryArray.map(this.renderFirstLevel) }

                    { childs && childs.map(this.renderFirstLevel) }
                </ul>
            </div>
        );
    }
}

export default ExtraMenu;
