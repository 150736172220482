import {
    FETCH_SHOWROOM_STOCK,
    FETCH_SHOWROOM_STOCK_FAILURE,
    FETCH_SHOWROOM_STOCK_SUCCESS
} from './ShowroomStock.action';

export const getInitialState = () => ({
    terminals: [],
    isLoading: false
});

export const ShowroomStockReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;
    switch (type) {
    case FETCH_SHOWROOM_STOCK:
        return {
            ...state,
            isLoading: true
        };
    case FETCH_SHOWROOM_STOCK_FAILURE:
        const { error } = action;
        return {
            ...state,
            isLoading: false,
            error
        };
    case FETCH_SHOWROOM_STOCK_SUCCESS:
        const { showroomStock } = action;
        return {
            ...state,
            isLoading: false,
            error: null,
            showroomStock
        };
    default:
        return state;
    }
};

export default ShowroomStockReducer;
