/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { getLocaleFromUrl } from 'Util/Global';

import PromotionBar from './PromotionBar.component';
import { PROMOTIONBAR_ISHIDDEN, PROMOTIONBAR_SLIDE_TIME } from './PromotionBar.config';

export const PromotionBarDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/PromotionBar/PromotionBar.dispatcher'
);

/** @namespace Sofacompany/Component/PromotionBar/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    promotionbars: state.PromotionBarReducer.promotionbars,
    isHidden: state.PromotionBarReducer.isHidden,
    storeConfigId: state.ConfigReducer.id,
    storeConfigBaseUrl: state.ConfigReducer.base_url,
    hasFetchedPromotionBarOnce: state.PromotionBarReducer.hasFetchedOnce

});

/** @namespace Sofacompany/Component/PromotionBar/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestPromotionBars: () => PromotionBarDispatcher.then(
        /** @namespace Component/PromotionBar/Container/then */
        ({ default: dispatcher }) => dispatcher.handleData(dispatch)
    ),
    updatePromotionBarVisibility: (status) => PromotionBarDispatcher.then(
        /** @namespace Component/PromotionBar/Container/then */
        ({ default: dispatcher }) => dispatcher.updatePromotionBarVisibility(status, dispatch)
    )
});

/** @namespace Sofacompany/Component/PromotionBar/Container/PromotionBarContainer */
export class PromotionBarContainer extends PureComponent {
    static propTypes = {
        requestPromotionBars: PropTypes.func.isRequired,
        promotionbars: PropTypes.array,
        updatePromotionBarVisibility: PropTypes.func.isRequired,
        isHidden: PropTypes.bool.isRequired,
        storeConfigId: PropTypes.any,
        prevStoreConfigId: PropTypes.any,
        storeConfigBaseUrl: PropTypes.string,
        hasFetchedPromotionBarOnce: PropTypes.any.isRequired
    };

    static defaultProps = {
        promotionbars: [],
        storeConfigId: null,
        prevStoreConfigId: null,
        storeConfigBaseUrl: ''
    };

    state = {
        slideMargin: 0
    };

    containerFunctions = {
        handleClose: this.handleClose.bind(this)
    };

    componentDidMount() {
        const { requestPromotionBars, storeConfigBaseUrl, hasFetchedPromotionBarOnce } = this.props;
        const locale = getLocaleFromUrl();
        document.documentElement.classList.add('promotionBarIsHidden');

        if (BrowserDatabase.getItem(PROMOTIONBAR_ISHIDDEN)) {
            return;
        }

        if ((storeConfigBaseUrl !== undefined && storeConfigBaseUrl.includes(locale))) {
            requestPromotionBars();
        }
    }

    componentDidUpdate(prevProps) {
        const { isHidden: prevIsHidden, storeConfigId: prevStoreConfigId } = prevProps;
        const {
            promotionbars,
            isHidden,
            storeConfigId,
            requestPromotionBars
        } = this.props;

        if (BrowserDatabase.getItem(PROMOTIONBAR_ISHIDDEN)) {
            document.documentElement.classList.add('promotionBarIsHidden');
            return;
        }

        if (promotionbars && promotionbars.length > 0) {
            document.documentElement.classList.remove('promotionBarIsHidden');
            this.slideInterval();
        }

        if (isHidden && prevIsHidden !== isHidden) {
            document.documentElement.classList.add('promotionBarIsHidden');
        }

        if (storeConfigId !== prevStoreConfigId) {
            requestPromotionBars();
        }
    }

    handleClose() {
        const { updatePromotionBarVisibility } = this.props;

        updatePromotionBarVisibility(true);
        BrowserDatabase.setItem(true, PROMOTIONBAR_ISHIDDEN);

        this.setState({
            slideMargin: 0
        });
    }

    slideInterval() {
        const { slideMargin, promotionbars } = this.state;

        if (!promotionbars) {
            return;
        }

        setTimeout(() => {
            // eslint-disable-next-line no-magic-numbers
            if (slideMargin === ((promotionbars.length - 1) * 100)) {
                this.setState({
                    slideMargin: 0
                });
            } else {
                this.setState({
                    // eslint-disable-next-line no-magic-numbers
                    slideMargin: slideMargin + 100
                });
            }
        }, PROMOTIONBAR_SLIDE_TIME);
    }

    render() {
        return (
            <PromotionBar
              { ...this.state }
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionBarContainer);
