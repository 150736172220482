import {
    TierPrices as SourceTierPrices
} from 'SourceComponent/TierPrices/TierPrices.component';
import { formatPrice } from 'Util/Price';

/** @namespace Sofacompany/Component/TierPrices/Component/TierPrices */
export class TierPrices extends SourceTierPrices {
    renderLowestTierPrice() {
        const {
            product: {
                price_tiers,
                price_range: {
                    minimum_price: {
                        final_price: {
                            currency
                        }
                    }
                }
            }
        } = this.props;

        const lowestValue = price_tiers
            .reduce((acc, { final_price: { value } }) => (acc < value ? acc : value), price_tiers[0].final_price.value);

        const formattedPrice = formatPrice(lowestValue, currency);
        return (
            <span block="TierPrices" elem="Item" mods={ { isLowest: true } }>
                <span block="TierPrices" elem="ItemPrice">
                    { `${ formattedPrice }` }
                </span>
            </span>
        );
    }
}

export default TierPrices;
