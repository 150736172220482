import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    NewVersionPopupContainer as SourceNewVersionPopupContainer
} from 'SourceComponent/NewVersionPopup/NewVersionPopup.container';
import { showNotification } from 'Store/Notification/Notification.action';
import BrowserSession from 'Util/BrowserDatabase/BrowserSession';

/** @namespace Sofacompany/Component/NewVersionPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (message) => dispatch(showNotification('success', message))
});

export {
    mapStateToProps
};

/** @namespace Sofacompany/Component/NewVersionPopup/Container/NewVersionPopupContainer */
export class NewVersionPopupContainer extends SourceNewVersionPopupContainer {
    componentDidMount() {
        // eslint-disable-next-line no-unused-vars
        const { goToPreviousHeaderState, device, showNotification } = this.props;

        if (BrowserSession.getItem('NewVersionPopup')) {
            BrowserSession.deleteItem('NewVersionPopup');

            // showNotification(__('We detected a update to the website. Reload was required to apply changes.'));
        }

        if ('serviceWorker' in navigator) {
            const originalSW = navigator.serviceWorker.controller;
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (originalSW) {
                    BrowserSession.setItem(true, 'NewVersionPopup');
                    window.location.reload();

                    if (device.isMobile) {
                        goToPreviousHeaderState();
                    }
                }
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVersionPopupContainer);
