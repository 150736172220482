/* eslint-disable no-unused-vars */
import React from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import {
    MenuItem as SourceMenuItem
} from 'SourceComponent/MenuItem/MenuItem.component';
import media from 'Util/Media';
import isMobile from 'Util/Mobile';

/** @namespace Sofacompany/Component/MenuItem/Component/MenuItem */
export class MenuItem extends SourceMenuItem {
    renderItemContentImage(icon, itemMods) {
        const { type } = itemMods;

        if (!icon || (type === 'subcategory')) {
            return null;
        }

        return (
            <div block="Menu" elem="Picture">
                <Image
                  mix={ { block: 'Menu', elem: 'Image', mods: itemMods } }
                  src={ icon && media(icon) }
                  ratio="custom"
                />
            </div>
        );
    }

    renderItemContentTitle(parentLink) {
        const {
            activeMenuItemsStack,
            item,
            itemMods: { isBanner },
            handleCategoryHover,
            closeMenu
        } = this.props;

        const {
            item_id,
            title
        } = item;

        const isHovered = activeMenuItemsStack.includes(item_id);

        if (isBanner) {
            return (
                <button
                  block="Menu"
                  elem="Button"
                  mix={ { block: 'Button' } }
                >
                    { title }
                </button>
            );
        }

        if (parentLink) {
            return title;
        }

        return (
            <div
              block="Menu"
              elem="Link"
              onMouseEnter={ handleCategoryHover }
              mods={ { isHovered } }
              onClick={ () => {
                  closeMenu();
              } }
            >
                { title }
            </div>
        );
    }

    renderItemContent(item, itemMods = {}, parentLink = false) {
        const { icon } = item;

        return (
            <figure
              block="Menu"
              elem="ItemFigure"
              mods={ itemMods }
            >
                { this.renderItemContentImage(icon, itemMods) }
                <figcaption
                  block="Menu"
                  elem="ItemCaption"
                  mods={ itemMods }
                >
                    { this.renderItemContentTitle(parentLink) }
                </figcaption>
            </figure>
        );
    }

    renderItemLinkContent() {
        const {
            activeMenuItemsStack,
            item,
            itemMods,
            handleCategoryHover,
            closeMenu
        } = this.props;

        const {
            url,
            item_id,
            children,
            item_class
        } = item;

        const isHovered = activeMenuItemsStack.includes(item_id);
        const isTouchDevice = window.matchMedia('(hover: none) and (pointer: coarse)').matches;
        const isLinkOnly = Object.values(children).length === 0;

        // Do not render link for sub menu group name
        if (item_class.includes('SubMenuGroupName')) {
            return this.renderItemContent(item, itemMods);
        }

        if ((!isMobile.any() && !isTouchDevice) && item_class.includes('TouchDeviceOnly')) {
            return null;
        }

        if (isMobile.any() && item_class.includes('DesktopDeviceOnly')) {
            return null;
        }

        return (
            <Link
              to={ url }
              block="Menu"
              elem="Link"
              onMouseEnter={ handleCategoryHover }
              mods={ { isHovered } }
              onClick={ (e) => (isLinkOnly || !isTouchDevice ? closeMenu() : e.preventDefault()) }
            >
                { this.renderItemContent(item, itemMods, true) }
            </Link>
        );
    }
}

export default MenuItem;
