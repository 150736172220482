import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './AttentionBox.style';

export class AttentionBox extends PureComponent {
    static propTypes = {
        attentionMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
    };

    render() {
        const { attentionMessage } = this.props;
        return (
            <div
              block="AttentionBox"
              ref={ (divElement) => {
                  this.divElement = divElement;
              } }
            >
                { attentionMessage }
            </div>
        );
    }
}

export default AttentionBox;
