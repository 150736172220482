import {
    MyAccountQuery as SourceMyAccountQuery
} from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

export class MyAccountQuery extends SourceMyAccountQuery {
    getCreateProfessionalAccountMutation(options) {
        const { customer, password } = options;

        return new Field('createCustomerAndAddress')
            .addArgument('input', 'CustomerAndAddressCreateInput!', { ...customer, password })
            .addField(this._getCustomerField());
    }

    _getAddressFields() {
        return [
            'id',
            'customer_id',
            'country_id',
            'street',
            'telephone',
            'postcode',
            'company',
            'city',
            'firstname',
            'lastname',
            'middlename',
            'prefix',
            'suffix',
            'default_shipping',
            'default_billing',
            'vat_id',
            this._getRegionField()
        ];
    }

    getResetPasswordMutation(options) {
        const {
            token, password, email
        } = options;

        return new Field('resetPassword')
            .addArgument('newPassword', 'String!', password)
            .addArgument('resetPasswordToken', 'String!', token)
            .addArgument('email', 'String!', email);
    }
}

export default new MyAccountQuery();
