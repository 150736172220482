/* eslint-disable no-undef */
import Event, {
    EVENT_GTM_GENERAL_INIT,
    EVENT_GTM_IMPRESSION
} from 'Util/Event';
import { getCurrentVariantIndexFromFilters } from 'Util/Product';

import ProductHelper from '../utils';
import BaseEvent from './BaseEvent.event';

/**
 * Constants
 *
 * @type {number}
 */
export const SPAM_PROTECTION_DELAY = 2000;
export const PRODUCT_IMPRESSION_COUNT = 36;
export const PRODUCT_IMPRESSION_CHUNK_SIZE = 12;
export const EVENT_HANDLE_DELAY = 700;

/**
 * GTM PWA Impression Event
 *
 * Called when customer see product list
 * On: Home, PLP, WishList, Up-Sell, Cross-Sell, Accessories(related things on PDP)
 * @namespace Sofacompany/Component/GoogleTagManager/Events/Impression/Event/Impression
 */
export class Impression extends BaseEvent {
    /**
     * Set base event call delay
     *
     * @type {number}
     */
    eventHandleDelay = EVENT_HANDLE_DELAY;

    /**
     * Bind PWA event handling
     */
    bindEvent() {
        Event.observer(EVENT_GTM_IMPRESSION, (products) => {
            this.handle(products);
        });

        // General
        Event.observer(EVENT_GTM_GENERAL_INIT, () => {
            this.cleanStorage();
        });
    }

    handler(products) {
        const { items, pagetype } = products;
        const impressionUID = this.getImpressionUID(items);
        if (!products
            || Object.values(products).length === 0
            || this.spamProtection(SPAM_PROTECTION_DELAY, `${ pagetype }_${ impressionUID }`)
        ) {
            return;
        }

        this.pushEventData({
            ecommerce: {
                currencyCode: this.getCurrencyCode(),
                impressions: this.getProducts(products)
            }
        });
    }

    getProducts(products) {
        const { items } = products;
        const impression = [];
        // eslint-disable-next-line no-unused-vars
        if (!products) {
            return null;
        }
        items.forEach((x) => {
            const obj = {
                name: x.name,
                id: x.id,
                price: x?.price_range?.minimum_price?.final_price?.value,
                brand: 'sofacompany',
                category: ProductHelper.getCategory(x.categories),
                variant: x.sofa_fabric_name_label,
                list: ProductHelper.getProductListName(products),
                position: x.position
            };

            impression.push(obj);
        });

        return impression;
    }

    /**
     * Handle Impressions
     *
     * @param productCollectionType product event type
     * @param products Product list
     * @param filters Category filters
     */
    handler4(productCollectionType = PLP_IMPRESSIONS, products = [], filters = {}, category = {}) {
        const impressions = this.getImpressions(productCollectionType, products, filters, category);
        const storage = this.getStorage();
        const impressionUID = this.getImpressionUID(impressions);

        if (!impressions
            || Object.values(impressions).length === 0
            || this.spamProtection(SPAM_PROTECTION_DELAY, `${ productCollectionType }_${ impressionUID }`)
        ) {
            return;
        }

        if (!storage.impressions) {
            storage.impressions = [];
        }
        storage.impressions.push(...impressions);
        this.setStorage(storage);

        // Chunk data to small parts
        // eslint-disable-next-line fp/no-loops, fp/no-let
        for (let offset = 0; offset < impressions.length; offset += PRODUCT_IMPRESSION_CHUNK_SIZE) {
            this.pushEventData({
                ecommerce: {
                    currencyCode: this.getCurrencyCode(),
                    impressions: impressions.slice(offset, offset + PRODUCT_IMPRESSION_CHUNK_SIZE)
                }
            });
        }
    }

    /**
     * Get impressions
     *
     * @param productCollectionType
     * @param products
     * @param filters
     *
     * @return {{price: string, name: string, variant: string, id: string, position: number, list: string, category: string, brand: string}[]}
     */
    getImpressions(productCollectionType = PLP_IMPRESSIONS, products, filters, category) {
        const { name: categoryName = '', url_path = '' } = category;
        const productCollection = this.getProductCollection(productCollectionType, products);
        const productCount = Object.values(productCollection || []).length;

        const offset = PRODUCT_IMPRESSION_COUNT - productCount < 0
            ? Math.abs(PRODUCT_IMPRESSION_COUNT - productCount)
            : 0;

        return Object.values(productCollection || [])
            .slice(-PRODUCT_IMPRESSION_COUNT) // Last from list
            .filter((product) => Object.values(product).length)
            .map((product, index) => {
                const configurableVariantIndex = getCurrentVariantIndexFromFilters(product, filters);

                const productData = productCollectionType === WISHLIST_IMPRESSIONS
                    ? ProductHelper.getItemData(product)
                    : ProductHelper.getProductData({ ...product, configurableVariantIndex, category: url_path });

                return {
                    ...productData,
                    position: offset + index + 1,
                    list: this.getProductCollectionList(productCollectionType, product, categoryName)
                };
            });
    }

    /**
     * Get collection of products
     *
     * @param productCollectionType
     * @param params
     *
     * @return {Array}
     */
    getProductCollection(productCollectionType = PLP_IMPRESSIONS, products) {
        switch (productCollectionType) {
        case PLP_IMPRESSIONS:
        case WISHLIST_IMPRESSIONS:
        case HOME_IMPRESSIONS:
        case SEARCH_IMPRESSIONS:
        case RECOMMENDED_IMPRESSIONS:
        case CHECKOUT_CROSS_SELL_IMPRESSIONS:
            return products || [];
        default:
            return [];
        }
    }

    /**
     * Get product collection list name
     *
     * @param productCollectionType
     * @param product
     *
     * @return {string}
     */
    getProductCollectionList(productCollectionType = PLP_IMPRESSIONS, product, categoryName = '') {
        switch (productCollectionType) {
        case HOME_IMPRESSIONS:
            return 'Homepage';
        case RECOMMENDED_IMPRESSIONS:
            return 'Recommended';
        case SEARCH_IMPRESSIONS:
            return 'Search results';
        case WISHLIST_IMPRESSIONS:
            return 'Wishlist';
        case CHECKOUT_CROSS_SELL_IMPRESSIONS:
            return 'Cross sell impressions';
        case PLP_IMPRESSIONS:
            return categoryName
                ? `PLP - ${ categoryName }`
                : 'PLP';
        default:
            return ProductHelper.getList(product);
        }
    }

    /**
     * Get provided impression UID
     *
     * @param impression
     * @return {string}
     */
    getImpressionUID(impression = []) {
        return impression.reduce((acc, { id }) => `${ acc }_${ id }`, '');
    }
}

export default Impression;
