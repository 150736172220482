/**
 * Event names
 *
 * @type {string}
 */
export const EVENT_GTM_CHECKOUT = 'gtm_checkout';
export const EVENT_GTM_CHECKOUT_OPTION = 'gtm_checkout_option';
export const EVENT_GTM_CHECKOUT_EMAIL = 'gtm_checkout_email';
export const EVENT_GTM_IMPRESSION = 'gtm_impressions';
export const EVENT_GTM_META_UPDATE = 'gtm_meta_update';
export const EVENT_GTM_GENERAL_INIT = 'gtm_general_init';
export const EVENT_GTM_PRODUCT_ADD_TO_CART = 'gtm_product_add_to_cart';
export const EVENT_GTM_PRODUCT_CLICK = 'gtm_product_click';
export const EVENT_GTM_PRODUCT_DETAIL = 'gtm_product_detail';
export const EVENT_GTM_PRODUCT_REMOVE_FROM_CART = 'gtm_product_remove_from_cart';
export const EVENT_GTM_PURCHASE = 'gtm_purchase';
export const EVENT_GTM_USER_LOGIN = 'gtm_user_login';
export const EVENT_GTM_USER_REGISTER = 'gtm_user_register';
export const EVENT_GTM_USER_IP = 'gtm_user_ip';
export const EVENT_GTM_KLEVU_SEARCH = 'gtm_klevu_search';
export const EVENT_GTM_BLOG = 'gtm_blog';
export const EVENT_GTM_404 = 'gtm_404';
export const EVENT_GTM_JOB_SEEKERS = 'gtm_job_seekers';
export const EVENT_GTM_AB_TEST = 'gtm_ab_test';
export const EVENT_GTM_POPUP = 'gtm_popup';
export const EVENT_GTM_FEATURED_CAT_CLICK = 'gtm_featured_cat_click';
export const EVENT_GTM_PLP_FILTER_CLICK = 'gtm_plp_filter_click';
export const EVENT_GTM_SLIDER_CLICK = 'gtm_slider_click';
