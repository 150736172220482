/* eslint-disable no-magic-numbers */

import MyAccountQuery from 'Query/MyAccount.query';
import {
    CUSTOMER,
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import {
    updateCustomerPasswordResetStatus, updateCustomerSignInStatus,
    updateIsLoading
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { setAuthorizationToken } from 'Util/Auth';
import { setGuestQuoteId } from 'Util/Cart/Token';
import { fetchMutation, getErrorMessage } from 'Util/Request';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

export {
    CUSTOMER,
    ONE_MONTH_IN_SECONDS
};

export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    createProfessionalAccount(options = {}, dispatch) {
        // const { customer: { email }, password } = options;
        const mutation = MyAccountQuery.getCreateProfessionalAccountMutation(options);
        dispatch(updateIsLoading(true));

        return fetchMutation(mutation).then(
            /** @namespace Store/MyAccount/Dispatcher/createAccountFetchMutationThen */
            (data) => {
                const { createCustomerAndAddress: { customer } } = data;
                const { confirmation_required } = customer;
                dispatch(updateIsLoading(false));

                if (confirmation_required) {
                    return 2;
                }

                return 1;
            },

            /** @namespace Store/MyAccount/Dispatcher/createAccountFetchMutationError */
            (error) => {
                dispatch(updateIsLoading(false));
                dispatch(showNotification('error', getErrorMessage(error)));
                Promise.reject();

                return false;
            }
        );
    }

    async signIn(options = {}, dispatch) {
        const mutation = MyAccountQuery.getSignInMutation(options);

        const result = await fetchMutation(mutation);
        const { generateCustomerToken: { token } } = result;

        setAuthorizationToken(token);

        const cartDispatcher = (await CartDispatcher).default;
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch);

        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );

        ProductCompareDispatcher.then(
            ({ default: dispatcher }) => dispatcher.assignCompareList(dispatch)
        );

        await this.requestCustomerData(dispatch);

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        dispatch(showNotification('success', __('You are successfully logged in!')));

        return true;
    }

    resetPassword(options = {}, dispatch) {
        const mutation = MyAccountQuery.getResetPasswordMutation(options);
        const { email, password } = options;
        return fetchMutation(mutation).then(
            /** @namespace Store/MyAccount/Dispatcher/resetPasswordFetchMutationThen */
            ({ resetPassword }) => {
                dispatch(updateCustomerPasswordResetStatus(resetPassword));
                this.signIn({ email, password }, dispatch);
            },
            /** @namespace Store/MyAccount/Dispatcher/resetPasswordFetchMutationError */
            (errors) => dispatch(updateCustomerPasswordResetStatus('error', getErrorMessage(errors)))
        );
    }
}

export default new MyAccountDispatcher();
