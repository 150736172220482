import BrowserDatabase from 'Util/BrowserDatabase';
import { roundPrice } from 'Util/Price';

import GoogleTagManager, { EVENT_GENERAL } from '../GoogleTagManager.component';
// eslint-disable-next-line no-unused-vars
import { categorySwitcher } from './Product.config';

export const PRODUCT_COLOR = 'variant';
export const GROUPED_PRODUCT_PRICE = 'metric1';

export const NOT_APPLICABLE = 'Not defined';

/**
 * Product helper, contain all related to product data prepare methods
 * @namespace Sofacompany/Component/GoogleTagManager/Utils/Product/Product
 */
export class Product {
    static DEFAULT_BRAND = 'Sofacompany';

    /**
     * Get product listing category string
     *
     * @param product
     * @return {string|string}
     */
    static getList() {
        const meta = GoogleTagManager.getEvent(EVENT_GENERAL).currentMeta.metaObject || {};

        return meta.name
            || meta.title
            || document.title.split('|').pop()
            || '';
    }

    /**
     * Get product Quantity from product object
     *
     * @param product
     * @return {number|string}
     */
    static getQuantity({ qty }) {
        return parseInt(qty, 10) || 0;
    }

    /**
     * Get product brand from product object
     *
     * @return {string|string}
     * @param selectedVariant
     */
    static getBrand(selectedVariant) {
        const { attributes = {} } = selectedVariant;
        const { brand: { attribute_value = '' } = {} } = attributes;
        return attribute_value;
    }

    static getSelectedVariant(product) {
        const { sku, variants } = product;
        return variants.find(({ sku: variantSku }) => sku === variantSku);
    }

    static getSelectedVariantIndex(product, sku) {
        const { variants = [] } = product;
        return variants.findIndex(({ sku: variantSku = '' }) => sku === variantSku);
    }

    static getProductListName(product) {
        const { list, category } = product;
        if (!list) {
            return null;
        }
        if (list.currentPageName && list.currentPageName !== null) {
            const concatList = `${list.currentPageAppender}${list.currentPageName}`;
            return concatList;
        }
        if (list.currentPageId) {
            // eslint-disable-next-line fp/no-let
            let listname = categorySwitcher[list.currentPageId];
            if (!listname) {
                listname = category?.url_key;
            }
            const concatList = `${list.currentPageAppender}${listname}`;

            return concatList;
        }

        return list.currentPageAppender;
    }

    /**
     * Get product sku
     *
     * @param product
     * @return {string}
     */
    static getSku(product) {
        const { variants = [], configurableVariantIndex = -1 } = product;
        const { sku = '' } = variants[configurableVariantIndex] || product;
        return sku;
    }

    /**
     * Get item data as object
     *
     *
     * @return {{quantity: number, price: number, name: string, variant: string, id: string, availability: boolean, list: string, category: string, brand: string}}
     * @param item
     */
    static getItemData(item) {
        if (item && Object.values(item).length) {
            const { product = {}, sku = '' } = item;
            const configurableVariantIndex = this.getSelectedVariantIndex(product, sku);

            return this.getProductData({ ...product, configurableVariantIndex }, item);
        }

        return {};
    }

    static getCategory(categories = []) {
        const theCategory = categories.find((x) => {
            if (x?.breadcrumbs?.length) {
                if (x.category_seo_name || categorySwitcher[x.id]) {
                    return x;
                }
            }
        });

        if (!theCategory) {
            return null;
        }
        const { id, category_seo_name } = theCategory;
        const withLabelname = !!category_seo_name;
        return withLabelname ? category_seo_name : categorySwitcher[id];
    }

    static getPrice(variant, type_id) {
        const {
            price_range: {
                minimum_price: {
                    final_price: {
                        value
                    } = {}
                } = {}
            } = {}
        } = variant;

        return type_id !== 'grouped'
            ? +roundPrice(value) || 0
            : 0;
    }

    /**
     * @param groupedProductData
     * @param product
     * @param groupedProductPrice
     */
    static addGroupedProduct(groupedProductData, product, groupedProductPrice) {
        const GTMInstance = GoogleTagManager.getInstance();
        const groupedProducts = GTMInstance.getGroupedProducts();
        const { sku, items } = product;
        const existingGroupedProduct = groupedProducts[sku];

        if (existingGroupedProduct) {
            const { data: { [GROUPED_PRODUCT_PRICE]: oldPrice } } = existingGroupedProduct;
            groupedProducts[sku].data[GROUPED_PRODUCT_PRICE] = groupedProductPrice + oldPrice;
        } else {
            groupedProducts[sku] = {
                data: groupedProductData,
                items: this.getArrayOfGroupedProductChildrenSku(items)
            };
        }

        GTMInstance.setGroupedProducts(groupedProducts);
    }

    static getArrayOfGroupedProductChildrenSku(items) {
        return items.reduce((acc, { product: { sku } }) => [...acc, sku], []);
    }

    static updateGroupedProduct(childSku, price) {
        const GTMInstance = GoogleTagManager.getInstance();
        const groupedProducts = GTMInstance.getGroupedProducts();
        const skuOfProductToUpdate = Object.keys(groupedProducts).find((sku) => {
            const { items } = groupedProducts[sku];
            return items.includes(childSku);
        });

        if (skuOfProductToUpdate) {
            const { [GROUPED_PRODUCT_PRICE]: prevPrice } = groupedProducts[skuOfProductToUpdate].data;

            // 0 price metric form grouped product indicates that no more children products are left in cart
            if (prevPrice + price === 0) {
                const productToDelete = groupedProducts[skuOfProductToUpdate];
                delete groupedProducts[skuOfProductToUpdate];

                GTMInstance.setGroupedProducts(groupedProducts);
                return productToDelete;
            }

            groupedProducts[skuOfProductToUpdate].data[GROUPED_PRODUCT_PRICE] += price;
            GTMInstance.setGroupedProducts(groupedProducts);
        }

        return null;
    }

    static mergeGroupedProducts(groupedProducts1, groupedProducts2) {
        if (!groupedProducts1) {
            return groupedProducts2;
        }
        if (!groupedProducts2) {
            return groupedProducts1;
        }

        const result = { ...groupedProducts2 };

        Object.keys(groupedProducts1).forEach((key) => {
            if (groupedProducts2[key]) {
                result[key].data[GROUPED_PRODUCT_PRICE] += groupedProducts1[key].data[GROUPED_PRODUCT_PRICE];
            } else {
                result[key] = groupedProducts1[key];
            }
        });

        return result;
    }

    /**
     * varian: color
     * dimension1: simple/grouped
     * dimension2: variantSKU
     * metric1: total for grouped product
     */

    static getVariantSku(sku, variantSku, isVariantPassed) {
        return (variantSku === sku && !isVariantPassed)
            ? NOT_APPLICABLE
            : variantSku;
    }

    static getGroupedProductPrice(product) {
        const { groupedProductPrice = 0 } = product;
        return groupedProductPrice;
    }

    /**
     * Get product data as object
     *
     * @param product
     * @param item
     * @return {{quantity: number, price: number, name: string, variant: string, id: string, availability: boolean, list: string, category: string, brand: string}}
     */
    static getProductData(product, item = {}) {
        const { dyo_data } = item;
        const isTypeUndefined = (typeof product.name === 'undefined');
        const trimmedName = (!isTypeUndefined) ? product?.name?.trim() : item?.name?.trim();
        const productSku = (!isTypeUndefined) && !dyo_data ? product.sku : item.sku;
        const productNewSku = (!isTypeUndefined) && !dyo_data ? product?.newSku : item?.newSku;

        if (dyo_data) {
            const { id } = dyo_data;
            product.sku = id;
        }

        const {
            sku,
            name = trimmedName,
            type_id,
            category = NOT_APPLICABLE,
            variants = [],
            categories = [],
            configurableVariantIndex = this.getSelectedVariantIndex(product, sku)
        } = product;
        const selectedVariant = variants[configurableVariantIndex] || product;

        return {
            name,
            id: productNewSku || productSku,
            price: this.getPrice(selectedVariant, type_id),
            brand: this.getBrand(selectedVariant) || this.DEFAULT_BRAND,
            category: this.getCategory(categories) || category,
            [PRODUCT_COLOR]: this.getCartItemVariant(product)
        };
    }

    static getCartItemVariant(product) {
        const {
            sku,
            newSku,
            sofa_fabric_name_label
        } = product;

        const currentSku = newSku || sku?.replace(/-/g, '');

        const storedVariants = BrowserDatabase.getItem('product_variants') || {};
        const storedVariant = storedVariants[currentSku];

        if ((sofa_fabric_name_label === 'false'
        || sofa_fabric_name_label === ' - '
        || !sofa_fabric_name_label) && storedVariant) {
            return storedVariant;
        }

        return sofa_fabric_name_label;
    }

    static getCartItemCategory(product) {
        const productCategories = BrowserDatabase.getItem('product_categories') || {};

        const { sku, newSku } = product;

        const currentSku = newSku || sku?.replace(/-/g, '');

        return productCategories[currentSku] || 'n/a';
    }
}

export default Product;
