Mosaic.setPlugins([require('./plugin/AdditionalRoutes.plugin.js'),
require('./plugin/StoreModifications.plugin.js'),
require('./plugin/TransactionId.plugin.js'),
require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js')]);
import { Field } from '@scandipwa/scandipwa/src/util/Query';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { FlagProvider } from '@unleash/proxy-client-react';
import { render } from 'react-dom';

import App from 'Component/App';
import { routesArray } from 'Component/Router/Router.component';
import { fetchQuery } from 'Util/Request';

import 'Util/Extensions';
import 'Util/Polyfill';
import 'Style/main';

if (window.location.pathname === '/' && window.location.hostname !== 'localhost') {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;
    const languages = navigator.languages ? navigator.languages : [navigator.language];
    // eslint-disable-next-line fp/no-let
    let found = false;
    languages.forEach((field) => {
        if (routesArray[field.toLowerCase()]) {
            found = true;
            window.location = `/${ field.toLowerCase() }`;
        }
    });
    if (!found) {
        const query = new Field('getStoreCode')
            .addField('url')
            .addArgument('browserLanguage', 'String', language);
        const queries = [query];
        fetchQuery(queries).then((data) => {
            if (data.getStoreCode.url) {
                window.location = data.getStoreCode.url;
            }
        });
    }
}

// appends a slash to the end of the URL if it doesn't exist
export const slashSuffix = (path) => (path.endsWith('/') ? path : `${ path }/`);

// ugly redirect fix for old links search queries
if (window.location.pathname.match(/search/) && window.location.search.match(/\?q=/)) {
    // get the search term from the query string and remove ?q=. i.e ?q=something&utm_source=1 => something
    const searchQuery = window.location.search.match(/\?q=([^&]*)/)[1];

    // get the other query params &utm_source&utm_medium&utm_campaign etc.
    const otherParams = window.location.search.replace(/\?q=[^&]*/, '').replace(/^&/, '');

    // build the new url
    // eslint-disable-next-line max-len
    window.location = `${slashSuffix(window.location.pathname) }${ searchQuery }${ otherParams ? `?${ otherParams}` : '' }`;
}

export const sentryEnv = {
    'sofacompany.com': {
        enabled: true,
        environment: 'production',
        sampleRate: 0.05
    },
    'staging.sofacompany.com': {
        enabled: true,
        environment: 'staging',
        sampleRate: 0.5
    },
    default: {
        enabled: false
    }
};
export const sentryHost = window.location.host !== undefined && sentryEnv[window.location.host] !== undefined
    ? window.location.host : 'default';

if (sentryEnv[sentryHost].enabled) {
    Sentry.init({
        dsn: 'https://d0e0410da4d617b361ed49682de0440c@sentry.wexo.io/20',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: sentryEnv[sentryHost].sampleRate,
        sampleRate: sentryEnv[sentryHost].sampleRate,
        environment: sentryEnv[sentryHost].environment
    });
}

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = `${ process.env.PUBLIC_URL }/service-worker.js`;
        navigator.serviceWorker.register(swUrl);
    });
}

export const abTestEnv = {
    'sofacompany.com': {
        url: 'https://ab-test.sofacompany.com/proxy',
        environment: 'production'
    },
    default: {
        url: 'https://ab-dev.sofacompany.com/proxy',
        environment: 'dev'
    }
};

export const host = window.location.host !== undefined && abTestEnv[window.location.host] !== undefined
    ? window.location.host : 'default';
export const config = {
    url: abTestEnv[host].url ?? 'https://ab-test.sofacompany.com/proxy',
    clientKey: 'b27028543365cdab8621ef19c7f313755eeb4e87',
    refreshInterval: 3600,
    appName: 'sofacompany',
    environment: abTestEnv[host].environment ?? 'production'
};

render(<FlagProvider config={ config }><App /></FlagProvider>, document.getElementById('root'));
