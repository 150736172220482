import PropTypes from 'prop-types';

import {
    ImageContainer as SourceImageContainer
} from 'SourceComponent/Image/Image.container';

export class ImageContainer extends SourceImageContainer {
    static propTypes = {
        ...SourceImageContainer.propTypes,
        loading: PropTypes.string,
        alt: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ])
    };
}

export default ImageContainer;
