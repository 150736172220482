/* eslint-disable no-unused-vars */
import ScrollContainer from 'react-indiana-drag-scroll';

import CmsBlock from 'Component/CmsBlock';
import ExtraMenu from 'Component/ExtraMenu';
import Icon from 'Component/Icon';
import MenuItem from 'Component/MenuItem';
import {
    Menu as SourceMenu
} from 'SourceComponent/Menu/Menu.component';
import { darkenColor } from 'Util/Global';
import { getSortedItems } from 'Util/Menu';
import isMobile from 'Util/Mobile';
import { debounce } from 'Util/Request';

import { SCROLL_DEBOUNCE_DELAY } from './Menu.config';

/** @namespace Sofacompany/Component/Menu/Component/Menu */
export class Menu extends SourceMenu {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        const { closeMenu, isNoScroll } = this.props;

        if (!isNoScroll) {
            this.debouncedCloseMenu = debounce(closeMenu, SCROLL_DEBOUNCE_DELAY);

            window.addEventListener('scroll', this.debouncedCloseMenu);
        }
    }

    renderCmsBlock(category) {
        if (isMobile.any()) {
            return null;
        }

        const { item_class } = category;

        const menu = item_class.split(' ')?.find((x) => x.includes('menu-block'));

        if (!menu) {
            return null;
        }

        return (
            <div block="Menu" elem="CmsBlock">
                <CmsBlock identifier={ menu } />
            </div>
        );
    }

    renderExtraMenu(obj) {
        if (!isMobile.any()) {
            return null;
        }

        return <ExtraMenu childs={ obj } />;
    }

    renderExtraSocialMenu() {
        if (!isMobile.any()) {
            return null;
        }

        return <ExtraMenu menu="social-menu" />;
    }

    renderAdditionalInformation(checkMobile = false) {
        const { device } = this.props;

        if (checkMobile && !device.isMobile) {
            return null;
        }

        return (
            <>
                { this.renderStoreSwitcher() }
            </>
        );
    }

    renderFirstLevelItems(item) {
        const {
            activeMenuItemsStack,
            handleSubcategoryClick,
            onCategoryHover,
            closeMenu,
            device
        } = this.props;

        const { children, item_id } = item;
        const childrenArray = Object.values(children);
        const itemMods = { type: 'main' };
        const isVisible = activeMenuItemsStack.includes(item_id);

        if (childrenArray.length && device.isMobile) {
            return (
                <div
                    // TODO: split into smaller components
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleSubcategoryClick(e, item) }
                  tabIndex="0"
                  block="Menu"
                  elem="SubCatLink"
                  mods={ { isVisible } }
                  role="button"
                >
                    <div block="Menu" elem="SubCatLink-Item">
                        <MenuItem
                          activeMenuItemsStack={ activeMenuItemsStack }
                          item={ item }
                          itemMods={ itemMods }
                          onCategoryHover={ onCategoryHover }
                          closeMenu={ closeMenu }
                        />
                        <Icon icon="arrow-down" />
                    </div>
                    { this.renderSubLevel(item) }
                </div>
            );
        }

        return (
            <MenuItem
              activeMenuItemsStack={ activeMenuItemsStack }
              item={ item }
              itemMods={ itemMods }
              onCategoryHover={ onCategoryHover }
              closeMenu={ closeMenu }
              isLink
            />
        );
    }

    renderSubLevel(category) {
        const {
            activeMenuItemsStack,
            closeMenu,
            device
        } = this.props;

        const {
            item_id, children, item_class
        } = category;
        const childrenArray = getSortedItems(Object.values(children));
        const isVisible = activeMenuItemsStack.includes(item_id);
        const subcategoryMods = { type: 'subcategory', firstSub: item_class.includes('MenuGroupName') };

        return (
            <div
              block="Menu"
              elem="SubMenu"
              mods={ { isVisible } }
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="SubMenuWrapper"
                >
                    <div
                      block="Menu"
                      elem="SubMenuContent"
                    >
                        <div
                          block="Menu"
                          elem="ItemList"
                          mods={ { ...subcategoryMods } }
                        >
                            { !device.isMobile && item_class.includes('MenuGroupName') && (
                                <MenuItem
                                  activeMenuItemsStack={ activeMenuItemsStack }
                                  item={ category }
                                  closeMenu={ closeMenu }
                                  isLink={ false }
                                />
                            ) }
                            { childrenArray.map(this.renderSubLevelItems) }
                        </div>
                        { this.renderCmsBlock(category) }
                    </div>

                </div>
            </div>
        );
    }

    renderSubLevelItems = (item, index) => {
        const {
            activeMenuItemsStack,
            closeMenu
        } = this.props;

        const {
            item_id,
            item_class,
            children
        } = item;

        const colors = ['#F4F0EC', '#EAE6E1', '#E3DEDA', '#D1D1D1'];

        const isSubTitle = Object.keys(children)?.length;

        // eslint-disable-next-line no-magic-numbers
        const newColor = darkenColor(colors[colors.length - 1], index * -10);

        // eslint-disable-next-line no-nested-ternary
        const curColor = isSubTitle ? (colors.length <= index ? newColor : colors[index]) : colors[0];

        const isBanner = item_class.includes('Menu-ItemFigure_type_banner');
        const isHideOnDesktop = item_class.includes('Menu-ItemFigure_type_hideOnDesktop');
        const mods = {
            isBanner: !!isBanner,
            isHideOnDesktop: !!isHideOnDesktop,
            isSubTitle: !isSubTitle
        };

        const style = {
            backgroundColor: curColor
        };

        return (
            <div
              block="Menu"
              elem="SubItemWrapper"
              key={ item_id }
              mods={ mods }
              style={ style }
              data-hj-ignore-attributes
            >
                <MenuItem
                  activeMenuItemsStack={ activeMenuItemsStack }
                  item={ item }
                  closeMenu={ closeMenu }
                  isLink
                />
                { this.renderSubLevelItem(item) }
                <div
                  block="Menu"
                  elem="SubItemWrapper"
                  mods={ { backgroundColor: true } }
                  style={ style }
                />
            </div>
        );
    };

    renderSubLevelItem(category) {
        const { children, item_class, item_id } = category;
        const childrenArray = getSortedItems(Object.values(children));

        const { device } = this.props;

        if (!childrenArray.length) {
            return null;
        }

        const isBanner = item_class.includes('Menu-ItemFigure_type_banner');
        const isLogo = item_class.includes('Menu-ItemFigure_type_logo');
        const mods = {
            isBanner: !!isBanner,
            isLogo: !!isLogo
        };

        return (
            <div
              block="Menu"
              elem={ !device.isMobile ? 'SubLevelDesktop' : 'SubLevelMobile' }
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="ItemList"
                  mods={ { ...mods } }
                  data-hj-ignore-attributes
                >
                    { childrenArray.map((item) => this.renderDesktopSubLevelItems(item, mods)) }
                </div>
            </div>
        );
    }

    renderSubMenuDesktopItems = (item) => {
        const { item_id, children } = item;

        if (!Object.keys(children).length) {
            return null;
        }

        const { activeMenuItemsStack, closeMenu } = this.props;
        const isVisible = activeMenuItemsStack.includes(item_id);

        return (
            <div
              block="Menu"
              elem="SubCategoriesWrapper"
              mods={ { isVisible } }
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="SubCategoriesWrapperInner"
                  mods={ { isVisible } }
                >
                    <div
                      block="Menu"
                      elem="SubCategories"
                    >
                        { this.renderSubLevel(item) }
                    </div>
                    { this.renderAdditionalInformation() }
                </div>
                <div
                  block="Menu"
                  elem="Overlay"
                  mods={ { isVisible } }
                  onMouseEnter={ closeMenu }
                />
            </div>
        );
    };

    renderWishlist() {
        const { onWishlistButtonClick, closeMenu } = this.props;

        return (

            <li key="wishlist" block="Menu" elem="Item" mods={ { wishlist: true } }>
                 <button
                   key="wishlist"
                   block="Menu"
                   elem="Button"
                   mix={ { block: 'Menu', elem: 'WishlistButton' } }
                   onClick={ onWishlistButtonClick }
                   onMouseEnter={ closeMenu }
                   aria-label={ __('Wishlist') }
                   tabIndex={ 0 }
                 >
                    <p>{ __('My wishlist') }</p>
                    { this.renderWishlistItemsQty() }
                 </button>

            </li>

        );
    }

    renderWishlistItemsQty() {
        const { wishlistItems } = this.props;

        const wishlistItemsQty = Object.values(wishlistItems).length;

        if (!wishlistItemsQty) {
            return null;
        }

        return (
            <span block="Menu" elem="ItemsCount">
                 <p>{ wishlistItemsQty }</p>
            </span>
        );
    }

    renderFirstLevel = (item) => {
        const { item_id, item_class } = item;

        const isHideOnDesktop = item_class.includes('Menu-ItemFigure_type_hideOnDesktop');
        const isTouchDevice = window.matchMedia('(hover: none) and (pointer: coarse)').matches;

        if (!isTouchDevice && item_class.includes('TouchDeviceOnly')) {
            return null;
        }

        if (isTouchDevice && item_class.includes('DesktopDeviceOnly')) {
            return null;
        }

        return (
            <li
              block="Menu"
              elem="Item"
              key={ item_id }
              mods={ { isHideOnDesktop } }
            >
                { this.renderFirstLevelItems(item) }
            </li>
        );
    };

    renderTopLevel() {
        const { menu, funcMenu = {}, device } = this.props;
        const categoryArray = Object.values(menu);
        const funcMenuArray = Object.values(funcMenu);
        if (!categoryArray.length || !funcMenuArray.length) {
            return null;
        }

        const [{ children, title: mainCategoriesTitle }] = categoryArray;
        const [{ children: funcChildren }] = funcMenuArray;
        const childrenArray = getSortedItems(Object.values(children));
        const funcChildrenArray = getSortedItems(Object.values(funcChildren));
        const concatArray = childrenArray.concat(funcChildrenArray);

        return (
            <>
                <div block="Menu" elem="MainCategories">
                <ScrollContainer hideScrollbars>
                    <ul
                      block="Menu"
                      elem="ItemList"
                      mods={ { type: 'main' } }
                      aria-label={ mainCategoriesTitle }
                      data-hj-ignore-attributes
                    >
                        { childrenArray.map(this.renderFirstLevel) }
                        { !device.isMobile && (
                            <div
                              block="Menu"
                              elem="Item"
                              mods={ { pipe: true } }
                            />
                        ) }
                        { !device.isMobile && funcChildrenArray.map(this.renderFirstLevel) }
                    </ul>
                </ScrollContainer>
                </div>
                <div block="Menu" elem="ExtraCategories">
                    { this.renderSubMenuDesktop(concatArray) }
                    { this.renderExtraMenu(funcChildrenArray) }
                    { this.renderExtraSocialMenu() }
                    { this.renderStoreSwitcher() }
                </div>
            </>
        );
    }
}

export default Menu;
