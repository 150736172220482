import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MenuItemContainer as SourceMenuItemContainer
} from 'SourceComponent/MenuItem/MenuItem.container';

import MenuItem from './MenuItem.component';

/** @namespace Sofacompany/Component/Menu/Container/MenuItemContainer */
export class MenuItemContainer extends SourceMenuItemContainer {
     static propTypes = {
         onCategoryHover: PropTypes.func,
         item: PropTypes.object.isRequired
     };

     static defaultProps = {
         ...this.defaultProps
     };

     containerFunctions = {
         ...this.containerFunctions,
         handleTouchClick: this.handleTouchClick.bind(this)
     };

     __construct(props) {
         super.__construct(props);
     }

     handleTouchClick(e) {
         e.preventDefault();
     }

     render() {
         return (
             <MenuItem
               { ...this.props }
               { ...this.containerFunctions }
             />
         );
     }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemContainer);
