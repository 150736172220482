import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import GoogleTagManager from './GoogleTagManager.component';

/** @namespace Sofacompany/Component/GoogleTagManager/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    gtm: state.ConfigReducer.gtm,
    state
});

/** @namespace Sofacompany/Component/GoogleTagManager/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    dispatch
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoogleTagManager));
