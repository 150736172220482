import PropTypes from 'prop-types';

import {
    TextPlaceholder as SourceTextPlaceholder
} from 'SourceComponent/TextPlaceholder/TextPlaceholder.component';

export class TextPlaceholder extends SourceTextPlaceholder {
    static propTypes = {
        ...SourceTextPlaceholder.propTypes,
        content: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.number,
            PropTypes.object
        ])
    };
}

export default TextPlaceholder;
