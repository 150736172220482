import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import Html from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';

import './Newsletter.style';

/** @namespace Sofacompany/Component/Newsletter/Component/Newsletter */
export class Newsletter extends PureComponent {
    static propTypes = {
        isNewsletterVisible: PropTypes.bool.isRequired,
        newsletterContent: PropTypes.string
    };

    static defaultProps = {
        newsletterContent: null
    };

    renderPlaceHolder() {
        return (
            <>
                <div>
                    <TextPlaceholder
                      mix={ { block: 'Newsletter', elem: 'PlaceholderH2' } }
                      length="custom"
                    />
                </div>
                <TextPlaceholder
                  mix={ { block: 'Newsletter', elem: 'PlaceholderContent' } }
                  length="long"
                />
            </>
        );
    }

    render() {
        const {
            isNewsletterVisible,
            newsletterContent
        } = this.props;

        if (!isNewsletterVisible) {
            return null;
        }

        return (
            <div block="Newsletter">
                <div block="Newsletter" elem="Block">
                    <div className="CmsBlock-Wrapper newsletter-signup-text">
                        { !newsletterContent && this.renderPlaceHolder() }
                        { newsletterContent && <Html content={ newsletterContent } /> }
                    </div>
                </div>
                <div block="Newsletter" elem="Form">
                    <CmsBlock identifier="newsletter-signup-form" />
                </div>
            </div>
        );
    }
}

export default Newsletter;
