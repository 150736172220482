import {
    CmsPageQuery as SourceCmsPageQuery
} from 'SourceQuery/CmsPage.query';
import { Field } from 'Util/Query';

/** @namespace Sofacompany/Query/CmsPage/Query/CmsPageQuery */
export class CmsPageQuery extends SourceCmsPageQuery {
    _getPageFields() {
        return [
            'title',
            'content',
            'page_width',
            'content_heading',
            'meta_title',
            'meta_description',
            'meta_keywords',
            'meta_robots',
            'canonical_url',
            this._getMwHrefLangs()
        ];
    }

    _getMwHrefLangs() {
        const items = new Field('items')
            .addField('url')
            .addField('code');

        const mwHrefLangs = new Field('mw_hreflangs')
            .addField(items);

        return mwHrefLangs;
    }
}

export default new CmsPageQuery();
