export const UPDATEDATA = 'UPDATEDATA';
export const CLEARDATA = 'CLEARDATA';

export const updateCurrentCheckoutInfo = (data) => ({
    type: UPDATEDATA,
    data
});

export const clearCurrentCheckoutInfo = () => ({
    type: CLEARDATA
});
