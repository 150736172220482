export const UPDATECONTENT = 'UPDATECONTENT';
export const CLEARCONTENT = 'CLEARCONTENT';

/** @namespace Sofacompany/Store/CurrentCategory/Reducer/getInitialState */
export const getInitialState = () => ({
    content: {}
});

/** @namespace Sofacompany/Store/CurrentCategoryReducer/Reducer/CurrentCategoryReducer */
export const ContentBlockReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATECONTENT:
        const { content, identifier } = action;
        const { content: curContent } = state;

        curContent[identifier] = content;

        return {
            ...state,
            content: curContent
        };
    case CLEARCONTENT:
        return {
            ...state,
            content: {}
        };

    default:
        return state;
    }
};

export default ContentBlockReducer;
