export const UPDATE_POSTS = 'UPDATE_POSTS';
export const NO_POSTS = 'NO_POSTS';

/**
 * Update posts
 * @param posts
 * @returns {{type: *, posts: *}}
 * @namespace Sofacompany/Store/Posts/Action/updatePosts */
export const updatePosts = (posts) => ({
    type: UPDATE_POSTS,
    posts,
    isLoaded: true
});

/**
 * @param message
 * @returns {{type: *, posts: *}}
 * @namespace Sofacompany/Store/Posts/Action/postsException */
export const postsException = (message) => ({
    type: NO_POSTS,
    message
});
