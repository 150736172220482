export * from 'SourceUtil/Price/Price';
/** @namespace Sofacompany/Util/Price/formatPrice */
export const formatPrice = (price = 0, currency = 'EUR', minimumDigits = 0, local = 'da-dk') => {
    const local_code = local.replace('_', '-');

    // eslint-disable-next-line max-len
    const intlNumber = new Intl.NumberFormat(local_code, {
        style: 'currency',
        currency: currency ?? 'USD',
        currencyDisplay: 'code',
        minimumFractionDigits: minimumDigits,
        maximumFractionDigits: 2
    }).format(price || 0).replace(/\D00(?=\D*$)/, '');

    return intlNumber;
};

/**
 * @namespace Sofacompany/Util/Price/formatCleanPrice
 * @param price
 * @param currency Default 'EUR'
 * @param minimumDigits Default 0
 * @param useGrouping Default false. Boolean value that tells wheter or not to use thousand-separators.
 */
export const formatCleanPrice = (price, currency = 'EUR', minimumDigits = 0, useGrouping = true, local = 'da-dk') => {
    const floatPrice = Number.isNaN(price) ? parseFloat(0.0) : parseFloat(price);
    const local_code = local.replace('_', '-');
    // eslint-disable-next-line max-len
    const intlNumber = new Intl.NumberFormat(local_code,
        {
            currencyDisplay: 'code',
            minimumFractionDigits: minimumDigits,
            useGrouping
        }).format(floatPrice);

    return `${currency} ${intlNumber}`;
};

export const formatCleanPriceIcon = (price, minimumDigits = 0, useGrouping = false) => {
    const langCode = navigator.languages ? navigator.languages[0] : navigator.language;
    const floatPrice = Number.isNaN(price) ? parseFloat(0.0) : parseFloat(price);

    // eslint-disable-next-line max-len
    const intlNumber = new Intl.NumberFormat(langCode, { currencyDisplay: 'code', minimumFractionDigits: minimumDigits, useGrouping }).format(floatPrice);
    return `${intlNumber} ${__('currency')} `;
};

/** @namespace Sofacompany/Util/Price/calcDiscountProcent */
// eslint-disable-next-line no-magic-numbers
export const calcDiscountProcent = (price1 = 1, price2 = 1) => Math.round((100 - ((100 / price1) * price2)));
