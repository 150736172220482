/* eslint-disable import/no-cycle */

import Event, { EVENT_GTM_CHECKOUT_EMAIL } from 'Util/Event';

import BaseEvent from './BaseEvent.event';

/**
 * Product click event
 * @namespace Sofacompany/Component/GoogleTagManager/Events/CheckoutEmail/Event/CheckoutEmailEvent */
export class CheckoutEmailEvent extends BaseEvent {
    eventHandleDelay = 0;

    bindEvent() {
        Event.observer(EVENT_GTM_CHECKOUT_EMAIL, (email) => {
            this.handle(email);
        });
    }

    handler(email) {
        if (email && typeof email === 'string') {
            this.pushEventData({
                checkoutEmail: email
            });
        }
    }
}

export default CheckoutEmailEvent;
