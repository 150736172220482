import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import TrustpilotBlock from './TrustpilotBlock.component';

/** @namespace Sofacompany/Component/TrustpilotBlock/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    buid: state.ConfigReducer.trustpilot_buid,
    locale: state.ConfigReducer.locale
});

/** @namespace Sofacompany/Component/TrustpilotBlock/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Sofacompany/Component/TrustpilotBlock/Container/TrustpilotBlockContainer */
export class TrustpilotBlockContainer extends PureComponent {
    static propTypes = {
        buid: PropTypes.string,
        locale: PropTypes.string
    };

    static defaultProps = {
        buid: '4ec816de00006400051195cd', // THE DANISH BU-ID!
        locale: 'da_DK'
    };

    componentDidMount() {
        const { trustpilotRef } = this.state;
        this.loadTrustpilot(() => {
            const interval = setInterval(() => {
                if (trustpilotRef.current && window.Trustpilot) {
                    clearInterval(interval);
                    window.Trustpilot.loadFromElement(trustpilotRef.current, true);
                }
            }, Number('500'));
        });
    }

    loadTrustpilot = (callback) => {
        const existingScript = document.getElementById('trustpilot-script');
        if (!existingScript) {
            const script = document.createElement('script');
            script.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
            script.id = 'trustpilot-script';
            script.defer = true;
            document.body.appendChild(script);
            script.onload = () => {
                if (callback) {
                    callback();
                }
            };
        }
        if (existingScript && callback) {
            callback();
        }
    };

    __construct(props) {
        super.__construct(props);
        this.state = {
            trustpilotRef: createRef()
        };
    }

    render() {
        return (
            <TrustpilotBlock
              { ...this.props }
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrustpilotBlockContainer);
