/* eslint-disable max-len */
import { lazy } from 'react';

import Loader from 'Component/Loader';
import NoMatch from 'Route/NoMatch';
import {
    UrlRewrites as SourceUrlRewrites
} from 'SourceRoute/UrlRewrites/UrlRewrites.component';

import {
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_NOTFOUND,
    TYPE_PRODUCT
} from './UrlRewrites.config';

export const CategoryPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/CategoryPage'));
export const CmsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/CmsPage'));
export const ABsplit = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "split" */ 'Component/ABsplit'));
export const ProductPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "split" */ 'Route/ProductPage'));
export class UrlRewrites extends SourceUrlRewrites {
    renderDefaultPage() {
        return (
            <main style={ { height: '100vh' } }>
                <Loader isLoading />
            </main>
        );
    }

    renderContent() {
        const { props, type } = this.props;

        switch (type) {
        case TYPE_PRODUCT:
            return <ProductPage { ...props } />;
        case TYPE_CMS_PAGE:
            return <CmsPage { ...props } />;
        case TYPE_CATEGORY:
            return <CategoryPage { ...props } />;
        case TYPE_NOTFOUND:
            return <NoMatch { ...props } />;
        default:
            return this.renderDefaultPage();
        }
    }
}

export default UrlRewrites;
