import {
    StoreItems as SourceStoreItems
} from 'SourceComponent/StoreItems/StoreItems.component';

/** @namespace Sofacompany/Component/StoreItems/Component/StoreItems */
export class StoreItems extends SourceStoreItems {
    render() {
        const {
            item: { label },
            getStoreCode
        } = this.props;

        return (
            <button
              block="StoreItems"
              elem="Item"
              mix={ { block: 'Button', mods: { isHollow: true } } }
              onClick={ getStoreCode }
            >
                { label }
            </button>
        );
    }
}

export default StoreItems;
