export const UPDATECONTENTSPOTS = 'UPDATECONTENTSPOTS';

export const getInitialState = () => ({
    spot: {},
    loaded: false,
    active: false
});

export const ContentSpotReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATECONTENTSPOTS:
        const { spot } = action;
        return {
            ...state,
            spot,
            loaded: true,
            active: !!spot
        };

    default:
        return state;
    }
};

export default ContentSpotReducer;
