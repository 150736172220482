export const categorySwitcher = {
    10: '2 person sofa',
    11: '3 person sofa',
    12: '4 personers sofa',
    13: 'chaiselong sofa',
    14: 'corner sofa',
    77: 'modular sofa',
    246: 'sofa covers',
    152: 'sleeper sofa',
    231: 'velour sofa',
    232: 'U sofa',
    15: 'daybed / bench',
    16: 'armchairs',
    60: 'ottomans and footrests',
    76: 'legs',
    65: 'coffe tables',
    62: 'dining tables',
    75: 'dining Chairs',
    196: 'headboards',
    59: 'pillows',
    57: 'rugs',
    236: 'plaider',
    61: 'furniture care',
    165: 'giftcard',
    201: 'pendant',
    202: 'table lamp',
    203: 'wall lamp',
    204: 'ceiling lamp',
    116: 'news',
    74: 'furniture'
};
