import {
    FETCH_CONNECTED_TERMINALS,
    FETCH_CONNECTED_TERMINALS_FAILURE,
    FETCH_CONNECTED_TERMINALS_SUCCESS
} from './AdyenPosCloud.action';

export const getInitialState = () => ({
    terminals: [],
    isLoading: false
});

export const AdyenPosCloudReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;
    switch (type) {
    case FETCH_CONNECTED_TERMINALS:
        return {
            ...state,
            isLoading: true
        };
    case FETCH_CONNECTED_TERMINALS_FAILURE:
        const { error } = action;
        return {
            ...state,
            isLoading: false,
            error
        };
    case FETCH_CONNECTED_TERMINALS_SUCCESS:
        const { terminals } = action;
        return {
            ...state,
            isLoading: false,
            error: null,
            terminals
        };
    default:
        return state;
    }
};

export default AdyenPosCloudReducer;
