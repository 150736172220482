export const UPDATECITY = 'UPDATECITY';

/** @namespace Sofacompany/Store/CurrentPage/Reducer/getInitialState */
export const getInitialState = () => ({
    city: '',
    cittyB: ''
});

/** @namespace Sofacompany/Store/Wishlist/Reducer/WishlistReducer */
export const CurrentCityReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATECITY:
        const { city, isBilling } = action;

        if (isBilling) {
            return {
                ...state,
                cityB: city
            };
        }

        return {
            ...state,
            city
        };

    default:
        return state;
    }
};

export default CurrentCityReducer;
