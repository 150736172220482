export const FETCH_SHOWROOMS = 'FETCH_SHOWROOMS';
export const FETCH_SHOWROOMS_FAILURE = 'FETCH_SHOWROOMS_FAILURE';
export const FETCH_SHOWROOMS_SUCCESS = 'FETCH_SHOWROOMS_SUCCESS';
export const UPDATE_ACTIVE_SHOWROOM = 'UPDATE_ACTIVE_SHOWROOM';

export const fetchShowrooms = () => ({
    type: FETCH_SHOWROOMS,
    loading: true
});

export const fetchShowroomsFailure = (error) => ({
    type: FETCH_SHOWROOMS_FAILURE,
    error
});
export const fetchShowroomsSuccess = (showrooms) => ({
    type: FETCH_SHOWROOMS_SUCCESS,
    showrooms
});

export const updateActiveShowroom = (activeShowroom) => ({
    type: UPDATE_ACTIVE_SHOWROOM,
    activeShowroom
});
