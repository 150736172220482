/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import AddToCart from 'Component/AddToCart';
import Html from 'Component/Html';
import Icon from 'Component/Icon';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import {
    PRODUCT_STOCK_NUMBER
} from 'Component/ProductActions/ProductActions.config';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import ProductBadges from 'Component/ProductBadges';
import ProductPrice from 'Component/ProductPrice';
import ProductWishlistButton from 'Component/ProductWishlistButton';
import TextPlaceholder from 'Component/TextPlaceholder';
import { CYLINDO_ANGLED_IMAGE } from 'Route/ProductPage/ProductPage.config';
import {
    ProductCard as SourceProductCard
} from 'SourceComponent/ProductCard/ProductCard.component';
import { GIFTCARD } from 'Store/GiftCard/GiftCard.dispatcher';
import Event, { EVENT_GTM_KLEVU_SEARCH, EVENT_GTM_PRODUCT_CLICK } from 'Util/Event';
import { findBadges } from 'Util/Global';
import isMobile from 'Util/Mobile';
import { SIMPLE } from 'Util/Product';
import { appendWithStoreCode } from 'Util/Url';

import {
    IMAGE_SIZE,
    IMAGE_SIZE_XS,
    PRODUCTCARD_MAX_AVAILABLEPICTURES,
    SWATCH_IMAGE_SIZE
} from './ProductCard.config';

/** @namespace Sofacompany/Component/ProductCard/Component/ProductCard */
export class ProductCard extends SourceProductCard {
    static propTypes = {
        ...SourceProductCard.propTypes,
        onActiveImageChange: PropTypes.func.isRequired,
        activeImage: PropTypes.number.isRequired,
        showAddToCard: PropTypes.bool,
        storeCode: PropTypes.string.isRequired
    };

    static defaultProps = {
        ...SourceProductCard.defaultProps,
        showAddToCard: false
    };

    handleClick = () => {
        const {
            currentVariantIndex: configurableVariantIndex,
            selectedFilters,
            product,
            clickDataLayerEvent,
            category,
            categoryPath,
            currentPageId,
            currentPageName,
            currentPageAppender
        } = this.props;

        const isSearchPage = window.location.pathname.includes('/search');

        const productToPass = Object.keys(selectedFilters).length
            ? { ...product, configurableVariantIndex }
            : product;
        const currentPage = {
            currentPageId,
            currentPageName,
            currentPageAppender
        };

        productToPass.category = category;
        productToPass.list = currentPage;
        productToPass.catPath = categoryPath;

        Event.dispatch(EVENT_GTM_PRODUCT_CLICK, productToPass);

        if (isSearchPage) {
            const { searchTimeout } = window;

            if (clickDataLayerEvent && searchTimeout) {
                clearTimeout(searchTimeout);

                Event.dispatch(EVENT_GTM_KLEVU_SEARCH, window.location.pathname?.split('/search')[1].replace('/', ''));
            }
        }

        this.registerSharedElement();
    };

    renderWishlistButton() {
        const {
            product,
            quantity,
            configurableVariantIndex,
            onProductValidationError
        } = this.props;

        if (product && !product.sku) {
            return null;
        }

        return (
            <ProductWishlistButton
              mix={ { block: 'ProductCard', elem: 'WishlistButton' } }
              product={ product }
              quantity={ quantity }
              configurableVariantIndex={ configurableVariantIndex }
              onProductValidationError={ onProductValidationError }
            />
        );
    }

    renderAdditionalProductDetails() {
        const { product: { sku }, getAttribute } = this.props;
        const { product_list_content: { attribute_to_display } = {} } = window.contentConfiguration;
        const brand = getAttribute(attribute_to_display || 'brand') || {};

        if (sku && !brand) {
            return null;
        }

        if (Object.keys(brand).length <= 0) {
            return null;
        }

        return (
            <div
              block="ProductCard"
              elem="Brand"
              mods={ { isLoaded: !!brand } }
            >
                <ProductAttributeValue
                  attribute={ brand }
                  isFormattedAsText
                />
            </div>
        );
    }

    renderCrumbs(gallery) {
        const gallerySplitted = gallery.slice(0, PRODUCTCARD_MAX_AVAILABLEPICTURES);

        return (
            <div
              block="ProductCard"
              elem="Crumbs"
            >
                { gallerySplitted.map((_, i) => this.renderCrumb(i)) }
            </div>
        );
    }

    renderCrumb(i) {
        const { activeImage } = this.props;
        const isActive = i === Math.abs(-activeImage);

        return (
            <button
              block="ProductCard"
              elem="Crumb"
              key={ i }
              mods={ { isActive } }
            >
                { i }
            </button>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    resize(image, size = IMAGE_SIZE) {
        const imageArray = image?.split('?');
        return `${imageArray[0]}${size}`;
    }

    renderImage() {
        const {
            product: {
                name, small_image, type_2, cylindo_angled_image, media_gallery_entries
            }
        } = this.props;

        // eslint-disable-next-line fp/no-let
        let media_image;
        if (media_gallery_entries) {
            media_image = media_gallery_entries.find((x) => x.types.includes('thumbnail'));
        }

        // eslint-disable-next-line fp/no-let
        let firstImage = small_image;
        // eslint-disable-next-line fp/no-let
        let secondImage = type_2;

        if (cylindo_angled_image && cylindo_angled_image.path) {
            firstImage = cylindo_angled_image;
            secondImage = small_image;
        } else {
            firstImage = small_image || media_image?.thumbnail;
            secondImage = type_2;
        }

        if (isMobile.any()) {
            return (
                <div block="ProductCard">
                    <Image
                      src={ firstImage && this.resize(firstImage.url, IMAGE_SIZE_XS) }
                      alt={ name }
                      ratio="custom"
                      minWidth="164px"
                      minHeight="82px"
                      mix={ { block: 'ProductCard', elem: 'Picture' } }
                      isPlaceholder={ !firstImage }
                    />
                </div>
            );
        }

        return (
            <>
                <div block="ProductCard" elem="PictureHover">
                    <Image
                      src={ firstImage && this.resize(firstImage.url) }
                      alt={ name }
                      ratio="custom"
                      minWidth="295px"
                      minHeight="147px"
                      mix={ { block: 'ProductCard', elem: 'Picture' } }
                      isPlaceholder={ !firstImage }
                    />
                </div>

                <div block="ProductCard" elem="PictureHover">
                    <Image
                      src={ secondImage && this.resize(secondImage.url) }
                      alt={ name }
                      minWidth="295px"
                      minHeight="147px"
                      ratio="custom"
                      mix={ { block: 'ProductCard', elem: 'Picture' } }
                      isPlaceholder={ !secondImage }
                    />
                </div>
            </>
        );
    }

    renderPicture() {
        const {
            product: { name, thumbnail, type_2 }
        } = this.props;

        return (
           <div
             block="ProductCard"
             elem={ isMobile.any() ? 'PictureSingle' : 'PictureHoverWrapper' }
             mods={ { singleImage: (!(type_2 && type_2.path)) } }
           >
            { this.renderImage() }
           </div>
        );
    }

    renderProductPrice() {
        const {
            product: {
                price_range,
                type_id,
                stock_item,
                sku
            },
            displayQtyIncrementText = true
        } = this.props;

        if (!price_range) {
            return <TextPlaceholder />;
        }

        if (type_id === GIFTCARD || sku === '0040') {
            const {
                productOrVariant: {
                    allow_open_amount,
                    open_amount_min,
                    open_amount_max,
                    giftcard_amounts,
                    price_range
                }
            } = this.props;

            if (allow_open_amount) {
                const price = { price_min: open_amount_min, price_max: open_amount_max, currency: price_range };

                return (
                    <ProductPrice
                      price={ price }
                      isGiftCard
                      mix={ { block: 'ProductCard', elem: 'Price' } }
                      stock_item={ stock_item }
                      displayQtyIncrementText={ displayQtyIncrementText }
                    />
                );
            }

            const price = {
                price_min: giftcard_amounts[0]?.value,
                price_max: giftcard_amounts[giftcard_amounts.length - 1]?.value
            };

            return (
                <ProductPrice
                  price={ price }
                  isGiftCard
                  mix={ { block: 'ProductCard', elem: 'Price' } }
                  stock_item={ stock_item }
                  displayQtyIncrementText={ displayQtyIncrementText }
                />
            );
        }

        return (
            <ProductPrice
              price={ price_range }
              mix={ { block: 'ProductCard', elem: 'Price' } }
              stock_item={ stock_item }
              displayQtyIncrementText={ displayQtyIncrementText }
            />
        );
    }

    renderVisualConfigurableOptions() {
        const { product } = this.props;

        if (!product?.sofa_variants) {
            product.sofa_variants = {};
        }
        const { sofa_variants: { items = [] } = {} } = product;
        const colorsArray = items.map((item) => ({
            sku: item.sku,
            active: item.sku === product.sku,
            url: item.materials.find((x) => x.type === 'FABRIC_MM')?.material_image
            ?? item.media_gallery.find((x) => x.label === 'fabric_code_image')?.url ?? item.media_gallery?.[0].url
        })).sort((a, b) => ((a.active && !b.active) ? -1 : 1));

        // take first 3 items and count rest
        const maxColors = 3;
        const rest = colorsArray.length - maxColors;
        const colors = colorsArray.slice(0, maxColors);

        return (
            <div block="ProductCard" elem="ConfigurableOptions" mods={ { hasRest: rest > 0 } }>
                { colors.map(({ url, active }, index) => (
                    <div
                      key={ index }
                      block="ProductCard"
                      elem="ColorDiv"
                      mods={ { active: !!active } }
                    >
                        <img
                          block="ProductCard"
                          elem="Color"
                          loading="lazy"
                          alt="Fabric color"
                          width={ 18 }
                          height={ 18 }
                          src={ this.resize(url, SWATCH_IMAGE_SIZE) }
                        />
                    </div>
                )) }
                { rest > 0 && (
                    <div
                      block="ProductCard"
                      elem="ColorDiv"
                      mods={ { rest: true } }
                    >
                        +
                    </div>
                ) }
            </div>
        );
    }

    renderName() {
        const {
            product: {
                sofa_original_name,
                name,
                name2,
                sofa_fabric_name_label,
                type_id,
                sku
            }
        } = this.props;

        const isGiftCard = type_id === GIFTCARD || sku === '0040';

        if (isGiftCard) {
            return (
                <div block="ProductCard" elem="NameWrapper">
                    <p block="ProductCard" elem="Name">{ name }</p>
                </div>
            );
        }

        if (!sofa_original_name || (!name && !name2)) {
            return null;
        }

        const fabric = name2 || sofa_fabric_name_label;
        const descriptionWithoutName = sofa_original_name?.split(', ')?.slice(1)?.join(', ') ?? sofa_original_name;
        const name3 = fabric !== undefined && fabric ? `${fabric?.split(', ')?.[0]}` : '';

        return (
            <div block="ProductCard" elem="NameWrapper">
            <p block="ProductCard" elem="Name">{ `${name }` }</p>
            <p block="ProductCard" elem="Name2">{ descriptionWithoutName }</p>
            <p block="ProductCard" elem="Name3">{ name3 }</p>
            </div>
        );
    }

    renderStock(stockStatus, qty) {
        // eslint-disable-next-line fp/no-let
        let stockColor = '';

        if (qty <= PRODUCT_STOCK_NUMBER && qty > 0) {
            stockColor = 'ALMOST_OUT_OF_STOCK';
        }

        if (qty <= 0) {
            stockColor = 'OUT_OF_STOCK';
            stockStatus = 'OUT_OF_STOCK';
        }

        if (!qty) {
            return null;
        }

        return (
            <div block="ProductCard" elem="StockWrapper">
                <div block="ProductCard" elem="Stock">
                    <i block="ProductCard" elem="StockCircle" mods={ { inStock: stockColor } } />
                    <i>
                        { qty > PRODUCT_STOCK_NUMBER ? `+${PRODUCT_STOCK_NUMBER}` : qty }
                    </i>
                    { __('in stock') }
                </div>
            </div>
        );
    }

    renderMainDetails() {
        return (
            <div block="ProductCard" elem="MainDetails">
                { this.renderName() }
            </div>
        );
    }

    renderCmsProduct() {
        const {
            isLoading,
            product: {
                name,
                small_image,
                short_description
            }
        } = this.props;

        const style = {
            backgroundImage: `url('${ small_image && small_image.url }')`
        };

        return (
            <li
              block="ProductCard"
              elem="CmsProduct"
              style={ style }
            >
                <Loader isLoading={ isLoading } />
                <div block="ProductCard" elem="Box">
                    <p
                      block="ProductCard"
                      elem="Name"
                      mods={ { isLoaded: !!name } }
                    >
                        <TextPlaceholder content={ name } length="medium" />
                    </p>
                    { short_description && <Html content={ short_description.html } /> }
                </div>
            </li>
        );
    }

    renderAddToCardButton() {
        const {
            product,
            product: {
                type_id,
                sku,
                stock_item: {
                    enable_qty_increments,
                    qty_increments
                } = {}
            },
            category,
            searchPage
        } = this.props;

        if ((type_id !== SIMPLE && !searchPage) || (sku === '0040' && searchPage)) {
            return null;
        }

        return (
            <AddToCart
              product={ product }
              quantity={ enable_qty_increments ? qty_increments : 1 }
              icon
              isWhite
              mix={ { block: 'ProductCard', elem: 'AddToCart', isHollow: true } }
              category={ category }
              isPowerStep
            />
        );
    }

    renderCardWrapper(children) {
        const { linkTo, product: { url } } = this.props;

        if (!url) {
            return (<div>{ children }</div>);
        }

        return (
            <Link
              block="ProductCard"
              elem="Link"
              to={ linkTo }
              onClick={ this.handleClick }
            >
              { children }
            </Link>
        );
    }

    renderBadges() {
        // eslint-disable-next-line no-unused-vars
        const { product } = this.props;

        const badges = findBadges(product);

        return <ProductBadges badges={ badges } type="category" />;
    }

    renderDeliveryPrice() {
        const { delivery_price_url } = this.props;
        return (
            <div
              block="ProductCard"
              elem="DeliveryPrice"
            >
                <Link to={ appendWithStoreCode(delivery_price_url) }>{ __('+ Delivery price') }</Link>
            </div>
        );
    }

    renderEcoPart() {
        const { product: { sofa_ecomaison_fee }, storeCode } = this.props;

        if (storeCode !== 'fr' || typeof sofa_ecomaison_fee === 'undefined') {
            return null;
        }

        return (
            <div
              block="ProductCard"
              elem="EcoPart"
            >
                { __('Ecomaison fee %s€', sofa_ecomaison_fee) }
            </div>
        );
    }

    render() {
        const {
            mix,
            isLoading,
            showAddToCard,
            product: {
                cms_product = null,
                sofa_variants,
                stock_status,
                stock_item: {
                    qty
                } = {}

            } = {}
        } = this.props;

        if (cms_product === 1) {
            return this.renderCmsProduct();
        }

        return (
            <li
              block="ProductCard"
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                <div block="ProductCard" elem="Box">
                    { this.renderCardWrapper((
                        <>
                            { this.renderAdditionalProductDetails() }
                            <figure block="ProductCard" elem="Figure">
                                { this.renderPicture() }
                                { sofa_variants && this.renderVisualConfigurableOptions() }
                                { this.renderBadges() }
                            </figure>
                            <div block="ProductCard" elem="ContentWrapper">
                                <div block="ProductCard" elem="Content">
                                    { this.renderMainDetails() }
                                </div>

                                <div block="ProductCard" elem="PriceWrapper">
                                    { this.renderTierPrice() }
                                    { this.renderProductPrice() }
                                    { this.renderDeliveryPrice() }
                                    { this.renderEcoPart() }
                                </div>
                            </div>
                        </>
                    )) }
                    { (window.location.pathname.includes('cart') || showAddToCard) && this.renderAddToCardButton() }
                </div>
            </li>
        );
    }
}

export default ProductCard;
