export const FABRICSCROLLED = 'FABRICSCROLLED';

export const getInitialState = () => ({
    fabricScrolled: false
});

export const FabricScrolledReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case FABRICSCROLLED:
        const { fabricScrolled } = action;
        return {
            ...state,
            fabricScrolled
        };

    default:
        return state;
    }
};

export default FabricScrolledReducer;
