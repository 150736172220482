/* eslint-disable new-cap */
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductWishlistButtonContainer as SourceProductWishlistButtonContainer,
    WishlistDispatcher as SourceWishlistDispatcher
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';

export const WishlistDispatcher = SourceWishlistDispatcher;

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Sofacompany/Component/ProductWishlistButton/Container/ProductWishlistButtonContainer */
export class ProductWishlistButtonContainer extends SourceProductWishlistButtonContainer {
    isInWishlist = () => {
        const { productsInWishlist, product } = this.props;

        const { sku: productSku } = product;
        return Object.values(productsInWishlist).findIndex(({ wishlist: { sku } }) => sku === productSku) >= 0;
    };

    toggleProductInWishlist = (add = true) => {
        const {
            product,
            product: { sku, description, id },
            quantity,
            isLoading,
            productsInWishlist,
            addProductToWishlist,
            removeProductFromWishlist
        } = this.props;

        if (isLoading) {
            return null;
        }

        if (add) {
            return addProductToWishlist({
                [id]: {
                    ...product,
                    quantity,
                    wishlist: {
                        id,
                        sku,
                        quantity,
                        description
                    }
                }
            });
        }

        const { wishlist: { id: item_id } } = Object.values(productsInWishlist).find(
            ({ wishlist: { sku: wishlistSku } }) => wishlistSku === sku
        );

        return removeProductFromWishlist({ item_id, sku });
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlistButtonContainer);
