// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ContentsQuery from 'Query/Contents.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchQuery } from 'Util/Request';

import SocialFooterItems from './SocialFooterItems.component';

export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

export class SocialFooterItemsContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    componentDidMount() {
        const query = ContentsQuery.getQuery('Social Footer Items');

        fetchQuery(query).then(
            ({ contents }) => {
                if (contents[0]?.custom_attributes?.length) {
                    this.setState({ data: contents[0].custom_attributes[0] });
                }
            },
            (error) => {
                this.setState({
                    isLoading: false
                });
                showNotification('error', error[0].message);
            }
        );
    }

    render() {
        return (
            <SocialFooterItems
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialFooterItemsContainer);
