import Html from 'Component/Html';
import {
    CmsBlock as SourceCmsBlock
} from 'SourceComponent/CmsBlock/CmsBlock.component';

/**
 * Cms Block
 * @class CmsBlock
 * @namespace Component/CmsBlock/Component
 */
import './CmsBlock.style';

export class CmsBlock extends SourceCmsBlock {
    render() {
        const {
            cmsBlock: {
                identifier,
                content,
                disabled
            }
        } = this.props;

        if (disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        return (
            <div
              block="CmsBlock"
              elem={ `Wrapper ${identifier}` }
            >
                <Html content={ content } />
            </div>
        );
    }
}

export default CmsBlock;
