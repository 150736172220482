export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const UPDATE_DEFAULT_PRODUCTS = 'UPDATE_DEFAULT_PRODUCTS';
export const UPDATE_LOADER = 'UPDATE_LOADER';
export const UPDATE_SELECTED_CATEGORY = 'UPDATE_SELECTED_CATEGORY';
export const UPDATE_SELECTED_PRODUCT = 'UPDATE_SELECTED_PRODUCT';
export const UPDATE_STUDIO_IMAGES = 'UPDATE_STUDIO_IMAGES';
export const UPDATE_META = 'UPDATE_META';
export const UPDATE_DEFAULT_PRODUCT = 'UPDATE_DEFAULT_PRODUCT';

/** @namespace Sofacompany/Store/DesignYourOwn/Action/updateCategories */
export const updateCategories = (categories) => ({
    type: UPDATE_CATEGORIES,
    categories
});

/** @namespace Sofacompany/Store/DesignYourOwn/Action/updateProducts */
export const updateProducts = (products) => ({
    type: UPDATE_PRODUCTS,
    products
});

export const updateDefaultProducts = (defaultProducts) => ({
    type: UPDATE_DEFAULT_PRODUCTS,
    defaultProducts
});

export const updateDefaultProduct = (defaultProduct) => ({
    type: UPDATE_DEFAULT_PRODUCT,
    defaultProduct
});

/** @namespace Sofacompany/Store/DesignYourOwn/Action/updateCategory */
export const updateCategory = (selectedCategory) => ({
    type: UPDATE_SELECTED_CATEGORY,
    selectedCategory
});

/** @namespace Sofacompany/Store/DesignYourOwn/Action/updateProduct */
export const updateProduct = (selectedProduct) => ({
    type: UPDATE_SELECTED_PRODUCT,
    selectedProduct
});

export const updateStudioImages = (studioImages, studioImagesSku) => ({
    type: UPDATE_STUDIO_IMAGES,
    studioImages,
    studioImagesSku
});

/** @namespace Sofacompany/Store/DesignYourOwn/Action/updateLoader */
export const updateLoader = (isLoading) => ({
    type: UPDATE_LOADER,
    isLoading
});

/** @namespace Sofacompany/Store/DesignYourOwn/Action/updateMeta */
export const updateMeta = (meta) => ({
    type: UPDATE_META,
    meta
});
