import PropTypes from 'prop-types';

import ClickOutside from 'Component/ClickOutside';
import Icon from 'Component/Icon';
import {
    Popup as SourcePopup
} from 'SourceComponent/Popup/Popup.component';

/** @namespace Sofacompany/Component/Popup/Component/Popup */
export class Popup extends SourcePopup {
    static propTypes = {
        ...SourcePopup.propTypes,
        title: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ])
    };

    // Same with click outside
    handleClickOutside = () => {
        const {
            clickOutside, innerContainerClicked, innerContainerHover, whenUp
        } = this.props;

        if (!clickOutside) {
            return;
        }

        if (innerContainerClicked) {
            whenUp();
        } else if (innerContainerHover) {
            whenUp();
        } else {
            this.hidePopUp();
        }
    };

    handleDrag = (e) => {
        e.stopPropagation();
    };

    renderTitle() {
        const { title } = this.props;

        return (
            <h2 block="Popup" elem="Heading">
                { title }
            </h2>
        );
    }

    renderClose() {
        return (
            <button
              block="Popup"
              elem="CloseBtn"
              aria-label={ __('Close') }
              onClick={ this.hidePopUp }
            >
              <Icon icon="close" />
            </button>
        );
    }

    renderContent() {
        const {
            children,
            whenDown,
            whenUp,
            whenEnter,
            whenLeave,
            title,
            small,
            isSticky
        } = this.props;

        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return (
        // eslint-disable-next-line max-len
        <ClickOutside onClick={ this.handleClickOutside } onDrag={ this.handleDrag }>
            { /* eslint-disable-next-line max-len */ }
            <div block="Popup" elem="Content" mods={ { small: small || !title } } onMouseEnter={ whenEnter } onMouseLeave={ whenLeave } onMouseDown={ whenDown } onMouseUp={ whenUp }>
                { isSticky && this.renderClose() }
                <header block="Popup" elem="Header" mods={ { isSticky } }>
                    { title && this.renderTitle() }
                    { !isSticky && this.renderClose() }
                </header>
                { children }
            </div>
        </ClickOutside>
        );
    }
}

export default Popup;
