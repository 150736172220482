import { connect } from 'react-redux';

import {
    MENU_SUBCATEGORY,
    WISHLIST
} from 'Component/Header/Header.config';
import MenuQuery from 'Query/Menu.query';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MenuContainer as SourceMenuContainer
} from 'SourceComponent/Menu/Menu.container';
import history from 'Util/History';
import MenuHelper from 'Util/Menu';
import {
    appendWithStoreCode
} from 'Util/Url';

export {
    mapDispatchToProps
};

export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Sofacompany/Component/Menu/Container/MenuContainer */
export class MenuContainer extends SourceMenuContainer {
    state = {
        ...this.state,
        menu: {},
        funcMenu: {}
    };

    containerFunctions = {
        ...this.containerFunctions,
        onWishlistButtonClick: this.onWishlistButtonClick.bind(this)

    };

    componentDidMount() {
        this._getMenu();
        this._getExtraMenu();
    }

    _getMenuOptions() {
        const { header_content: { header_menu } = {} } = window.contentConfiguration;

        return {
            identifier: header_menu || 'main-nav-menu-v2'
        };
    }

    _getExtraMenu() {
        const { menu = 'secondary-menu' } = this.props;

        this.fetchData(
            [MenuQuery.getQuery({
                identifier: menu
            })],
            ({ menu }) => this.setState({
                funcMenu: MenuHelper.reduce(menu)
            })
        );
    }

    onWishlistButtonClick() {
        history.push(appendWithStoreCode(`/${ WISHLIST }`));
    }

    handleSubcategoryClick(e, activeSubcategory) {
        const { activeMenuItemsStack } = this.state;
        const { changeHeaderState, goToPreviousHeaderState } = this.props;
        const { item_id, title } = activeSubcategory;
        e.stopPropagation();

        changeHeaderState({
            name: MENU_SUBCATEGORY,
            force: true,
            title,
            onBackClick: () => {
                this.setState(({ activeMenuItemsStack }) => (
                    { activeMenuItemsStack: activeMenuItemsStack.slice(1) }
                ));
                goToPreviousHeaderState();
            }
        });

        if (!activeMenuItemsStack.includes(item_id)) {
            this.setState({ activeMenuItemsStack: [item_id, ...activeMenuItemsStack] });
        } else if (activeMenuItemsStack.includes(item_id)) {
            activeMenuItemsStack.map((currElement, index) => {
                if (currElement === item_id) {
                    this.setState({ activeMenuItemsStack: activeMenuItemsStack.slice(0, index) });
                }
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
