import { connect } from 'react-redux';
import { Subscribe } from 'unstated';

import { AMOUNT_OF_PLACEHOLDERS, IMAGE_TYPE, THUMBNAIL_KEY } from 'Component/ProductGallery/ProductGallery.config';
import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import {
    CartDispatcher,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { convertToPath } from 'Util/CategoryPath/converter';
import history from 'Util/History';
import { appendWithStoreCode, objectToUri } from 'Util/Url';

import ProductCardList from './ProductCardList.component';

export {
    CartDispatcher,
    mapDispatchToProps
};

/** @namespace Sofacompany/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    navigationState: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState,
    baseLinkUrl: state.ConfigReducer.base_link_url,
    clickDataLayerEvent: state.SearchBarReducer.clickDataLayerEvent,
    currentPageId: state.CurrentPageReducer.pageId,
    currentPageName: state.CurrentPageReducer.pageName,
    currentPageAppender: state.CurrentPageReducer.appender
});

/** @namespace Sofacompany/Component/ProductCard/Container/ProductCardContainer */
export class ProductCardListContainer extends SourceProductCardContainer {
    state = {
        ...this.state,
        activeImage: 0
    };

    containerFunctions = {
        ...this.containerFunctions,
        getProductGallery: this.getProductGallery.bind(this),
        onActiveImageChange: this.onActiveImageChange.bind(this)
    };

    containerProps = () => ({
        availableVisualOptions: this._getAvailableVisualOptions(),
        currentVariantIndex: this._getCurrentVariantIndex(),
        productOrVariant: this._getProductOrVariant(),
        thumbnail: this._getThumbnail(),
        linkTo: this._getLinkTo(),
        category: this._getCategory(),
        categoryPath: this._getCategoryPath()
    });

    onActiveImageChange(activeImage) {
        this.setState({
            activeImage
        });
    }

    _getLinkTo() {
        const {
            baseLinkUrl,
            product: { url, url_rewrites, canonical_url },
            product
        } = this.props;

        if (!url) {
            return undefined;
        }

        const { parameters } = this._getConfigurableParameters();

        if (url_rewrites) {
            // eslint-disable-next-line fp/no-let
            let redirectUrl;

            url_rewrites.map((url_rewrite) => {
                const { url } = url_rewrite;
                const { location: { pathname } } = history;

                // substring 7 = e.g. /da-dk/
                const pathnameWithoutLocale = pathname.substring(Number('7'));

                // check if include pathname and if same length of '/'
                if (url.includes(pathnameWithoutLocale)
                    && (pathnameWithoutLocale.split('/').length + 1 === url.split('/').length)
                ) {
                    redirectUrl = url;
                }
            });

            if (redirectUrl) {
                return {
                    pathname: appendWithStoreCode(`/${redirectUrl}`),
                    state: { product },
                    search: objectToUri(parameters)
                };
            }
        }

        if (canonical_url) {
            return {
                pathname: appendWithStoreCode(`/${canonical_url.replace(baseLinkUrl, '')}`),
                state: { product },
                search: objectToUri(parameters)
            };
        }

        return {
            pathname: url,
            state: { product },
            search: objectToUri(parameters)
        };
    }

    _getCategoryPath() {
        const {
            product: {
                url_key,
                categories,
                canonical_url
            }
        } = this.props;

        if (categories?.length) {
            return convertToPath({ url: canonical_url, url_key, categories });
        }

        return null;
    }

    _getCategory() {
        const linkTo = this._getLinkTo();

        if (!linkTo?.pathname) {
            return null;
        }

        const splitUrl = linkTo.pathname.split('/');

        splitUrl.splice(0, 2);

        splitUrl.pop();

        const category = splitUrl.join('/');

        return category;
    }

    getProductGallery() {
        const {
            areDetailsLoaded,
            product: {
                media_gallery_entries: mediaGallery = [],
                [THUMBNAIL_KEY]: { url } = {},
                name
            }
        } = this.props;

        if (mediaGallery.length) {
            return Object.values(mediaGallery.reduce((acc, srcMedia) => {
                const {
                    types,
                    position,
                    disabled
                } = srcMedia;

                const canBeShown = !disabled;
                if (!canBeShown) {
                    return acc;
                }

                const isThumbnail = types.includes(THUMBNAIL_KEY);
                const key = isThumbnail ? 0 : position + 1;

                return {
                    ...acc,
                    [key]: srcMedia
                };
            }, {}));
        }

        if (!url) {
            return Array(AMOUNT_OF_PLACEHOLDERS + 1).fill({ media_type: 'placeholder' });
        }

        const placeholders = !areDetailsLoaded
            ? Array(AMOUNT_OF_PLACEHOLDERS).fill({ media_type: 'placeholder' }) : [];

        return [
            {
                thumbnail: { url },
                base: { url },
                id: THUMBNAIL_KEY,
                label: name,
                media_type: IMAGE_TYPE
            },
            ...placeholders
        ];
    }

    _getAvailableVisualOptions() {
        const { product: { configurable_options = [] } } = this.props;

        return Object.values(configurable_options).reduce((acc, { values = {}, attribute_values }) => {
            const visualOptions = Object.values(values).reduce(
                (acc, option) => {
                    const {
                        swatch_data,
                        label,
                        value_index: attrValue
                    } = option;

                    const { __typename, value } = swatch_data || {};

                    if (__typename === 'ColorSwatchData' && attribute_values.includes(String(attrValue))) {
                        acc.push({ value, label });
                    }

                    return acc;
                }, []
            );

            if (visualOptions.length > 0) {
                return [...acc, ...visualOptions];
            }

            return acc;
        }, []);
    }

    render() {
        return (
            <Subscribe to={ [SharedTransitionContainer] }>
                { ({ registerSharedElement }) => (
                    <ProductCardList
                      { ...{ ...this.props, registerSharedElement } }
                      { ...this.containerFunctions }
                      { ...this.containerProps() }
                      { ...this.state }
                    />
                ) }
            </Subscribe>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardListContainer);
