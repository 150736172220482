import PropTypes from 'prop-types';
import {
    formatCleanPrice,
    formatCleanPriceIcon
} from 'src/util/Price';

import {
    ProductPriceContainer as SourceProductPriceContainer
} from 'SourceComponent/ProductPrice/ProductPrice.container';
import {
    calculateFinalPrice,
    formatPrice,
    roundPrice
} from 'Util/Price';

/** @namespace Sofacompany/Component/ProductPrice/Container/ProductPriceContainer */
export class ProductPriceContainer extends SourceProductPriceContainer {
    static propTypes = {
        ...SourceProductPriceContainer.propTypes,
        isGiftCard: PropTypes.bool,
        quantity: PropTypes.number
    };

    static defaultProps = {
        quantity: 1,
        isGiftCard: false
    };

    containerProps = () => {
        const {
            quantity,
            isGiftCard,
            row_total_incl_tax: rt,
            price: {
                minimum_price: {
                    discount: {
                        percent_off: discountPercentage
                    } = {},
                    final_price: {
                        value: minimalPriceValue,
                        currency: priceCurrency
                    } = {},
                    regular_price: {
                        value: regularPriceValue
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!minimalPriceValue || !regularPriceValue) {
            return {};
        }

        const discount = rt ? 1 : discountPercentage;

        const price = rt ? (rt / quantity) : minimalPriceValue;
        const roundedRegularPrice = formatPrice(roundPrice(regularPriceValue) * quantity, priceCurrency);
        const finalPrice = calculateFinalPrice(discount, price, regularPriceValue) * quantity;
        const formattedFinalPrice = formatPrice(finalPrice, priceCurrency);
        const formattedFinalCleanPrice = formatCleanPrice(finalPrice, priceCurrency);
        const formattedCleanPriceIcon = formatCleanPriceIcon(finalPrice);
        const roundedRegularCleanPrice = formatCleanPrice((roundPrice(regularPriceValue) * quantity), priceCurrency);

        return {
            roundedRegularPrice,
            roundedRegularCleanPrice,
            priceCurrency,
            discountPercentage,
            formattedFinalPrice,
            formattedCleanPriceIcon,
            formattedFinalCleanPrice,
            isGiftCard
        };
    };
}

export default ProductPriceContainer;
