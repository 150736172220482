// TODO update this import when renamed
import { UPDATE_POWERSTEP } from './PowerStep.action';

export const getInitialState = () => ({
    product: {}
});

export const PowerStepReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_POWERSTEP:
        const {
            product
        } = action;

        return {
            ...state,
            product
        };

    default:
        return state;
    }
};

export default PowerStepReducer;
