import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { PROMOTIONBAR_ISHIDDEN } from 'Component/PromotionBar/PromotionBar.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import {
    DEFAULT_HEADER_STATE,
    HeaderContainer as SourceHeaderContainer,
    mapDispatchToProps as soruceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Header/Header.container';
import {
    clearCurrentInfo
} from 'Store/Checkout/Checkout.action';
import {
    clearCurrentCategory
} from 'Store/CurrentCategory/CurrentCategory.action';
import {
    clearCurrentCheckoutInfo
} from 'Store/CurrentCheckoutInfo/CurrentCheckoutInfo.action';
import { showNotification } from 'Store/Notification/Notification.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import {
    appendWithStoreCode
} from 'Util/Url';

import {
    CART,
    MENU,
    SEARCH_POPUP,
    STORES,
    WISHLIST
} from './Header.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);
export {
    DEFAULT_HEADER_STATE
};

export const mapDispatchToProps = (dispatch) => ({
    ...soruceMapDispatchToProps(dispatch),
    clearCurrentInfo: () => dispatch(clearCurrentInfo()),
    clearCurrentCheckoutInfo: () => dispatch(clearCurrentCheckoutInfo()),
    clearCurrentCategory: () => dispatch(clearCurrentCategory()),
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    resetCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    logout: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(false, dispatch)
    )
});

/** @namespace Sofacompany/Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    wishlistItems: state.WishlistReducer.productsInWishlist,
    storeCode: state.ConfigReducer.code,
    hasFetchedPromotionBarOnce: state.PromotionBarReducer.hasFetchedOnce,
    restoreCart: state.CurrentCheckoutInfoReducer.data?.restoreCart,
    currentCheckoutInfo: state.CurrentCheckoutInfoReducer.data
});

/** @namespace Sofacompany/Component/Header/Container/HeaderContainer */
export class HeaderContainer extends SourceHeaderContainer {
    containerFunctions = {
        ...this.containerFunctions,
        onMenuButtonClick: this.onMenuButtonClick.bind(this),
        onStoresButtonClick: this.onStoresButtonClick.bind(this),
        onWishlistButtonClick: this.onWishlistButtonClick.bind(this),
        clearSearchInput: this.clearSearchInput.bind(this),
        onAccountHover: this.onAccountHover.bind(this),
        onAccountLeave: this.onAccountLeave.bind(this),
        handleLogout: this.handleLogout.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            prevPathname: '',
            searchCriteria: '',
            isClearEnabled: this.getIsClearEnabled(),
            showMyAccountLogin: false,
            curSearch: '',
            searchOpen: false,
            showShadow: false,
            accountHover: false

        };
    }

    containerProps = () => {
        const {
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            isLoading,
            device,
            wishlistItems,
            storeCode,
            isWishlistLoading,
            hasFetchedPromotionBarOnce,
            restoreCart
        } = this.props;

        const {
            isClearEnabled,
            searchCriteria,
            showMyAccountLogin,
            showShadow
        } = this.state;

        const {
            location: {
                pathname
            }
        } = history;

        const isCheckout = pathname.includes(CHECKOUT_URL);

        const promotionHidden = BrowserDatabase.getItem(PROMOTIONBAR_ISHIDDEN);
        return {
            restoreCart,
            promotionHidden,
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            isLoading,
            isClearEnabled,
            searchCriteria,
            isCheckout,
            showMyAccountLogin,
            device,
            wishlistItems,
            storeCode,
            isWishlistLoading,
            showShadow,
            hasFetchedPromotionBarOnce
        };
    };

    // eslint-disable-next-line no-unused-vars
    onScroll(event) {
        const offset = window.scrollY;
        const minOffset = 10;
        this.setState({ showShadow: offset > minOffset });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll.bind(this));
    }

    componentDidMount() {
        const { clearCurrentCategory } = this.props;
        this.handleHeaderVisibility();
        super.componentDidMount();
        clearCurrentCategory();
        window.addEventListener('scroll', this.onScroll.bind(this));
        this.checkCurrentRoute();
    }

    handleLogout() {
        const { logout } = this.props;
        logout();
    }

    _handleError = (error) => {
        const { showErrorNotification } = this.props;
        const [{ message, debugMessage }] = error;

        this.setState({
            isDeliveryOptionsLoading: false,
            isLoading: false
        }, () => {
            if (message !== 'Internal server error') {
                showErrorNotification(debugMessage || message);
            }
        });

        return false;
    };

    componentDidUpdate(prevProps) {
        const { location: { pathname } } = this.props;
        const { location: { pathname: prevPathname } } = prevProps;
        this.hideSearchOnStateChange(prevProps);
        this.handleHeaderVisibility();

        if (pathname !== prevPathname) {
            this.checkCurrentRoute();
        }
    }

    onAccountHover() {
        this.setState({ accountHover: true });
    }

    onAccountLeave() {
        this.setState({ accountHover: false });
    }

    onSearchBarFocus() {
        const {
            activeOverlay,
            showOverlay
        } = this.props;

        if (activeOverlay !== SEARCH_POPUP) {
            showOverlay(SEARCH_POPUP);
            this.setState({ searchOpen: true });

            setTimeout(() => {
                document.querySelector('.SearchOverlay #search-field')?.focus();
                document.querySelector('.SearchOverlayPopover #search-field')?.focus();
            }, Number('200'));
        }
    }

    onSearchBarChange({ target: { value: searchCriteria } }) {
        this.setState({ searchCriteria });
        this.setState({ curSearch: searchCriteria });
    }

    hideSearchOverlay() {
        const { hideActiveOverlay, activeOverlay } = this.props;

        if (activeOverlay === SEARCH_POPUP) {
            hideActiveOverlay();
        }
        this.setState({ searchOpen: false });
    }

    hideSearchOnStateChange(prevProps) {
        const { navigationState: { name: prevName } } = prevProps;
        const { navigationState: { name } } = this.props;

        if (prevName === SEARCH_POPUP && prevName !== name) {
            this.hideSearchOverlay();
        }
    }

    checkCurrentRoute() {
        const {
            location: { pathname }, clearCurrentCheckoutInfo, clearCurrentInfo, currentCheckoutInfo
        } = this.props;

        if (!pathname.includes('/checkout') && !pathname.includes('/cart') && currentCheckoutInfo?.email) {
            clearCurrentCheckoutInfo();
            clearCurrentInfo();
        }
    }

    onSearchOutsideClick() {
        this.clearSearchInput();
        this.hideSearchOverlay();
    }

    clearSearchInput() {
        this.setState({ curSearch: '' });
    }

    onMenuButtonClick() {
        const { pathname } = location;

        if (pathname !== appendWithStoreCode(`/${ MENU }`)) {
            history.push(appendWithStoreCode(`/${ MENU }`));
        } else {
            history.goBack();
        }
    }

    onBackButtonClick() {
        const { pathname } = location;
        this.setState({ searchCriteria: '' });
        this.clearSearchInput();
        if (pathname.includes('/checkout/success') || pathname.includes('/checkout/fail')) {
            history.push(appendWithStoreCode('/cart'));
        } else {
            history.goBack();
        }
    }

    onStoresButtonClick() {
        history.push(appendWithStoreCode(`/${ STORES }`));
    }

    onWishlistButtonClick() {
        history.push(appendWithStoreCode(`/${ WISHLIST }`));
    }

    onMinicartButtonClick() {
        history.push(appendWithStoreCode(`/${ CART }`));
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer));
