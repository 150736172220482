import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import { routesArray } from 'Component/Router/Router.component';
import {
    Meta as SourceMeta
} from 'SourceComponent/Meta/Meta.component';
import { getLocaleFromUrl } from 'Util/Global';

/** @namespace Sofacompany/Component/Meta/Component/Meta */
export class Meta extends SourceMeta {
    static propTypes = {
        ...SourceMeta.propTypes,
        title: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ])
    };

    renderTitle() {
        const {
            default_title,
            title_prefix,
            title_suffix,
            title
        } = this.props;

        const titlePrefix = title_prefix ? `${ title_prefix } | ` : '';
        const titleSuffix = title_suffix ? ` | ${ title_suffix }` : '';

        return (
            <>
                <title>
                    { `${ titlePrefix }${ title || default_title }${ titleSuffix }` }
                </title>
                <meta property="og:title" content={ `${ titlePrefix }${ title || default_title }${ titleSuffix }` } />
                <meta name="twitter:title" content={ `${ titlePrefix }${ title || default_title }${ titleSuffix }` } />
            </>
        );
    }

    renderCanonical() {
        const { canonical_url, custom_url_type } = this.props;
        const locale = getLocaleFromUrl();

        if (!canonical_url) {
            if (custom_url_type) {
                const url = `${window.location.origin}/${routesArray[locale][custom_url_type]}`;

                return (
                    // eslint-disable-next-line max-len
                    <link rel="canonical" href={ url } />
                );
            }

            return null;
        }

        return (
            <link rel="canonical" href={ canonical_url } />
        );
    }

    renderHrefLang() {
        const {
            customHreflangs,
            hreflangs,
            custom_url_type,
            hrefLangCapitalize
        } = this.props;
        const origin = `${window.location.origin }`;
        const pathnameWithoutLocale = window.location.pathname.slice(Number('7'));

        if (Object.entries(routesArray).length > 0 && customHreflangs.url === pathnameWithoutLocale) {
            return Object.entries(routesArray)?.map((routes) => (
                <link
                  rel="alternate"
                  // eslint-disable-next-line prefer-template
                  href={ origin + '/' + routes[0] + '/' + customHreflangs.url }
                  key={ hrefLangCapitalize(routes[0]) }
                  hrefLang={ hrefLangCapitalize(routes[0]) }
                />
            ));
        }

        if (hreflangs && hreflangs.length <= 0 && custom_url_type) {
            // eslint-disable-next-line max-len
            return Object.entries(routesArray)?.map((routes) => (
                <link
                  rel="alternate"
                  // eslint-disable-next-line prefer-template
                  href={ origin + '/' + routes[0] + '/' + routes[1][custom_url_type] }
                  key={ hrefLangCapitalize(routes[0]) }
                  hrefLang={ hrefLangCapitalize(routes[0]) }
                />
            ));
        }

        return hreflangs?.map((hreflang) => (
            <link rel="alternate" href={ hreflang.url } key={ hreflang.code } hrefLang={ hreflang.code } />
        ));
    }

    renderMeta() {
        const { metadata } = this.props;

        metadata.map((tag) => {
            if (tag.name === 'title') {
                metadata.push({
                    name: 'og:title',
                    content: tag.content
                });
                metadata.push({
                    name: 'twitter:title',
                    content: tag.content
                });
            }
            if (tag.name === 'description') {
                metadata.push({
                    name: 'og:description',
                    content: tag.content
                });
                metadata.push({
                    name: 'twitter:description',
                    content: tag.content
                });
            }
            if (tag.name === 'image') {
                metadata.push({
                    name: 'og:image',
                    content: tag.content
                });
                metadata.push({
                    name: 'twitter:image',
                    content: tag.content
                });
            }
        });

        return (
            <>
                { this.renderTitle() }
                { this.renderCanonical() }
                { this.renderHrefLang() }
                <meta property="og:url" content={ window.location.href } />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                { metadata.map((tag) => <meta key={ tag.name || tag.property } { ...tag } />) }
            </>
        );
    }

    render() {
        const { is_ready, default_meta } = this.props;
        if (!is_ready || !default_meta) {
            return null;
        }

        return createPortal(
            { ...this.renderMeta() },
            document.head
        );
    }
}

export default Meta;
