import {
    appendTokenToHeaders, checkForErrors,
    getGraphqlEndpoint, HTTP_201_CREATED,
    HTTP_410_GONE, parseResponse, putPersistedQuery
} from 'SourceUtil/Request/Request';

import { hash } from './Hash';

export const WINDOW_ID = 'WINDOW_ID';

export * from 'SourceUtil/Request/Request';

/** @namespace Sofacompany/Util/Request/getWindowId */
export const getWindowId = () => {
    const result = sessionStorage.getItem(WINDOW_ID);

    if (!result) {
        const id = Date.now();
        sessionStorage.setItem(WINDOW_ID, id);
        return id;
    }

    return result;
};

/** @namespace Sofacompany/Util/Request/formatURI */
export const formatURI = (query, variables, url) => {
    const stringifyVariables = Object.keys(variables).reduce(
        (acc, variable) => [...acc, `${ variable }=${ JSON.stringify(variables[ variable ]) }`],
        [`?hash=${ hash(query) }`]
    );

    return `${ url }${ stringifyVariables.join('&') }`.replace(/ /g, '');
};

/** @namespace Sofacompany/Util/Request/formatProductURI */
export const formatProductURI = (query, variables, url) => {
    const stringifyVariables = Object.keys(variables).reduce(
        (acc, variable) => [...acc, `${ variable }=${ variables[ variable ] }`],
        [`?hash=${ hash(query) }`]
    );

    return `${ url }${ stringifyVariables.join('&') }`.replace(/ /g, '');
};

/**
 *
 * @param {String} uri
 * @param {String} name
 * @returns {Promise<Response>}
 * @namespace Sofacompany/Util/Request/getFetch */
export const getFetch = (uri, name) => fetch(uri,
    {
        method: 'GET',
        headers: appendTokenToHeaders({
            'Content-Type': 'application/json',
            'Application-Model': `${ name }_${ getWindowId() }`,
            Accept: 'application/json'
        })
    });

/** @namespace Sofacompany/Util/Request/executeGet */
export const executeGet = (queryObject, name, cacheTTL) => {
    const { query, variables } = queryObject;
    // eslint-disable-next-line max-len
    const uri = (name === 'ProductListInfo' || name === 'Product' || name === 'ProductList' || name === 'ProductListWidget')
        ? formatProductURI(query, variables, getGraphqlEndpoint())
        : formatURI(query, variables, getGraphqlEndpoint());

    return parseResponse(new Promise((resolve) => {
        getFetch(uri, name).then(
            /** @namespace Sofacompany/Util/Request/getFetch/then */
            (res) => {
                if (res.status === HTTP_410_GONE) {
                    putPersistedQuery(getGraphqlEndpoint(), query, cacheTTL).then(
                        /** @namespace Sofacompany/Util/Request/putPersistedQuery/then */
                        (putResponse) => {
                            if (putResponse.status === HTTP_201_CREATED) {
                                getFetch(uri, name).then(
                                    /** @namespace Sofacompany/Util/Request/getFetch/then */
                                    (res) => resolve(res)
                                );
                            }
                        }
                    );
                } else {
                    resolve(res);
                }
            }
        );
    }));
};

/**
 * Listen to the BroadCast connection
 * @param  {String} name Name of model for ServiceWorker to send BroadCasts updates to
 * @return {Promise<any>} Broadcast message promise
 * @namespace Sofacompany/Util/Request/listenForBroadCast */
export const listenForBroadCast = (name) => new Promise((resolve) => {
    const { BroadcastChannel } = window;
    const windowId = getWindowId();

    if (BroadcastChannel) {
        const bc = new BroadcastChannel(`${ name }_${ windowId }`);
        bc.onmessage = (update) => {
            const { data: { payload: body } } = update;
            resolve(checkForErrors(body));
        };
    }
});

/** @namespace Util/Request/debounce */
export const bouncer = (func, delay, skip) => {
    const reFunc = func;
    const context = this;
    return (...args) => {
        if (func) {
            func.apply(context, args);

            if (skip && !func.skip && func.skip !== 0) {
                func.skip = skip - 1;
            } else if (skip && func.skip) {
                func.skip--;
            } else {
                func = null;
                setTimeout(() => {
                    func = reFunc;
                    delete func.skip;
                }, delay);
            }
        }
    };
};

export const DEFAULT_LOADING_TIME = 500;
export const LONG_LOADING_TIME = 1000;
export const FOREVER_LOADING_TIME = 99999;
