import {
    OfflineNotice as SourceOfflineNotice
} from 'SourceComponent/OfflineNotice/OfflineNotice.component';
import isMobile from 'Util/Mobile';

/** @namespace Sofacompany/Component/OfflineNotice/Component/OfflineNotice */
export class OfflineNotice extends SourceOfflineNotice {
    renderText() {
        if (!isMobile.any()) {
            return (
                <div block="OfflineNotice" elem="Text">
                    { __('We could not load the content. Check your internet connection and try again.') }
                </div>
            );
        }

        return (
            <div block="OfflineNotice" elem="Text">
                { __('Offline mode') }
            </div>
        );
    }

    render() {
        const { isPage, isBig } = this.props;

        if (isPage) {
            return null;
        }

        return (
            <div block="OfflineNotice" mods={ { isBig } }>
                { this.renderText() }
            </div>
        );
    }
}

export default OfflineNotice;
