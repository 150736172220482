import { isEventEnabled } from '../EventConfig';

/**
 * @namespace Sofacompany/Util/Event/Event
 */
export class Event {
    static dispatch(name, data = {}) {
        if (!isEventEnabled(name)) {
            return false;
        }

        window.dispatchEvent(new CustomEvent(name, { detail: data }));

        return true;
    }

    static observer(name, callback) {
        if (callback && typeof callback === 'function' && isEventEnabled(name)) {
            const callbackWrapper = ({ detail: data }) => {
                callback.call(this, data);
            };

            window.addEventListener(name, callbackWrapper, false);

            return callbackWrapper;
        }

        return false;
    }

    static removeObserver(name, listener) {
        if (!isEventEnabled(name)) {
            return false;
        }

        window.removeEventListener(name, listener, false);

        return true;
    }
}

export default Event;
