import { connect } from 'react-redux';

import MenuQuery from 'Query/Menu.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Footer/Footer.container';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import MenuHelper from 'Util/Menu';
import DataContainer from 'Util/Request/DataContainer';

import Footer from './Footer.component';
import { FOOTER_MENU_IDENTIFIER } from './Footer.config';

/** @namespace Sofacompany/Component/Footer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    navigationState: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState,
    currentCategory: state.CurrentCategoryReducer,
    powerStepProduct: state.PowerStepReducer.product,
    powerStepDYOToS: state.ContentBlockReducer.content.powerStepDYOToS
});

export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    hidePopup: () => dispatch(showPopup('', {}))
});

/** @namespace Sofacompany/Component/Footer/Container/FooterContainer */
export class FooterContainer extends DataContainer {
    state = {
        isLoading: true,
        menu: {}
    };

    containerFunctions = {
        openCookieConsent: this.openCookieConsent.bind(this)
    };

    componentDidMount() {
        this.requestMenu();
    }

    openCookieConsent() {
        window.CookieConsent.renew();
    }

    requestMenu() {
        this.setState({
            isLoading: true
        });

        this.fetchData(
            [MenuQuery.getQuery({ identifier: FOOTER_MENU_IDENTIFIER })],
            ({ menu }) => this.setState({
                menu: MenuHelper.reduce(menu),
                isLoading: false
            }),
            (error) => {
                this.setState({
                    isLoading: false,
                    menu: {}
                });

                showNotification('error', error[0].message);
            }
        );
    }

    render() {
        return (
            <Footer
              { ...this.state }
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
