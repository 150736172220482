import GiftCardQuery from 'Query/GiftCard.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { getGuestQuoteId } from 'Util/Cart/Token';
import { fetchMutation } from 'Util/Request';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const GIFTCARD = 'giftcard';

/**
 * Product CartDispatcher
 * @class CartDispatcher
 * @namespace Sofacompany/Store/GiftCard/Dispatcher/GiftCardDispatcher */
export class GiftCardDispatcher {
    /**
     * Apply gift card to cart
     * @param dispatch
     * @param giftCardCode
     * @returns {Promise<void>}
     */
    async applyGiftCardToCart(dispatch, giftCardCode) {
        try {
            const result = await fetchMutation(GiftCardQuery.getApplyGiftCardMutation(
                // eslint-disable-next-line max-len
                giftCardCode, getGuestQuoteId()
            ));
            const { cartData } = result.applyGiftCard;

            if (cartData) {
                CartDispatcher.then(({ default: dispatcher }) => dispatcher._updateCartData(cartData, dispatch));
                dispatch(showNotification('success', __('Gift card was applied!')));
            }
        } catch (error) {
            if (error.length) {
                dispatch(showNotification('error', error[0].message));
            }
        }
    }

    /**
     * Remove giftcard from cart
     * @param dispatch
     * @param giftCardCode
     * @param showNotification
     * @returns {Promise<void>}
     */
    async removeGiftCardFromCart(dispatch, giftCardCode, showNotification = true) {
        try {
            const result = await fetchMutation(GiftCardQuery.getRemoveGiftCardFromCartMutation(
                giftCardCode, getGuestQuoteId()
            ));
            const { cartData } = result.removeGiftCard;

            if (cartData) {
                CartDispatcher.then(({ default: dispatcher }) => dispatcher._updateCartData(cartData, dispatch));

                if (showNotification) {
                    dispatch(showNotification('success', __('Gift card was removed!')));
                }
            }
        } catch (error) {
            if (error.length) {
                dispatch(showNotification('error', error[0].message));
            }
        }
    }
}

export default new GiftCardDispatcher();
