import {
    IMAGE_LOADED,
    IMAGE_LOADING,
    IMAGE_NOT_FOUND,
    IMAGE_NOT_SPECIFIED
} from '@scandipwa/scandipwa/src/component/Image/Image.config';
import PropTypes from 'prop-types';
import InnerImageZoom from 'react-inner-image-zoom';

import {
    Image as SourceImage
} from 'SourceComponent/Image/Image.component';

import './Image.style.overide';

export class Image extends SourceImage {
    static propTypes = {
        ...SourceImage.propTypes,
        loading: PropTypes.string,
        alt: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ]),
        isBackGround: PropTypes.any
    };

    static defaultProps = {
        loading: 'lazy'
    };

    renderBackgroundImage(image) {
        if (!image) {
            return null;
        }

        return {
            backgroundImage: `url(${ image })`
        };
    }

    renderImage() {
        const {
            alt, src, isPlaceholder, style, loading, isBackGround, zoomSrc
        } = this.props;
        const { imageStatus } = this.state;

        if (isPlaceholder) {
            return null;
        }

        const isPrerender = navigator.userAgent.toLowerCase().indexOf('prerender') !== -1;
        if (isPrerender) {
            if (imageStatus === IMAGE_NOT_SPECIFIED) {
                return (
                    <span block="Image" elem="Content">{ __('Image not specified') }</span>
                );
            }

            return (
                <img
                  block="Image"
                  elem="Image"
                  src={ src || '' }
                  alt={ alt }
                  mods={ { isLoading: imageStatus === IMAGE_LOADING } }
                  style={ style }
                  onLoad={ this.onLoad }
                  onError={ this.onError }
                  loading={ loading }
                />
            );
        }

        switch (imageStatus) {
        case IMAGE_NOT_FOUND:
            return this.renderImageNotFound();
        case IMAGE_NOT_SPECIFIED:
            return (
                <span block="Image" elem="Content">{ __('Image not specified') }</span>
            );
        case IMAGE_LOADED:
        case IMAGE_LOADING:

            if (isBackGround) {
                const backgroundImage = this.renderBackgroundImage(src);
                const newStyle = {
                    ...backgroundImage,
                    ...style
                };

                return (
                    <div
                      block="Image"
                      elem="Image"
                      mods={ { isLoading: imageStatus === IMAGE_LOADING, isBackGround } }
                      style={ newStyle }
                      onLoad={ this.onLoad }
                      onError={ this.onError }
                      loading={ loading }
                    />
                );
            }

            if (zoomSrc) {
                return (
                    <div block="Image" elem="Image">
                        <InnerImageZoom
                          src={ src || '' }
                          zoomSrc={ zoomSrc || '' }
                          zoomScale="2"
                          height="100%"
                          className="Image-Zoom"
                          hideHint="false"
                          zoomPreload="true"
                          hideCloseButton="true"
                        />
                    </div>

                );
            }

            return (
                <img
                  block="Image"
                  elem="Image"
                  src={ src || '' }
                  alt={ alt }
                  mods={ { isLoading: imageStatus === IMAGE_LOADING } }
                  style={ style }
                  onLoad={ this.onLoad }
                  onError={ this.onError }
                  loading={ loading }
                  decoding="async"
                />
            );
        default:
            return null;
        }
    }
}

export default Image;
