import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';

import GoogleTagManager from '../component/GoogleTagManager';

const addAdditionalComponents = (member) => {
    const maxPosition = Math.max(
        // eslint-disable-next-line no-magic-numbers
        member.map((route) => route.position).filter((num) => num <= 1000)
    );

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    return [
        ...member,
        {
            component: <GoogleTagManager />,
            // eslint-disable-next-line no-magic-numbers
            position: maxPosition + 10
        }
    ];
};

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: addAdditionalComponents
        }
    }
};
