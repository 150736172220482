export const UPDATE_STORE_FINDER = 'UPDATE_STORE_FINDER';
export const UPDATE_STORE_FINDER_LOADER = 'UPDATE_STORE_FINDER_LOADER';

/**
 * Update Store Finder information
 * @namespace Sofacompany/Store/StoreFinder/Action/updateStoreFinder */
export const updateStoreFinder = (storeList) => ({
    type: UPDATE_STORE_FINDER,
    storeList
});

/* @namespace Sofacompany/Store/StoreFinder/Action/updateStoreFinderLoader */
export const updateStoreFinderLoader = (isLoading) => ({
    type: UPDATE_STORE_FINDER_LOADER,
    isLoading
});
