import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';

import './TrustpilotBlock.style';

/** @namespace Sofacompany/Component/Accordion/Component/Accordion */
export class TrustpilotBlock extends PureComponent {
    static propTypes = {
        content: PropTypes.any,
        trustpilotRef: PropTypes.any,
        buid: PropTypes.any,
        locale: PropTypes.any
    };

    static defaultProps = {
        content: null,
        trustpilotRef: null,
        buid: null,
        locale: null
    };

    render() {
        const {
            content,
            trustpilotRef,
            buid,
            locale
        } = this.props;

        return (content ? (
            <Html trustpilotRef={ trustpilotRef } content={ content } />
        ) : (
                <div block="TrustpilotBlock">
                    <div
                      ref={ trustpilotRef }
                      block="TrustpilotBlock"
                      elem="Widget"
                      data-locale={ locale.replace('_', '-') }
                      data-businessunit-id={ buid }
                      data-template-id="53aa8912dec7e10d38f59f36"
                      data-style-height="140px"
                      data-style-width="100%"
                      data-theme="light"
                      data-stars="4,5"
                    />
                </div>
        ));
    }
}

export default TrustpilotBlock;
