import { UPDATE_PRODUCT_LIST_ITEMS } from 'SourceStore/ProductList/ProductList.action';

export * from 'SourceStore/ProductList/ProductList.action';

export const updateProductListItems = (
    items,
    currentPage,
    currentPath,
    total_count,
    total_pages,
    args
) => ({
    type: UPDATE_PRODUCT_LIST_ITEMS,
    items,
    currentPage,
    currentPath,
    total_pages,
    total_count,
    args
});
