import {
    UPDATE_STUDIO_IMAGE
} from './StudioImage.action';

/** @namespace Sofacompany/Store/StudioImage/Reducer/getInitialState */
export const getInitialState = () => ({
    studioImageFrame: false
});

/** @namespace Sofacompany/Store/StudioImage/Reducer/StudioImageReducer */
export const StudioImageReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_STUDIO_IMAGE:
        const { studioImageFrame } = action;

        return {
            ...state,
            studioImageFrame
        };
    default:
        return state;
    }
};

export default StudioImageReducer;
