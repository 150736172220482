import { reduceFilters } from 'Store/ProductListInfo/ProductListInfo.reducer';

import {
    UPDATE_FABRIC_CATEGORY, UPDATE_FABRIC_CHECKOUT_DONE,
    UPDATE_FABRIC_FILTERS,
    UPDATE_FABRIC_LOADING, UPDATE_FABRIC_LOADING_CHECKOUT, UPDATE_FABRIC_PRODUCTS,
    UPDATE_FABRIC_SORTS, UPDATE_META
} from './FabricSamples.action';

/** @namespace Sofacompany/Store/FabricSamples/Reducer/getInitialState */
export const getInitialState = () => ({
    category: {},
    products: 'isLoading',
    isFabricLoading: false,
    isFabricCheckoutLoading: false,
    sortFields: {},
    filters: {},
    isFabricCheckoutDone: false,
    meta: {}
});

/** @namespace Sofacompany/Store/FabricSamples/Reducer/FabricSamplesReducer */
export const FabricSamplesReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_FABRIC_CATEGORY:
        const { category } = action;

        return {
            ...state,
            category
        };

    case UPDATE_FABRIC_LOADING:
        const { isFabricLoading } = action;

        return {
            ...state,
            isFabricLoading
        };

    case UPDATE_FABRIC_PRODUCTS:
        const { products } = action;

        return {
            ...state,
            products
        };

    case UPDATE_FABRIC_LOADING_CHECKOUT:
        const { isFabricCheckoutLoading } = action;

        return {
            ...state,
            isFabricCheckoutLoading
        };

    case UPDATE_FABRIC_SORTS:
        const { sortFields } = action;

        return {
            ...state,
            sortFields
        };

    case UPDATE_FABRIC_FILTERS:
        const { filters: availableFilters = [] } = action;

        return {
            ...state,
            filters: reduceFilters(availableFilters)
        };

    case UPDATE_FABRIC_CHECKOUT_DONE:
        const { isFabricCheckoutDone } = action;

        return {
            ...state,
            isFabricCheckoutDone
        };

    case UPDATE_META:
        const { meta } = action;

        return {
            ...state,
            meta
        };

    default:
        return state;
    }
};

export default FabricSamplesReducer;
