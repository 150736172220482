import PropTypes from 'prop-types';

import Icon from 'Component/Icon';
import {
    Loader as SourceLoader
} from 'SourceComponent/Loader/Loader.component';

/** @namespace Sofacompany/Component/Loader/Component/Loader */
export class Loader extends SourceLoader {
    static propTypes = {
        ...SourceLoader.propTypes,
        isBlock: PropTypes.bool.isRequired,
        isFixed: PropTypes.bool.isRequired
    };

    static defaultProps = {
        ...SourceLoader.defaultProps,
        isBlock: false,
        isFixed: false
    };

    renderMain() {
        return (
            <div
              block="Loader"
              elem="Preloader"
              aria-busy="true"
              aria-label={ __('Loading, please wait.') }
              role="progressbar"
            >
                <Icon icon="sofa" />
            </div>
        );
    }

    render() {
        const {
            isLoading, isBlock, isFixed, isBoxed, isFull
        } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div
              block="Loader"
              mods={ {
                  isBlock, isFixed: !!isFixed, isBoxed: !!isBoxed, isFull: !!isFull
              } }
            >
                { this.renderMain() }
            </div>
        );
    }
}

export default Loader;
