import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import Loader from 'Component/Loader';
import { BlockListType } from 'Type/CMS';

import './CmsPageBlock.style';
/** @namespace Sofacompany/Component/CmsPageBlock/Component/CmsPageBlock */
export class CmsPageBlock extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        page: BlockListType.isRequired
    };

    render() {
        const {
            isLoading,
            page: { content }
        } = this.props;

        if (isLoading) {
            return (
                <div block="CmsPageBlock" elem="Loader">
                    <Loader isBlock isLoading />
                </div>
            );
        }

        if (!isLoading && !content) {
            return null;
        }

        return <Html content={ content } />;
    }
}

export default CmsPageBlock;
