import { connect } from 'react-redux';

import {
    MENU_SUBCATEGORY
} from 'Component/Header/Header.config';
import MenuQuery from 'Query/Menu.query';
import {
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/Menu/Menu.container';
import MenuHelper from 'Util/Menu';
import DataContainer from 'Util/Request/DataContainer';

import ExtraMenu from './ExtraMenu.component';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Sofacompany/Component/ExtraMenu/Container/ExtraMenuContainer */
export class ExtraMenuContainer extends DataContainer {
    state = {
        ...this.state,
        menu: {},
        activeMenuItemsStack: []
    };

    containerFunctions = {
        handleSubcategoryClick: this.handleSubcategoryClick.bind(this),
        closeMenu: this.closeMenu.bind(this)
    };

    containerProps = () => {
        const {
            device,
            goToPreviousHeaderState,
            changeHeaderState,
            isNoScroll,
            childs,
            extra
        } = this.props;

        return {
            device,
            goToPreviousHeaderState,
            changeHeaderState,
            isNoScroll,
            childs,
            extra
        };
    };

    componentDidMount() {
        this._getExtraMenu();
    }

    _getExtraMenu() {
        const { menu = 'func-menu' } = this.props;

        this.fetchData(
            [MenuQuery.getQuery({
                identifier: menu
            })],
            ({ menu }) => this.setState({
                menu: MenuHelper.reduce(menu)
            })
        );
    }

    closeMenu() {
        const { device } = this.props;
        if (device.isMobile) {
            return;
        }

        this.setState({ activeMenuItemsStack: [] });
    }

    handleSubcategoryClick(e, activeSubcategory) {
        const { activeMenuItemsStack } = this.state;
        const { changeHeaderState, goToPreviousHeaderState } = this.props;
        const { item_id, title } = activeSubcategory;

        e.stopPropagation();

        changeHeaderState({
            name: MENU_SUBCATEGORY,
            force: true,
            title,
            onBackClick: () => {
                this.setState(({ activeMenuItemsStack }) => (
                    { activeMenuItemsStack: activeMenuItemsStack.slice(1) }
                ));
                goToPreviousHeaderState();
            }
        });

        if (!activeMenuItemsStack.includes(item_id)) {
            this.setState({ activeMenuItemsStack: [item_id, ...activeMenuItemsStack] });
        } else if (activeMenuItemsStack.includes(item_id)) {
            activeMenuItemsStack.map((currElement, index) => {
                if (currElement === item_id) {
                    this.setState({ activeMenuItemsStack: activeMenuItemsStack.slice(0, index) });
                }
            });
        }
    }

    render() {
        return (
            <ExtraMenu
              { ...this.state }
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtraMenuContainer);
