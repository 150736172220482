/* eslint-disable import/no-cycle */

import Event, { EVENT_GTM_SLIDER_CLICK } from 'Util/Event';

import BaseEvent from './BaseEvent.event';

export const CHECKOUT_EVENT_DELAY = 500;
export const SPAM_PROTECTION_DELAY = 1000;

/**
 * On checkout
 * @namespace Sofacompany/Component/GoogleTagManager/Events/SliderClick/Event/SliderClickEvent
 */
export class SliderClickEvent extends BaseEvent {
    /**
     * Event fire delay
     *
     * @type {number}
     */
    eventHandleDelay = CHECKOUT_EVENT_DELAY;

    /**
     * Bind
     */
    bindEvent() {
        Event.observer(EVENT_GTM_SLIDER_CLICK, ({ i, link }) => {
            this.handle(i, link);
        });
    }

    /**
     * Handle
     */
    handler(imageIndex, link) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        this.pushEventData({
            imageIndexClicked: imageIndex,
            imageLink: link
        });
    }
}

export default SliderClickEvent;
