import {
    getInitialState,
    reduceFilters
} from 'SourceStore/ProductListInfo/ProductListInfo.reducer';
import {
    UPDATE_INFO_LOAD_STATUS,
    UPDATE_PRODUCT_LIST_INFO,
    UPDATE_PRODUCT_PRICE_RANGE
} from 'Store/ProductListInfo/ProductListInfo.action';

export {
    reduceFilters,
    getInitialState
};

/** @namespace Sofacompany/Store/ProductListInfo/Reducer/ProductListReducer */
export const ProductListReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        isLoading,
        selectedFilter,
        products: {
            filters: availableFilters = [],
            sort_fields: sortFields
        } = {}
    } = action;

    switch (type) {
    case UPDATE_PRODUCT_LIST_INFO:

        return {
            ...state,
            filters: reduceFilters(availableFilters),
            sortFields,
            isLoading: false,
            selectedFilter
        };

    case UPDATE_INFO_LOAD_STATUS:
        return {
            ...state,
            isLoading
        };

    case UPDATE_PRODUCT_PRICE_RANGE:

        const [{ filter_items: [{ value_string }] }] = availableFilters;

        const priceArray = value_string.split('_');
        return {
            ...state,
            minPrice: Math.floor(priceArray[0]),
            maxPrice: Math.ceil(priceArray[1])
        };

    default:
        return state;
    }
};

export default ProductListReducer;
