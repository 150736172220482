import {
    UPDATE_LINKED_PRODUCTS,
    updateLinkedProducts as SourceUpdateLinkedProducts
} from 'SourceStore/LinkedProducts/LinkedProducts.action';

export {
    UPDATE_LINKED_PRODUCTS
};

export const updateLinkedProducts = SourceUpdateLinkedProducts;
