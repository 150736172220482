/* eslint-disable no-unused-vars */

import {
    QueryDispatcher as SourceQueryDispatcher
} from 'SourceUtil/Request/QueryDispatcher';
import { makeCancelable } from 'Util/Promise';
import { prepareQuery } from 'Util/Query';
import { hash } from 'Util/Request/Hash';
import { executeGet } from 'Util/Request/Request';

export const ONE_MONTH_IN_SECONDS = 2592000;
export const FIVE_MINUTES_IN_SECONDS = 300;

export class QueryDispatcher extends SourceQueryDispatcher {
    fetchData(rawQueries, onSucces = () => {}, onError = () => {}) {
        const preparedQuery = prepareQuery(rawQueries);
        const { query, variables } = preparedQuery;
        const queryHash = hash(query + JSON.stringify(variables));

        if (!window.dataCache) {
            window.dataCache = {};
        }

        if (window.dataCache[queryHash]) {
            onSucces(window.dataCache[queryHash]);
            return;
        }

        this.promise = makeCancelable(
            executeGet(preparedQuery, this.dataModelName, ONE_MONTH_IN_SECONDS)
        );

        this.promise.promise.then(
            /** @namespace Util/Request/DataContainer/fetchData/thisPromisePromiseThen */
            (response) => {
                window.dataCache[queryHash] = response;
                onSucces(response);
            },
            /** @namespace Util/Request/DataContainer/fetchData/thisPromisePromiseCatch */
            (err) => onError(err)
        );
    }
}

export default QueryDispatcher;
