import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    POWERSTEP_OVERLAY
} from 'Component/Footer/Footer.config';
import {
    AddToCartContainer as SourceAddToCartContainer,
    CartDispatcher,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    WishlistDispatcher
} from 'SourceComponent/AddToCart/AddToCart.container';
import { GIFTCARD } from 'Store/GiftCard/GiftCard.dispatcher';
import { showPopup } from 'Store/Popup/Popup.action';
import { updatePowerStep } from 'Store/PowerStep/PowerStep.action';
import { isSignedIn } from 'Util/Auth';
import Event, {
    EVENT_GTM_PRODUCT_ADD_TO_CART
} from 'Util/Event';

export {
    CartDispatcher,
    WishlistDispatcher
};

export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    currencyCode: state.ConfigReducer.default_display_currency_code,
    cart_totals: state.CartReducer.cartTotals
});

export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updatePowerStep: (product) => dispatch(updatePowerStep(product)),
    showPowerStep: (payload) => dispatch(showPopup(POWERSTEP_OVERLAY, {
        title: __('Product added to cart!'),
        ...payload
    }))
});

/** @namespace Sofacompany/Component/AddToCart/Container/AddToCartContainer */
export class AddToCartContainer extends SourceAddToCartContainer {
    static propTypes = {
        ...SourceAddToCartContainer.propTypes,
        groupedProductQuantity: PropTypes.objectOf(PropTypes.number).isRequired,
        productOptionsData: PropTypes.object,
        updatePowerStep: PropTypes.func.isRequired,
        isWhite: PropTypes.bool
    };

    static defaultProps = {
        ...SourceAddToCartContainer.defaultProps,
        groupedProductQuantity: {},
        productOptionsData: {},
        isWhite: false
    };

    addToCartHandlerMap = {
        ...this.addToCartHandlerMap,
        [GIFTCARD]: this.addGiftCardProductToCart.bind(this)
    };

    validateAddToCart() {
        const {
            showNotification,
            product: {
                type_id,
                giftcard_type
            },
            isGiftCardInputError,
            giftCardData: {
                giftcard_recipient_email = null,
                giftcard_recipient_name = 'Giftcard'
            } = {}
        } = this.props;

        const validationRule = this.validationMap[type_id];

        if (validationRule) {
            return validationRule();
        }

        if (type_id === GIFTCARD) {
            if ((!giftcard_recipient_email && giftcard_type !== 'PHYSICAL')
            || (!giftcard_recipient_name && giftcard_type !== 'PHYSICAL')) {
                showNotification('info', __('Please fill in all the fields!'));

                return false;
            }

            if (isGiftCardInputError) {
                showNotification('info', __('Unable to add the giftcard with the specified value'));

                return false;
            }

            return true;
        }

        return this.validateSimpleProduct();
    }

    buttonClick() {
        const {
            product: { type_id },
            onProductValidationError
        } = this.props;

        if (!this.validateAddToCart()) {
            onProductValidationError(type_id);
            return;
        }

        this.setState({ isLoading: true }, () => this.addProductToCart());
    }

    addGiftCardProductToCart() {
        const {
            product,
            configurableVariantIndex,
            product: {
                variants
            },
            quantity,
            addProduct,
            giftCardVariantIndex,
            giftCardData,
            giftCardAmount,
            productOptionsData
        } = this.props;

        // eslint-disable-next-line fp/no-let
        let giftCardFieldData = {};

        const { giftcard_amounts, allow_open_amount } = product;
        const {
            giftcard_sender_email,
            giftcard_sender_name,
            giftcard_recipient_name,
            giftcard_message
        } = giftCardData;

        if (allow_open_amount) {
            giftCardFieldData = { custom_giftcard_amount: giftCardAmount };
        } else {
            giftCardFieldData = { giftcard_amount: giftcard_amounts[giftCardVariantIndex].value };
        }

        giftCardFieldData = {
            ...giftCardFieldData,
            giftcard_message,
            giftcard_recipient_name,
            giftcard_sender_email,
            giftcard_sender_name
        };

        const productToAdd = variants
            ? {
                ...product,
                configurableVariantIndex,
                giftCardData: giftCardFieldData
            }
            : product;

        addProduct({
            product: productToAdd,
            quantity,
            productOptionsData
        }).then(
            /** @namespace Sofacompany/Component/AddToCart/Container/addProduct/then */
            () => this.afterAddToCart(),
            /** @namespace Sofacompany/Component/AddToCart/Container/addProduct/then */
            () => this.resetLoading()
        );

        return true;
    }

    containerProps() {
        const {
            product, mix, disabled, isWhite
        } = this.props;
        const { isLoading } = this.state;

        return {
            isLoading,
            product,
            mix,
            disabled,
            isWhite
        };
    }

    removeProductFromWishlist() {
        const {
            wishlistItems,
            removeFromWishlist,
            configurableVariantIndex,
            product: { type_id, variants = {} } = {},
            giftCardData
        } = this.props;

        if (type_id !== 'configurable') {
            return;
        }

        const { sku } = variants[configurableVariantIndex];

        // eslint-disable-next-line fp/no-let
        let wishlistItemKey;

        if (type_id === GIFTCARD) {
            wishlistItemKey = Object.keys(wishlistItems)
                .find((key) => {
                    const { wishlist: { sku: wSku, options } } = wishlistItems[key];
                    return wSku === sku && options === JSON.stringify(giftCardData);
                });
        } else {
            wishlistItemKey = Object.keys(wishlistItems)
                .find((key) => {
                    const { wishlist: { sku: wSku } } = wishlistItems[key];
                    return wSku === sku;
                });
        }

        if (!isSignedIn() || wishlistItemKey === undefined) {
            return;
        }

        const { wishlist: { id: item_id } } = wishlistItems[wishlistItemKey];
        removeFromWishlist({
            item_id,
            sku,
            giftCardData,
            noMessage: true
        });
    }

    isValidEmail(address) {
        return address.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    }

    afterAddToCart() {
        const {
            showNotification,
            setQuantityToDefault,
            product,
            product: { type_id, variants },
            giftCardAmount,
            quantity,
            configurableVariantIndex,
            groupedProductQuantity,
            category,
            isPowerStep,
            showPowerStep,
            updatePowerStep,
            currencyCode,
            cart_totals
        } = this.props;

        product.category = category;

        if (type_id === 'grouped') {
            Event.dispatch(EVENT_GTM_PRODUCT_ADD_TO_CART, {
                product: {
                    ...product,
                    quantities: groupedProductQuantity
                },
                isGrouped: true
            });
        } else {
            const productToAdd = variants
                ? { ...product, configurableVariantIndex }
                : product;

            Event.dispatch(EVENT_GTM_PRODUCT_ADD_TO_CART, {
                product: productToAdd,
                quantity,
                configurableVariantIndex
            });
        }

        if (isPowerStep) {
            const {
                thumbnail,
                sofa_original_name,
                name2,
                priceValidUntil,
                price_range: productPrice,
                sku,
                id,
                related_products,
                name,
                type_id,
                dyoData,
                stock_item
            } = product;
            const isGiftCard = type_id === GIFTCARD;
            const foundItem = cart_totals?.items.find((item) => item.product.sku === sku);
            const foundItemCartQty = foundItem?.qty;
            const showStockNotification = stock_item.qty > 0 && foundItemCartQty > stock_item.qty;
            const powerProduct = {
                dyoData,
                thumbnail,
                sofa_original_name: isGiftCard ? name : sofa_original_name,
                name,
                name2,
                priceValidUntil,
                productPrice: isGiftCard ? {
                    minimum_price: {
                        discount: { amount_off: 0, percent_off: 0 },
                        final_price: { currency: currencyCode, value: giftCardAmount },
                        regular_price: { currency: currencyCode, value: giftCardAmount }
                    }
                } : productPrice,
                sku,
                id,
                related_products,
                isGiftCard,
                showStockNotification,
                stock_item
            };

            updatePowerStep(powerProduct);
            showPowerStep();
        }

        showNotification('success', __('Product added to cart!'));
        setQuantityToDefault();

        this.removeProductFromWishlist();
        this.setState({ isLoading: false });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
