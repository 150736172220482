/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { Suspense } from 'react';

import Icon from 'Component/Icon';
import {
    SearchField as SourceSearchField,
    SearchOverlay
} from 'SourceComponent/SearchField/SearchField.component';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

export {
    SearchOverlay
};

/** @namespace Sofacompany/Component/SearchField/Component/SearchField */
export class SearchField extends SourceSearchField {
    static getDerivedStateFromProps(props) {
        const { isActive, searchCriteria } = props;

        if (isActive || searchCriteria !== '') {
            return { isPlaceholderVisible: false };
        }

        return { isPlaceholderVisible: true };
    }

    componentDidMount() {
        setTimeout(() => {
            this.refresh();
        }, Number('1000'));
        const { clearSearchInput, searchCriteria } = this.props;

        if (clearSearchInput && searchCriteria !== '') {
            clearSearchInput();
        }
    }

    refresh = () => {
        // re-renders the component
        // the reason for this is that the i18n module dont force update this component so its keeps taking the english translation
        this.forceUpdate();
    };

    onSearchEnterPress = (e) => {
        const { searchCriteria, onSearchOutsideClick } = this.props;
        const search = searchCriteria.trim().replace(/\s/g, '+');
        const trimmedSearch = searchCriteria.trim();

        if (e.key === 'Enter' && trimmedSearch !== '') {
            onSearchOutsideClick();
            history.push(appendWithStoreCode(`/search/${ search }`));
            this.searchBarRef.current.blur();
        }
    };

    openSearch = () => {
        const { onSearchBarFocus } = this.props;

        onSearchBarFocus();
    };

    closeSearch = () => {
        const { onSearchOutsideClick } = this.props;

        onSearchOutsideClick();
    };

    renderOverlayFallback() {
        return null;
    }

    renderSearchIcon(isButton = false) {
        const {
            isVisible,
            isActive
        } = this.props;

        if (isButton && isActive) {
            return null;
        }

        if (isButton) {
            return (
                <button
                  key="wishlist"
                  block="Header"
                  elem="Button"
                  mods={ { isVisible } }
                  mix={ { block: 'SearchField', elem: 'SearchButton' } }
                  aria-label={ __('Search') }
                  aria-hidden={ !isVisible }
                  tabIndex={ isVisible ? 0 : -1 }
                >
                        <Icon icon="search" />

                </button>
            );
        }

        return (
            <div
              block="SearchField"
              elem="SearchIcon"
              role="search"
              aria-label={ __('Search') }
            >
                <Icon icon="search" />
            </div>
        );
    }

    renderContent() {
        const {
            isButton = false,
            renderOverlay = true,
            searchCriteria,
            onSearchBarFocus,
            onSearchBarChange,
            isActive,
            clearSearchInput,
            curSearch,
            searchOpen,
            onSearchOutsideClick
        } = this.props;

        const { isPlaceholderVisible } = this.state;

        const searchValue = curSearch === '' ? curSearch : searchCriteria;

        return (
            <>
                { this.renderSearchIcon(isButton) }
                { !isButton && (
                    <>
                        <input
                          id="search-field"
                          aria-label={ __('search') }
                          ref={ this.searchBarRef }
                          block="SearchField"
                          elem="Input"
                          onFocus={ onSearchBarFocus }
                          onChange={ this.handleChange }
                          onKeyDown={ this.onSearchEnterPress }
                          value={ searchValue }
                          mods={ { isActive } }
                          autoComplete="off"
                        />
                        <div
                          block="SearchField"
                          elem="Placeholder"
                          mods={ {
                              isActive,
                              isPlaceholderVisible
                          } }
                        >
                            <span>{ __('Search') }</span>
                        </div>
                    </>
                ) }

                { renderOverlay && (
                    <Suspense fallback={ this.renderOverlayFallback() }>
                        <SearchOverlay
                          searchOpen={ searchOpen }
                          clearSearch={ this.clearSearch }
                          closeSearch={ this.closeSearch }
                          searchCriteria={ searchCriteria }
                          curSearch={ curSearch }
                          clearSearchInput={ clearSearchInput }
                          onSearchBarChange={ onSearchBarChange }
                          onSearchOutsideClick={ onSearchOutsideClick }
                        />
                    </Suspense>
                ) }
            </>
        );
    }

    render() {
        const {
            isVisible,
            isActive,
            isButton
        } = this.props;

        return (
            <div block="SearchField" mods={ { isVisible, isActive, isButton } } onClick={ this.openSearch }>
                <div block="SearchField" elem="Wrapper">
                    { this.renderContent() }
                </div>
            </div>
        );
    }
}

export default SearchField;
