/* eslint-disable import/no-cycle */

import Event, { EVENT_GTM_USER_REGISTER } from 'Util/Event';

import BaseEvent from './BaseEvent.event';

export const USER_REGISTRATION_EVENT_DELAY = 500;
export const SPAM_PROTECTION_DELAY = 100;

/**
 * On checkout
 * @namespace Sofacompany/Component/GoogleTagManager/Events/UserRegister/Event/UserRegistrationEvent
 */
export class UserRegistrationEvent extends BaseEvent {
    /**
     * Event fire delay
     *
     * @type {number}
     */
    eventHandleDelay = USER_REGISTRATION_EVENT_DELAY;

    /**
     * Bind
     */
    bindEvent() {
        Event.observer(EVENT_GTM_USER_REGISTER, () => {
            this.handle();
        });
    }

    checkDataExists() {
        return true;
    }

    /**
     * Handle
     */
    handler() {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        this.pushEventData({});
    }
}

export default UserRegistrationEvent;
