import Icon from 'Component/Icon';
import {
    Notification as SourceNotification
} from 'SourceComponent/Notification/Notification.component';

/** @namespace Sofacompany/Component/Notification/Component/Notification */
export class Notification extends SourceNotification {
    render() {
        const { notification } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        return (
            <div block="Notification" mods={ mods } ref={ this.notification }>
                <p block="Notification" elem="Text">{ msgText }</p>
                { this.renderDebug() }
                <button block="Notification" elem="Button" onClick={ this.hideNotification }>
                    <Icon icon="close" />
                </button>
            </div>
        );
    }
}

export default Notification;
