import { getVariantsIndexes } from 'SourceUtil/Product';

export * from 'SourceUtil/Product/Product';
export * from 'SourceUtil/Product/Types';

/** @namespace Sofacompany/Util/Product/Index/getConfigurableParametersFromFilters */
export const getConfigurableParametersFromFilters = (product, selectedFilters) => {
    const { variants = [] } = product;
    const filterKeys = Object.keys(selectedFilters);

    if (filterKeys.length < 0) {
        return { indexes: [], parameters: {} };
    }

    const indexes = getVariantsIndexes(variants, selectedFilters);
    const [index] = indexes;

    if (!variants[index]) {
        return { indexes: [], parameters: {} };
    }
    const { attributes } = variants[index];

    const parameters = Object.entries(attributes)
        .reduce((parameters, [key, { attribute_value }]) => {
            if (filterKeys.includes(key)) {
                return { ...parameters, [key]: attribute_value };
            }

            return parameters;
        }, {});

    return { indexes, index, parameters };
};

/** @namespace Sofacompany/Util/Product/Index/getCurrentVariantIndexFromFilters */
export const getCurrentVariantIndexFromFilters = (product, selectedFilters) => {
    if (!Object.keys(selectedFilters).length) {
        return -1;
    }

    const { index } = getConfigurableParametersFromFilters(product, selectedFilters);
    return index >= 0 ? index : -1;
};

/** @namespace Sofacompany/Util/Product/Index/removeCustomFilters */
export const removeCustomFilters = (filterData = false) => {
    if (filterData) {
        const { args: { filter, search }, requirePriceRange } = filterData;

        const { categoryIds } = filter;

        const infoOptions = {
            args: {
                filter: {
                    categoryIds,
                    customFilters: {},
                    priceRange: {
                        max: 0,
                        min: 0
                    }
                },
                search
            },
            requirePriceRange
        };

        return infoOptions;
    }

    return null;
};
