/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link/Link.component';

import './SocialFooterItems.style';

/** @namespace Sofacompany/Component/ImageDeck/Component/ImageDeck */
export class SocialFooterItems extends PureComponent {
    static propTypes = {
        data: PropTypes.object
    };

    static defaultProps = {
        data: null
    };

    getImage(img) {
        if (!img) {
            return '/';
        }

        return `${img}`;
    }

    renderImage(social) {
        const { custom_attributes: fields } = social;

        const item = {};

        if (!fields && fields.length <= 0) {
            return null;
        }

        fields.forEach((field) => {
            const { identifier, value } = field;
            item[identifier] = value;
        });

        if (!item) {
            return null;
        }

        const { social_item_link, social_item_svg_icon } = item;

        return (
            <Link
              to={ social_item_link }
              block="SocialFooterItems"
              elem="Link"
              key={ social_item_link }
              href={ social_item_link }
              target="_blank"
              rel="noreferrer"
            >
                <div block="SocialFooterItems" elem="Image">
                    <Image src={ social_item_svg_icon } ratio="custom" />
                </div>
            </Link>

        );
    }

    render() {
        const { data } = this.props;

        if (!data) {
            return null;
        }

        const {
            repeater_field_contents
        } = data;

        return (
            <div block="SocialFooterItems">

                { repeater_field_contents.map(this.renderImage) }

            </div>
        );
    }
}

export default SocialFooterItems;
