import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductLinksContainer as SourceProductLinksContainer
} from 'SourceComponent/ProductLinks/ProductLinks.container';
import { showNotification } from 'Store/Notification/Notification.action';
import Event, {
    EVENT_GTM_IMPRESSION, EVENT_GTM_PRODUCT_ADD_TO_CART
} from 'Util/Event';

import ProductLinks from './ProductLinks.component';

export const LinkedProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/LinkedProducts/LinkedProducts.dispatcher'
);

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    currentPageId: state.CurrentPageReducer.pageId,
    currentPageName: state.CurrentPageReducer.pageName,
    currentPageAppender: state.CurrentPageReducer.appender
});

/** @namespace Sofacompany/Component/ProductLinks/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    requestLinkedProducts: (linkType) => LinkedProductsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getLinkedProducts(dispatch, linkType)
    ),
    clearLinkedProducts: () => LinkedProductsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.clearLinkedProducts(dispatch)
    ),
    addProducts: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductsToCart(dispatch, options)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Sofacompany/Component/ProductLinks/Container/ProductLinksContainer */
export class ProductLinksContainer extends SourceProductLinksContainer {
    static propTypes = {
        linkType: PropTypes.string.isRequired,
        linkedProducts: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array
        ]),
        requestLinkedProducts: PropTypes.func.isRequired,
        clearLinkedProducts: PropTypes.func.isRequired,
        showAsGrid: PropTypes.bool,
        addProducts: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired
    };

    static defaultProps = {
        ...SourceProductLinksContainer.defaultProps,
        showAsGrid: false,
        numberOfProductsToDisplay: 4,
        pageSize: 12
    };

    containerFunctions = {
        ...this.containerFunctions,
        onScroll: this.onScroll.bind(this),
        handleVisibilityChange: this.handleVisibilityChange.bind(this),
        checkIfActive: this.checkIfActive.bind(this),
        addAllToBasketClick: this.addAllToBasketClick.bind(this)
    };

    state = {
        ...this.state,
        scrolledTo: 0,
        gtmEventFired: false,
        visibleProductRows: {}
    };

    componentDidMount() {
        const { product } = this.props;

        if (product && product.sku) {
            this.requestProducts();
        }
    }

    componentDidUpdate(prevProps) {
        const { product: { sku } = {}, areDetailsLoaded } = this.props;
        const { product: { sku: prevSku } = {}, areDetailsLoaded: wereDetailsLoaded } = prevProps;

        // TODO: see if this works???
        if (areDetailsLoaded && wereDetailsLoaded) {
            const { linkType = '', linkedProducts = {} } = this.props;
            const { items = {} } = linkedProducts[linkType] || {};

            if (items.length) {
                // Event.dispatch(EVENT_GTM_IMPRESSION, { items });
            }
        }

        if (!sku) {
            return;
        }

        if (sku !== prevSku) {
            this.requestProducts();
        }
    }

    handleVisibilityChange(isVisible, index, items, pageNumber) {
        const { visibleProductRows: { [pageNumber]: page = [] } } = this.state;

        if (index.length) {
            return null;
        }

        if (isVisible && !page.includes(index)) {
            const {
                selectedFilters: filters,
                category = {},
                currentPageId,
                currentPageName,
                currentPageAppender
            } = this.props;

            // eslint-disable-next-line no-unused-vars
            const { currentRouteName } = window;

            const currentPage = {
                currentPageId,
                currentPageName,
                currentPageAppender
            };

            const products = {
                items,
                filters,
                category,
                list: currentPage
            };

            Event.dispatch(EVENT_GTM_IMPRESSION, products);

            this.setState((prevState) => ({
                visibleProductRows: {
                    ...prevState.visibleProductRows,
                    [pageNumber]: [...page, index]
                }
            }));
        }
    }

    requestProducts() {
        const {
            requestLinkedProducts,
            linkType,
            product
        } = this.props;

        if (linkType) {
            requestLinkedProducts({ linkType, product });
        }
    }

    onScroll(e) {
        const { showAsGrid } = this.props;

        if (showAsGrid) {
            return;
        }

        this.setState({
            scrolledTo: e.target.scrollLeft
        });
    }

    checkIfActive() {
        const { product: { products }, linkType, linkedProducts: { [linkType]: { items } } } = this.props;

        const linkedItems = Array.from(items).reduce((pV, cV) => {
            pV.push(cV?.sku);
            return pV;
        }, []);

        const isActive = [];

        linkedItems.forEach((item) => {
            if (products.find((x) => x?.sku === item)) {
                isActive.push(item);
            }
        });

        const uLinkedItems = Array.from(new Set(linkedItems));
        const uIsActive = Array.from(new Set(isActive));

        return uIsActive?.length === uLinkedItems?.length;
    }

    addAllToBasketClick() {
        this.setState({ isLoading: true }, () => this.addSimpleProductsToCart());
    }

    afterAddToCart() {
        const {
            showNotification
        } = this.props;

        showNotification('success', __('Products added to cart!'));
        this.setState({ isLoading: false });
    }

    addSimpleProductsToCart() {
        const {
            linkType,
            linkedProducts: { [linkType]: { items } },
            addProducts
        } = this.props;

        const productsArray = [];

        items.forEach((item) => {
            productsArray.push({
                sku: item.sku,
                product_type: item.type_id,
                quantity: 1,
                product_option: {
                    buy_request: null,
                    extension_attributes: null
                }
            });
        });

        Event.dispatch(EVENT_GTM_PRODUCT_ADD_TO_CART, {
            product: items,
            addType: 'shopTheLook'
        });

        addProducts({ products: productsArray }).then(
            () => this.afterAddToCart(),
        );
    }

    render() {
        const {
            linkType,
            linkedProducts: {
                [linkType]: {
                    items = []
                } = {}
            }
        } = this.props;

        if (items.length === 0) {
            return null;
        }

        return (
                <ProductLinks
                  { ...this.state }
                  { ...this.props }
                  { ...this.containerFunctions }
                  { ...this.containerProps() }
                />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductLinksContainer);
