import CartQuery from 'Query/Cart.query';
import CheckoutQuery from 'Query/Checkout.query';
import ConfigQuery from 'Query/Config.query';
import GtmQuery from 'Query/Gtm.query';
import MaterialImageQuery from 'Query/MaterialImage.query';
import RegionQuery from 'Query/Region.query';
import ReviewQuery from 'Query/Review.query';
import {
    ConfigDispatcher as SourceConfigDispatcher
} from 'SourceStore/Config/Config.dispatcher';
import { updateConfig } from 'Store/Config/Config.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { bouncer, DEFAULT_LOADING_TIME } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Sofacompany/Store/Config/Dispatcher/ConfigDispatcher */
export class ConfigDispatcher extends SourceConfigDispatcher {
    updateConfigData = bouncer((dispatch) => {
        this.handleData(dispatch);
    }, DEFAULT_LOADING_TIME);

    onSuccess(data, dispatch) {
        if (data) {
            if (data?.storeNumber) {
                data.storeNumber = data.storeNumber.find((x) => x.store_name.includes('Customer Service')
                && x.store_id === data.storeConfig.default_country);
            }
            BrowserDatabase.setItem(data, 'config', ONE_MONTH_IN_SECONDS);
            dispatch(updateConfig(data));
        }
    }

    prepareRequest() {
        return [
            RegionQuery.getCountriesQuery(),
            ReviewQuery.getRatingQuery(),
            ConfigQuery.getQuery(),
            ConfigQuery.getCheckoutAgreements(),
            GtmQuery.getGTMConfiguration(),
            ConfigQuery.getCurrencyData(),
            CartQuery.getCartDisplayConfig(),
            MaterialImageQuery.getMaterialImages(),
            ConfigQuery.getCustomerServiceNumber(),
            CheckoutQuery.getRestrictedZipCodes()

        ];
    }
}

export default new ConfigDispatcher();
