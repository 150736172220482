import {
    CookiePopup as SourceCookiePopup
} from 'SourceComponent/CookiePopup/CookiePopup.component';
import { getLocaleFromUrl } from 'Util/Global';

/** @namespace Sofacompany/Component/CookiePopup/Component/CookiePopup */
export class CookiePopup extends SourceCookiePopup {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        // Do not show cookieinformation box when prerender is rendering the page
        const isPrerender = window.navigator.userAgent.match(/prerender/gi)?.length > 0;

        if (isPrerender) {
            return;
        }

        const timer = 2000;
        const store = getLocaleFromUrl().toUpperCase().split('-')[0];

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'CookieConsent';
        script.async = true;
        script.src = 'https://policy.app.cookieinformation.com/uc.js';
        script.setAttribute('data-culture', store);

        // Disable cookieinformation consent mode
        script.setAttribute('data-gcm-version', '2.0');

        setTimeout(() => {
            document.head.insertBefore(script, document.head.childNodes[1]);
        }, timer);
    }

    render() {
        return null;
    }
}

export default CookiePopup;
