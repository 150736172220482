export const FETCH_SHOWROOM_STOCK = 'FETCH_SHOWROOM_STOCK';
export const FETCH_SHOWROOM_STOCK_FAILURE = 'FETCH_SHOWROOM_STOCK_FAILURE';
export const FETCH_SHOWROOM_STOCK_SUCCESS = 'FETCH_SHOWROOM_STOCK_SUCCESS';

export const fetchShowroomStock = () => ({
    type: FETCH_SHOWROOM_STOCK,
    loading: true
});

export const fetchShowroomStockFailure = (error) => ({
    type: FETCH_SHOWROOM_STOCK_FAILURE,
    error
});
export const fetchShowroomStockSuccess = (showroomStock) => ({
    type: FETCH_SHOWROOM_STOCK_SUCCESS,
    showroomStock
});
