export const UPDATE_POSTS_SEARCH = 'UPDATE_POSTS_SEARCH';
export const UPDATE_POSTS_SEARCH_LOAD_STATUS = 'UPDATE_POSTS_SEARCH_LOAD_STATUS';

/** @namespace Sofacompany/Store/PostsSearch/Action/updatePostsSearch */
export const updatePostsSearch = (items) => ({
    type: UPDATE_POSTS_SEARCH,
    items
});

/** @namespace Sofacompany/Store/PostsSearch/Action/updatePostsLoadStatus */
export const updatePostsLoadStatus = (status) => ({
    type: UPDATE_POSTS_SEARCH_LOAD_STATUS,
    isLoading: status
});
