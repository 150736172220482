/* eslint-disable import/no-cycle */

import Event, { EVENT_GTM_FEATURED_CAT_CLICK } from 'Util/Event';

import BaseEvent from './BaseEvent.event';

export const CLICK_EVENT_DELAY = 500;
export const SPAM_PROTECTION_DELAY = 1000;

/**
 * On checkout
 * @namespace Sofacompany/Component/GoogleTagManager/Events/FeaturedCatClick/Event/FeaturedCatClickEvent
 */
export class FeaturedCatClickEvent extends BaseEvent {
    /**
     * Event fire delay
     *
     * @type {number}
     */
    eventHandleDelay = CLICK_EVENT_DELAY;

    /**
     * Bind
     */
    bindEvent() {
        Event.observer(EVENT_GTM_FEATURED_CAT_CLICK, ({ categoryTitle, position, id }) => {
            this.handle(categoryTitle, position, id);
        });
    }

    /**
     * Handle
     */
    handler(categoryTitle, position, id) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        this.pushEventData({
            imageCategoryTitle: categoryTitle,
            imageCategoryPosition: position,
            currentCategoryId: id
        });
    }
}

export default FeaturedCatClickEvent;
