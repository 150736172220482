export * from 'SourceStore/Wishlist/Wishlist.action';
export const ADD_ITEM_TO_WISHLIST = 'ADD_ITEM_TO_WISHLIST';
/**
 * Add specified product to wishlist
 * @param  {Object} product Product which should be added
 * @return {void}
 * @namespace Sofacompany/Store/Wishlist/Action/addItemToWishlist */
export const addItemToWishlist = (product) => ({
    type: ADD_ITEM_TO_WISHLIST,
    product
});
