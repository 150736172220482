import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import DataContainer from 'Util/Request/DataContainer';

import Newsletter from './Newsletter.component';

export const NewsletterDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Newsletter/Newsletter.dispatcher'
);

export const ContentBlockDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ContentBlock/ContentBlock.dispatcher'
);

/** @namespace Sofacompany/Component/Newsletter/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isNewsletterVisible: state.NewsletterReducer.isNewsletterVisible,
    newsletterContent: state.ContentBlockReducer.content.newsletterContent
});

/** @namespace Sofacompany/Component/Newsletter/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    sendNewsletterSignup: (options) => NewsletterDispatcher.then(
        /** @namespace Component/Newsletter/Container/then */
        ({ default: dispatcher }) => dispatcher.requestNewsletterSignup(dispatch, options)
    ),
    requestContentBlock: (options) => ContentBlockDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getData(dispatch, options)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Sofacompany/Component/Newsletter/Container/NewsletterContainer */
export class NewsletterContainer extends DataContainer {
    static propTypes = {
        sendNewsletterSignup: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        requestContentBlock: PropTypes.func.isRequired,
        newsletterContent: PropTypes.string
    };

    static defaultProps = {
        newsletterContent: null
    };

    state = {
        isLoading: false,
        scriptsInjected: false
    };

    componentDidMount() {
        const {
            requestContentBlock,
            newsletterContent

        } = this.props;

        const options3 = {
            identifier: 'newsletter-signup-text',
            alias: 'newsletterContent'
        };

        if (!newsletterContent) {
            requestContentBlock(options3);
        }
    }

    render() {
        return (
            <Newsletter
              { ...this.state }
              { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterContainer);
