import {
    App as SourceApp
} from 'SourceComponent/App/App.component';

/** @namespace Sofacompany/Component/App/Component/App */
export class App extends SourceApp {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    injectComment() {
        return null;
    }
}

export default App;
