/* eslint-disable import/no-cycle */

import Event, { EVENT_GTM_USER_IP } from 'Util/Event';

import BaseEvent from './BaseEvent.event';

/**
 * Product click event
 * @namespace Sofacompany/Component/GoogleTagManager/Events/UserIP/Event/UserIPEvent */
export class UserIPEvent extends BaseEvent {
    eventHandleDelay = 0;

    bindEvent() {
        Event.observer(EVENT_GTM_USER_IP, (ip) => {
            this.handle(ip);
        });
    }

    handle(...args) {
        // Set little delay on event fire. make it async, to do not block main code
        setTimeout(() => {
            this.handler(...args);
        }, this.eventHandleDelay);
    }

    handler(ip) {
        if (!ip || Object.entries(ip).length <= 0) {
            return;
        }

        this.pushEventData({
            ipAddress: ip
        });
    }
}

export default UserIPEvent;
