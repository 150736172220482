import {
    deleteProperty
} from 'SourceStore/Wishlist/Wishlist.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getLocaleFromUrl } from 'Util/Global';
import { getIndexedParameteredProducts } from 'Util/Product';

import {
    ADD_ITEM_TO_WISHLIST,
    REMOVE_ITEM_FROM_WISHLIST
} from './Wishlist.action';

export {
    deleteProperty
};

export const PRODUCTS_IN_WISHLIST = `wishlist_products_${getLocaleFromUrl()}`;

/** @namespace Sofacompany/Store/Wishlist/Reducer/getInitialState */
export const getInitialState = () => ({
    productsInWishlist: BrowserDatabase.getItem(PRODUCTS_IN_WISHLIST) || {},
    isLoading: false
});

/** @namespace Sofacompany/Store/Wishlist/Reducer/removeItemFromWishlist */
export const removeItemFromWishlist = ({ item_id }, { productsInWishlist: initialProducts }) => {
    const productsInWishlist = deleteProperty(item_id, initialProducts) || {};

    BrowserDatabase.setItem(
        productsInWishlist,
        PRODUCTS_IN_WISHLIST
    );

    return { productsInWishlist };
};

/** @namespace Sofacompany/Store/Wishlist/Reducer/updateAllProductsInWishlist */
export const updateAllProductsInWishlist = (action) => {
    const { products: initialProducts } = action;

    const products = getIndexedParameteredProducts(initialProducts);

    BrowserDatabase.setItem(
        products,
        PRODUCTS_IN_WISHLIST
    );

    return { productsInWishlist: products, isLoading: false };
};

/** @namespace Sofacompany/Store/Wishlist/Reducer/updateItemOptions */
export const updateItemOptions = (options, { productsInWishlist }) => {
    const { item_id } = options;
    const cleanedOptions = deleteProperty('item_id', options) || {};

    const products = {
        ...productsInWishlist,
        [item_id]: {
            ...productsInWishlist[item_id],
            wishlist: {
                ...productsInWishlist[item_id].wishlist,
                ...cleanedOptions
            }
        }
    };

    BrowserDatabase.setItem(
        products,
        PRODUCTS_IN_WISHLIST
    );

    return { productsInWishlist: products };
};

/** @namespace Sofacompany/Store/Wishlist/Reducer/clearWishlist */
export const clearWishlist = () => {
    const productsInWishlist = {};

    BrowserDatabase.setItem(productsInWishlist, PRODUCTS_IN_WISHLIST);
    return { productsInWishlist };
};

/** @namespace Sofacompany/Store/Wishlist/Reducer/WishlistReducer */
export const WishlistReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case REMOVE_ITEM_FROM_WISHLIST:
        return {
            ...state,
            isLoading: false,
            ...removeItemFromWishlist(action, state)
        };
    case ADD_ITEM_TO_WISHLIST:
        const { product } = action;
        const productsInWishlist = {
            ...state.productsInWishlist,
            ...product
        };

        BrowserDatabase.setItem(
            productsInWishlist,
            PRODUCTS_IN_WISHLIST
        );

        return {
            ...state,
            productsInWishlist: {
                ...state.productsInWishlist,
                ...product
            }
        };

    default:
        return state;
    }
};

export default WishlistReducer;
