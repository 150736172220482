import {
    RenderWhenVisible as SourceRenderWhenVisible
} from 'SourceComponent/RenderWhenVisible/RenderWhenVisible.component';
import { isCrawler } from 'Util/Global';

/* @namespace Sofacompany/Component/RenderWhenVisible/Component/RenderWhenVisible */
export class RenderWhenVisible extends SourceRenderWhenVisible {
    renderContent() {
        const { wasVisible } = this.state;
        const disableInView = document.documentElement.classList.contains('disableInView');

        if (!wasVisible && !isCrawler() && !disableInView) {
            return this.renderVisibilitySensor();
        }

        return this.renderChildren();
    }
}

export default RenderWhenVisible;
